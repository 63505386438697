import { useState, useRef, useEffect } from "react";
import { BsFillCloudArrowUpFill, BsX } from "react-icons/bs";

const MultipleFileInput = ({
  addonText,
  handleNewPhotos = null,
  makeEmpty,
  handleMakeEmpty,
  setSelectedPhotoIndex = null,
  inputName,
  height = null,
  draggedFiles,
}) => {
  const [multipleFiles, setMultipleFiles] = useState([]);
  const inputElement = useRef(null);
  const [clickedPhotoIndex, setClickedPhotoIndex] = useState(0);

  const handleMultipleFilesChange = (event) => {
    let tmpImageList = [];
    for (let i = 0; i < event.target.files.length; i++) {
      tmpImageList.push(event.target.files[i]);
    }
    setMultipleFiles(tmpImageList);
  };

  const HandleRemovePhoto = (index) => {
    let tmp = [...multipleFiles];
    tmp.splice(index, 1);
    setMultipleFiles(tmp);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  const handleSelectedPhotoId = (index) => {
    setClickedPhotoIndex(index);
    if (setSelectedPhotoIndex !== null) {
      setSelectedPhotoIndex(index);
    }
  };

  useEffect(() => {
    if (multipleFiles != null && handleNewPhotos !== null) {
      handleNewPhotos(multipleFiles);
    }
  }, [multipleFiles]);

  useEffect(() => {
    if (makeEmpty) {
      setMultipleFiles([]);
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  useEffect(() => {
    if (draggedFiles) {
      const listItems = Object.entries(draggedFiles).map((key) => {
        return key[1];
      });
      setMultipleFiles(listItems);
    }
  }, [draggedFiles]);

  return (
    <div>
      <div className="rounded-2xl border-dashed border-2 p-3 border-gray-300 bg-gray-100">
        <div
          onClick={handleOpenInput}
          className="flex items-center gap-2 cursor-pointer"
        >
          <div className="bg-blue-1000 h-8 w-8 rounded-full p-2">
            <BsFillCloudArrowUpFill className="text-white w-full h-full" />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-slate-600">{addonText} ატვირთვა</p>
            <p className="text-xs text-slate-500">JPG, PNG,SVG,WEBP</p>
          </div>
        </div>
        {multipleFiles && multipleFiles.length !== 0 && (
          <div
            className={`w-full grid grid-cols-4 gap-2 overflow-auto scrollbar pt-4 max-w-[500px]
                    ${height ? height : "max-h-[120px] "}`}
          >
            {multipleFiles.map((single, index) => {
              let fileUrl = URL.createObjectURL(single);
              let fileName = single.name;
              return (
                <div
                  onClick={() => {
                    handleSelectedPhotoId(index);
                  }}
                  key={index}
                  className={`w-full max-sm:h-auto relative rounded-xl bg-white
                                            ${
                                              clickedPhotoIndex === index &&
                                              "bg-green-100"
                                            }`}
                >
                  <img
                    src={fileUrl}
                    className="w-full rounded-xl h-auto object-cover"
                    alt="#"
                  />
                  <div
                    className="rounded-full w-4 h-4 bg-red-400 flex items-center justify-center 
                                            absolute top-0 right-0 translate-x-[50%] translate-y-[-50%] cursor-pointer"
                    onClick={() => {
                      HandleRemovePhoto(index);
                    }}
                  >
                    <BsX className="text-gray-100 text-2xl" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <input
        name={`${inputName}`}
        ref={inputElement}
        multiple
        className="hidden"
        type="file"
        onChange={handleMultipleFilesChange}
        accept={"image/png, image/jpg, image/bmp, image/webp, image/gif"}
      />
    </div>
  );
};

export default MultipleFileInput;
