import { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function SubMenu({ children }) {
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("below");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleSubMenu(false);
      }
    };

    const handlePosition = () => {
      if (dropdownRef.current) {
        const toggleRect = dropdownRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - toggleRect.bottom;
        const spaceAbove = toggleRect.top;

        if (spaceBelow < toggleRect.height) {
          setDropdownPosition("above");
        }

        if (spaceAbove < toggleRect.height) {
          setDropdownPosition("below");
        }
      }
    };

    if (toggleSubMenu) {
      handlePosition();
    }

    window.addEventListener("resize", handlePosition);
    window.addEventListener("scroll", handlePosition);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("resize", handlePosition);
      window.removeEventListener("scroll", handlePosition);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSubMenu]);

  return (
    <div className="relative">
      <BsThreeDotsVertical
        className="text-2xl text-slate-600 cursor-pointer"
        onClick={() => {
          setToggleSubMenu((prev) => !prev);
        }}
      />
      {toggleSubMenu && (
        <div
        ref={dropdownRef}
          className={`right-0 absolute bg-white shadow-my rounded-2xl p-3
flex flex-col gap-2 z-20 *:
${dropdownPosition === "below" ? "top-[105%]" : "bottom-[105%]"}
`}
        >
          {children}
        </div>
      )}
    </div>
  );
}
