import img from "../../img/google.jpg";
import PluginCard from "./PluginCard";

const Plugins = () => {
    const data = [
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
        {
            img: img,
            name: "ინტეგრაციის სახელი 0.23.1",
            smallDesk: "მოკლე აღწერა",
            bigDesk: "ვრცელი აღწერა ",
            keyFeatures: [
                'უპირატესობა პირველი',
                'უპირატესობა მეორე',
                'უპირატესობა მესამე'
            ]
        },
    ]

    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                პლაგინები
            </h2>
            <div className="mt-5 pt-5 border-border-gray-300">
                <h2 className="text-base text-slate-600">
                    ჩამოწეილები
                </h2>
                <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 mt-3">
                    {data.map((item, index) => {
                        return <PluginCard
                            key={index}
                            img={item.img}
                            name={item.name}
                            smallDesk={item.smallDesk}
                            bigDesk={item.bigDesk}
                            keyFeatures={item.keyFeatures}
                        />
                    })}
                </div>
            </div>
            <div className="mt-5 pt-5 border-border-gray-300">
                <h2 className="text-base text-slate-600">
                    ხელმისწვდომი
                </h2>
                <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 mt-3">
                    {data.map((item, index) => {
                        return <PluginCard
                            key={index}
                            img={item.img}
                            name={item.name}
                            smallDesk={item.smallDesk}
                            bigDesk={item.bigDesk}
                            keyFeatures={item.keyFeatures}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}

export default Plugins