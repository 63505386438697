import FileInput from "../../components/inputs/FileInput";
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/inputs/Button";

import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaLinkedin,
} from "react-icons/fa";

const BrandOptions = () => {
  return (
    <div className="min-h-screen w-full p-5 ">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        პარამეტრები
      </h2>
      <div className="w-full grid grid-cols-2 pt-5 gap-5 max-lg:grid-cols-1">
        <div className="w-full">
          <div className="shadow-my p-5 rounded-2xl mt-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              ტაბის აიქონი
            </h2>
            <div>
              <p className="text-slate-600 text-sm mb-3 mt-3">ტაბის აიქონი</p>
              <FileInput addonText={"ტაბის აიქონის"} />
              <p className="text-slate-600 text-sm mb-3 mt-3">
                გალერიიდან არჩევა
              </p>
              <FileInput addonText={"გალერია"} />
            </div>
          </div>
          <div className="shadow-my p-5 rounded-2xl mt-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              აღწერა
            </h2>
            <div className="grid grid-cols-1 gap-3 mt-3">
              <TextInput name={"სათაური"} />
              <TextInput name={"სლოგანი"} />
              <TextInput name={"მოკლე აღწერა"} />
              <TextInput name={"სეო keywords"} />
              <TextInput name={"საიტის ლინკი"} />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="shadow-my p-5 rounded-2xl mt-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              სოციალური ქსელები
            </h2>
            <p className="text-slate-600 text-sm mb-3 mt-3">
              შენი ბიზნესის სოციალური ქსელების ლინკები, რომლებიც ძირითადად
              ჩნდება გვერდის ბოლოს
            </p>
            <div className="flex flex-col gap-3">
              <TextInput name={"Facebook"} PrefixIcon={FaFacebookF} />
              <TextInput name={"Instagram"} PrefixIcon={FaInstagram} />
              <TextInput name={"Youtube"} PrefixIcon={FaYoutube} />
              <TextInput name={"Tiktok"} PrefixIcon={FaTiktok} />
              <TextInput name={"Linkedin"} PrefixIcon={FaLinkedin} />
            </div>
            <div className=" mt-3">
              <Button
                style={
                  "border border-blue-1000 text-blue-1000 text-sm rounded-lg py-2 px-4"
                }
                text={"დამახსოვრება"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandOptions;
