import { BsGraphUpArrow } from "react-icons/bs";
import LineChartt from "../charts/LineChartt";
import { FaArrowUp } from "react-icons/fa"

const StatisticCards = ({
    color,
    amount = 400,
    title,
    days,
}) => {
    return (
        <div className="bg-white flex flex-col rounded-2xl w-full shadow-my">
            <div className="flex flex-col items-start justify-start w-full p-4 gap-4">
                <div className="flex flex-row gap-[15px] items-center justify-start w-full">
                    <div className={`bg-blue-1000 flex h-8 items-center justify-center p-2 rounded-lg w-8`}>
                        <BsGraphUpArrow className="h-4 w-4 text-white" />
                    </div>
                    <p
                        className="text-slate-600 text-sm"
                    >
                        {days}
                    </p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                    <p
                        className="md:text-4xl sm:text-[34px] text-[38px] text-blue-1000"
                    >
                        {amount}
                    </p>
                    <div className="w-[120px]">
                        <LineChartt lineColor={"#010125"} />
                    </div>
                </div>
            </div>
            <div className={`bg-blue-1000 flex items-center
            p-4 rounded-bl-2xl rounded-br-2xl shadow-bs1 w-full h-20`}>
                <div className="flex gap-2 items-center ">
                    <div className="bg-white flex h-8 items-center justify-center p-2 rounded-full w-8">
                        <FaArrowUp className={`h-4 w-4 text-blue-1000`} />
                    </div>
                    <p
                        className="text-xs text-white"
                    >
                        <span className="text-white text-left font-bold">
                            +15%{" "}
                        </span>
                        <span className="text-white text-left font-normal">
                            {title}
                        </span>
                    </p>
                </div>
            </div>
        </div>

    )
}

export default StatisticCards