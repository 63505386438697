import { useEffect, useState } from "react"
import SelectInput from "./SelectInput"
import { BsX } from "react-icons/bs"

const SelectWithListBelow = ({
    text,
    nameOnInput,
    inputName,
    passedOptions,
    clearFields,
    setClearFields,
    retrieveData
}) => {
    //initial options
    const [initialOptions, setInitialOptions] = useState([])
    //picked options
    const [pickedOptions, setPickedOptions] = useState([])

    //handle pick
    const handlePick = (value, key) => {
        if (retrieveData) {
            retrieveData(prev => ({ ...prev, [key]: [...prev[inputName], value] }))
            setPickedOptions(prev => [...prev, initialOptions.filter(item => { return item.id === value && item })[0]])
            setInitialOptions(prev => prev.filter(item => { return item.id !== value }))
        }
    }

    //handle delete picked
    const handleDeletePicked = (id) => {
        if (retrieveData) {
            setInitialOptions(prev => [...prev, pickedOptions.filter(item => { return item.id === id && item })[0]])
            retrieveData(prev => ({ ...prev, [inputName]: prev[inputName].filter(item => { return item !== id }) }))
            setPickedOptions(prev => prev.filter(item => { return item.id !== id && item }))
        }
    }

    //when passed options change set it as initial options
    useEffect(() => {
        setInitialOptions(passedOptions)
    }, [passedOptions])

    return (
        <div >
            <p className="text-gray-800 text-sm mb-3">{text}</p>
            <SelectInput
                name={nameOnInput}
                options={initialOptions}
                inputSetter={handlePick}
                inputName={inputName}
                clearFields={clearFields}
                setClearFields={setClearFields}
            />
            <div className="flex flex-wrap gap-2 items-center mt-3">
                {pickedOptions.map((obj) => {
                    return (
                        <div
                            key={obj.id}
                            className="flex gap-2 items-center p-2 bg-blue-1000 rounded-full px-4 py-2"
                        >
                            <p className="text-white text-sm">
                                {obj.title}
                            </p>
                            <BsX className="text-white cursor-pointer"
                                onClick={() => { handleDeletePicked(obj.id) }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectWithListBelow