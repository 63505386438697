import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import Switch from "../../components/inputs/Switch"

export const TbsPay = () => {
    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                თიბისი ბაკის გადახდის სისტემა
            </h2>

            <div className=" w-full flex gap-5 flex-col mt-5">
                <div className="shadow-my p-5 rounded-2xl">
                    <div className="flex gap-2 items-center">
                        <div className="rounded-full w-12 h-12 bg-slate-600"></div>
                        <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                            გადახდის სისტემა
                        </h2>
                    </div>
                    <div className="mt-5 flex gap-3 flex-col">
                        <TextInput
                            name={"Client ID"}
                            type={"password"}
                        />
                        <TextInput
                            name={"Client Secret"}
                            type={"password"}
                        />
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                ბარათით გადახდა
                            </p>
                            <Switch />
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                QR გადახდა
                            </p>
                            <Switch />
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                ერთგული ბარათით გადახდა
                            </p>
                            <Switch />
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                Apple Pay გადახდა
                            </p>
                            <Switch />
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                Google Pay გადახდა
                            </p>
                            <Switch />
                        </div>
                        <div className="flex items-center gap-4">
                            <p className="text-slate-600 text-sm">
                                ინტერნეტ ბანკით გადახდა
                            </p>
                            <Switch />
                        </div>
                    </div>
                    <div className="w-full flex gap-2 justify-end mt-5 ">
                        <Button
                            style={"border border-blue-1000 text-sm text-blue-1000 rounded-lg py-2 px-4"}
                            text={"გასუფთავება"}
                        />
                        <Button
                            style={"bg-blue-1000 text-sm text-white rounded-lg py-2 px-4"}
                            text={"შენახვა"}
                        />
                    </div>
                </div>
            </div>

        </div >
    )
}
