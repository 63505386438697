import { Link } from "react-router-dom"
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs"
import deleteRecord from "../../Services/delete"

const CategoryCard = ({ data, updateFunction }) => {
    return (
        <div className="grid grid-cols-4 p-5">
            <div className="flex gap-2 items-center">
                <div className="flex gap-2 items-center">
                    {data?.imgs.length === 0 ?
                        <div className="rounded-xl w-10 h-10 bg-slate-400">
                        </div> :
                        <img
                            src={`${process.env.REACT_APP_API_URL}/${data?.imgs[0]?.url}`}
                            className="w-10 h-10 rounded-xl object-cover" alt="#" />
                    }
                </div>
            </div>
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {data.name}
                </p>
            </div>
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {data.link}
                </p>
            </div>
            <div className="flex gap-2 items-center justify-end">
                <Link to={`/category/${data.id}`}>
                    <div className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer">
                        <BsPencilSquare className="w-full h-full text-white" />
                    </div>
                </Link>
                <div
                    onClick={() => {
                        deleteRecord({
                            url: "/admin/category/",
                            id: data.id,
                            updateFunction: updateFunction,
                        })
                    }}
                    className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer">
                    <BsFillTrash3Fill className="w-full h-full text-white" />
                </div>
            </div>
        </div>
    )
}

export default CategoryCard