import protectedAxios from "../api/protectedAxios"

export default function getSingleRecord({
    url,
    id,
    stateUpdater
}) {
    protectedAxios.get(`${url}${id}`)
        .then(({ data }) => {
            if (stateUpdater) {
                stateUpdater(prev => ({ ...prev, ...data }))
            }
        })
        .catch((err) => { })
}
