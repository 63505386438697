import protectedAxios from "../api/protectedAxios"

export default function getAllRecords({
    url,
    page,
    perPage = 8,
    otherParams,
    handleLoading,
    handleTotal,
    handleData
}) {
    if (handleLoading) {
        handleLoading(true)
    }
    var restParams = '';
    if (otherParams) {
        restParams = otherParams.map((param) => {
            return `${param.key}=${param.value}`
        }).join("&");
    }

    protectedAxios.get(`${url}?per_page=${perPage}&page=${page + 1}${restParams !== '' ? `&${restParams}` : ''}`)
        .then(({ data }) => {
            if (handleData) {
                handleData(data.data)
            }
            if (handleTotal) {
                handleTotal(data.total)
            }
            if (handleLoading) {
                handleLoading(false)
            }
        })
        .catch((err) => { })
}
