const PluginFAQ = () => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-[#3c434a] text-[14px] indent-2">
        Contact Form 7 can manage multiple contact forms, plus you can customize
        the form and the mail contents flexibly with simple markup. The form
        supports Ajax-powered submitting, CAPTCHA, Akismet spam filtering and so
        on.
      </p>

      <ul className="flex flex-col gap-2 list-disc list-inside	">
        <li className="underline text-blue-400 cursor-pointer text-[14px]">
          Docs
        </li>

        <li className="underline text-blue-400 cursor-pointer text-[14px]">
          Docs
        </li>

        <li className="underline text-blue-400 cursor-pointer text-[14px]">
          Docs
        </li>
      </ul>

      <p className="underline text-blue-400 cursor-pointer text-[14px]">
        support
      </p>
    </div>
  );
};

export default PluginFAQ;
