import { Link } from "react-router-dom";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import { useEffect, useState } from "react";
import deleteRecord from "../../Services/delete";

const roles = [
  {
    id: 1,
    title: "სუპერ ადმინი",
  },
  {
    id: 2,
    title: "მაღაზიის მმართველი",
  },
  {
    id: 3,
    title: "კონტენტის მმართველი",
  },
  {
    id: 4,
    title: "შეკვეთების მმართველი",
  },
  {
    id: 5,
    title: "კურიერი",
  },
];

const AdminsCard = ({ data, updateFunction }) => {
  const [role, setRole] = useState("");

  useEffect(() => {
    setRole(
      roles.filter((item) => {
        return parseInt(item.id) === parseInt(data.role);
      })
    );
  }, [data]);

  return (
    <div className="grid grid-cols-4 p-5">
      <div className="flex gap-2 items-center">
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-sm">
            {data.id} - {data.name}
          </p>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-sm">{data.email}</p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-sm">
          {role.length !== 0 && role[0].title}
        </p>
      </div>
      <div className="flex gap-2 items-center justify-end">
        <Link to={`/admins/${data.id}`}>
          <div className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer">
            <BsPencilSquare className="w-full h-full text-white" />
          </div>
        </Link>
        <div
          onClick={() => {
            deleteRecord({
              url: "/admin/admins/",
              id: data.id,
              updateFunction: updateFunction,
            });
          }}
          className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer"
        >
          <BsFillTrash3Fill className="w-full h-full text-white" />
        </div>
      </div>
    </div>
  );
};

export default AdminsCard;
