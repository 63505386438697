import AdminRouter from "./components/router/AdminRouter";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App min-h-screen min-w-screen">
      <AdminRouter />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
