import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"

import { FaUserCircle } from "react-icons/fa"
import { BsEnvelope, BsGlobe, BsFillEnvelopeFill, BsKey, BsShieldCheck } from "react-icons/bs"
import Switch from "../../components/inputs/Switch"

import MultipleChoice from "../../components/inputs/MultipleChoice"

const NotificationOptions = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                შეტყობინებები
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                აირჩიეთ როგორ გსურთ მიიღოთ ინფორმაცია სიახლეებთან მიმრთებაში
            </p>
            <div className="flex flex-col gap-5 mt-10">
                <MultipleChoice
                    name={"სიახლეები"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"ექაუნთის აქტივობა"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"ახალი დაკავშირებული დივაისები"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"შეხსენებები"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
            </div>
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-10">
                პროექტის შეტყობინება
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                აირჩიე როგორ გსურთ მიიღოოთ ინფორმაცია პროექტზე დკავშირებულ სიახლეებთან დაკავშირებით
            </p>
            <div className="flex flex-col gap-5 mt-10">
                <MultipleChoice
                    name={"მოგიხსენიეს"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"წერილზე გიპასუხეს"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"თასქებზე სტატუსის განახლებ"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"თქვენთან დაკავშირებული თასქები"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
            </div>
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-10">
                გაყიდვების შეტყობინებები
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                აირჩიე როგორ გსურთ მიიღოოთ ინფორმაცია გაყიდვებთან დაკავშირებულ სიახლეებზე და ივენთებზე
            </p>
            <div className="flex flex-col gap-5 mt-10">
                <MultipleChoice
                    name={"ახალ პროდუქტი"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <MultipleChoice
                    name={"ახალი შეკვეთები"}
                    options={[
                        {
                            id: 1,
                            name: 'ელ-ფოსტა',
                            Icon: BsEnvelope
                        },
                        {
                            id: 2,
                            name: 'ბრაუზერი',
                            Icon: BsGlobe
                        }
                    ]}
                />
            </div>

            <div className="flex gap-2 items-center justify-end mt-10">
                <Button
                    style={"border border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm"}
                    text={"გასუფთავება"}
                />
                <Button
                    style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                    text={"შენახვა"}
                />
            </div>
        </div>
    )
}

export default NotificationOptions