import pluginBanner from "../../img/plugin-banner.png";
import { BsX } from "react-icons/bs";
import PluginDescription from "./pluginCardSections/PluginDescription";
import PluginRating from "./pluginCardSections/PluginRating";
import { useState } from "react";
import PluginFAQ from "./pluginCardSections/PluginFAQ";
import PluginReleases from "./pluginCardSections/PluginReleases";
import PluginImg from "./pluginCardSections/PluginImg";
import PluginDiscussion from "./pluginCardSections/PluginDiscussion";

const PluginDetails = ({
  handleToggleChange,
  IntegrationName,
  integrationImg,
  integrationBigDescription,
  keyFeatures,
}) => {
  const [currentPart, setCurrentPart] = useState(1);

  return (
    <div className="top-0 left-0 fixed z-50 w-full h-full bg-opacity-80 bg-blue-1000 flex items-center justify-center p-10">
      <div
        className="w-6/12 max-md:w-6/12 max-sm:w-10/12 bg-white 
            h-full"
      >
        <div className="h-[30%] w-full bg-red-300 relative">
          <img
            src={pluginBanner}
            alt="#"
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-5 left-5 bg-black-900 bg-opacity-80 p-2 rounded-lg">
            <h2 className="text-white ">რო აგიხსნა რამეს გაიგეეებ ?</h2>
          </div>
          <div
            onClick={handleToggleChange}
            className="absolute top-5 right-5 bg-black-900 bg-opacity-80 p-1 rounded-lg"
          >
            <BsX className="text-xl text-white font-bold  cursor-pointer" />
          </div>
        </div>
        <div className="h-[5%] border-b border-slate-300 px-5 flex items-center">
          <div
            onClick={() => {
              setCurrentPart(1);
            }}
            className={`h-full flex items-center justify-center px-2 cursor-pointer ${
              currentPart === 1 ? "bg-slate-100" : ""
            }`}
          >
            <p className="text-xs">აღწერა</p>
          </div>
          <div
            onClick={() => {
              setCurrentPart(2);
            }}
            className={`h-full flex items-center justify-center px-2 cursor-pointer ${
              currentPart === 2 ? "bg-slate-100" : ""
            }`}
          >
            <p className="text-xs">FAQ</p>
          </div>
          <div
            onClick={() => {
              setCurrentPart(3);
            }}
            className={`h-full flex items-center justify-center px-2 cursor-pointer ${
              currentPart === 3 ? "bg-slate-100" : ""
            }`}
          >
            <p className="text-xs">ცვლილებები</p>
          </div>
          <div
            onClick={() => {
              setCurrentPart(4);
            }}
            className={`h-full flex items-center justify-center px-2 cursor-pointer ${
              currentPart === 4 ? "bg-slate-100" : ""
            }`}
          >
            <p className="text-xs">სურათები</p>
          </div>
          <div
            onClick={() => {
              setCurrentPart(5);
            }}
            className={`h-full flex items-center justify-center px-2 cursor-pointer ${
              currentPart === 5 ? "bg-slate-100" : ""
            }`}
          >
            <p className="text-xs">მიმოხილვა</p>
          </div>
        </div>
        <div className="p-5 h-[65%] flex gap-5">
          <div className="w-8/12 overflow-auto scrollbar">
            {currentPart === 1 && <PluginDescription />}
            {currentPart === 2 && <PluginFAQ />}
            {currentPart === 3 && <PluginReleases />}
            {currentPart === 4 && <PluginImg />}
            {currentPart === 5 && <PluginDiscussion />}
          </div>
          <div className="w-4/12 bg overflow-auto scrollbar">
            <PluginRating />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginDetails;
