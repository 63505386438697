//library imports
import { useEffect, useState } from "react"
//components
import CategoryCard from "../../components/dataCards/CategoryCard"
import { Line } from "../../components/inputs/Line"
import SearchInput from "../../components/inputs/SearchInput"
import PaginatedItems from "../../components/pagination/Pagination"
import AddCategory from "./AddCategory"
import SelectInput from "../../components/inputs/SelectInput"
//functions
import getAllRecords from "../../Services/getAll"
//resources
import { BiLoaderAlt } from "react-icons/bi"

const Category = () => {
    //category data
    const [categoryData, setCategoryData] = useState([])
    //update category data
    const [updateCategoryData, setUpdateCategoryData] = useState(null)
    //if data is loading
    const [isLoading, setIsLoading] = useState(false)
    //total data number on this page
    const [total, setTotal] = useState(0)
    //current page
    const [page, setPage] = useState(0)
    //search value
    const [searchValue, setSearchValue] = useState("")

    //get all category function
    const handleGetCategory = () => {
        getAllRecords({
            url: "/admin/category",
            page: page,
            otherParams: [
                {
                    key: "key",
                    value: searchValue,
                },
            ],
            handleLoading: setIsLoading,
            handleTotal: setTotal,
            handleData: setCategoryData,
        })
    }

    //get data from api
    useEffect(() => {
        handleGetCategory()
    }, [page, searchValue, updateCategoryData])


    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                კატეგორიები
            </h2>
            <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
                <AddCategory
                    dataUpdateFunction={setUpdateCategoryData}
                />
                <div className="w-full col-span-2 max-lg:col-span-1">
                    <div className="bg-white shadow-my rounded-2xl w-full">
                        <div className="flex flex-col w-full">
                            <div className="flex items-center justify-between p-5 pb-0 max-sm:flex-col max-sm:gap-2">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                                    კატეგორიები
                                </h2>
                                <div className="shrink-0 flex gap-2">
                                    <SearchInput
                                        placeholder={"კატეგორიის სახელი, იდენთიფიკატორი"}
                                        inputSetter={setSearchValue}
                                    />
                                    < SelectInput
                                        name={"მშობელი კატეგორია"}
                                        options={[
                                            {
                                                id: 1,
                                                title: "კატეგორია 1"
                                            }
                                        ]}
                                        inputSetter={() => { }}
                                        getWithTiTitle={true}
                                    />
                                </div>
                            </div>
                            {isLoading ?
                                <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                    <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000" />
                                </div> :
                                (isLoading === false && categoryData.length === 0) ?
                                    <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                        <p className="text-slate-600 text-sm">
                                            მონაცემები არ მოიძებნა
                                        </p>
                                    </div>
                                    : <>
                                        <div className="max-sm:overflow-auto scrollbar">
                                            <div className="max-sm:w-[1000px]">
                                                <div className="grid grid-cols-4 p-5">
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            სურათი
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            სახელი
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            სათაურის - URL
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center justify-end">
                                                        <p className="text-slate-600 text-sm">
                                                            მოქმედება
                                                        </p>
                                                    </div>
                                                </div>
                                                {categoryData.map((user) => {
                                                    return (
                                                        <div key={user.id}>
                                                            <Line className="bg-gray-400_87 w-full h-[1px]" />
                                                            <CategoryCard
                                                                data={user}
                                                                updateFunction={setUpdateCategoryData}
                                                            />
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                        <Line className="bg-gray-400_87 w-full h-[1px]" />
                                        <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2">
                                            <PaginatedItems
                                                dataLength={total}
                                                itemsPerPage={8}
                                                pageNumberSetter={setPage}
                                                forcedPage={page}
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category