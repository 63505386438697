import axios from "axios";

const protectedAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

protectedAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("BM_USER_ADMIN");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// protectedAxios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const { response } = error;
//     if (response.status === 401) {
//       localStorage.removeItem("BM_USER_ADMIN");
//     } else if (response.status === 404) { }:
//     throw error;
//   }
// );

export default protectedAxios;
