import { useState } from "react";
import { FaShopify } from "react-icons/fa";

const QuestionTwo = () => {
  const [answer, setAnswer] = useState({
    shopCondition: 0,
  });

  const handleShopCondition = (newCondition) => {
    setAnswer({
      shopCondition: newCondition,
    });
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-blue-1000">
        სად ისურვებდი შენი პროდუქტის გაყიდვას
      </h2>

      <div className="mt-5 grid grid-cols-2 gap-5">
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(0);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 0
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-[22px] text-blue-1000 font-bold">
              ონლაინ მაღაზია
            </p>
            <p className="text-[14px] text-slate-600">
              შექმენი სრულიად კონფიგურირებული ვებსაიტი
            </p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(1);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 1
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-[22px] text-blue-1000 font-bold">
              სოციალური მედია
            </p>
            <p className="text-[14px] text-slate-600">
              მოიზიდე მეტი მომხმარებელი მედიით
            </p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(2);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 2
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-[22px] text-blue-1000 font-bold">მარქეთფლეისი</p>
            <p className="text-[14px] text-slate-600">
              ჩვენ გეხმარებით თქვენი პროდუქციის რეალიზაციაში
            </p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(3);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 3
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <p className="text-[22px] text-blue-1000">დარწმუნებული არ ვარ</p>
        </div>
      </div>
    </div>
  );
};

export default QuestionTwo;
