import { Link } from "react-router-dom"
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs"
import deleteRecord from "../../Services/delete"

const FilterCard = ({ data, updateFunction }) => {
    return (
        <div className="grid grid-cols-3 p-5">
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {data.name}
                </p>
            </div>
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {data.sort}
                </p>
            </div>
            <div className="flex gap-2 items-center justify-end">
                <Link to={`/filter/${data.id}`}>
                    <div className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer">
                        <BsPencilSquare className="w-full h-full text-white" />
                    </div>
                </Link>
                <div
                    onClick={() => {
                        deleteRecord({
                            url: "/admin/categoryFilters/",
                            id: data.id,
                            updateFunction: updateFunction,
                        })
                    }}
                    className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer">
                    <BsFillTrash3Fill className="w-full h-full text-white" />
                </div>
            </div>
        </div>
    )
}

export default FilterCard