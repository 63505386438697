import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"

import { FaUserCircle } from "react-icons/fa"
import { BsFillEnvelopeFill, BsKey, BsShieldCheck } from "react-icons/bs"
import Switch from "../../components/inputs/Switch"

const Common = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                ზოგადი
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                ძირითადი ინფორმაცია რომელის ნახვაც საზოგადოებას შეეძლება
            </p>
            <div className="flex flex-col gap-5 mt-10 shadow-my rounded-xl p-5">
                <div className="flex gap-5 items-center">
                    <p className="text-slate-600 text-sm">
                        ავატარი
                    </p>
                    <div className="w-12 h-12 rounded-full bg-gray-300"></div>
                </div>
                <TextInput
                    PrefixIcon={FaUserCircle}
                    name={"სახელი"}
                    placeholder={"სახელი"}
                    type={"text"}
                />
                <TextInput
                    PrefixIcon={BsFillEnvelopeFill}
                    name={"ელ-ფოსტა"}
                    placeholder={"forExample@gmail.com"}
                    type={"text"}
                />
                <TextInput
                    PrefixIcon={BsFillEnvelopeFill}
                    name={"კომპანიის სახელი"}
                    placeholder={"კომპანიის სახელი"}
                    type={"text"}
                />
                <div className="flex gap-5 items-center">
                    <p className="text-slate-600 text-sm">
                        სინქრონიზაცია
                    </p>
                    <Switch />
                </div>
            </div>
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-10">
                პაროლი
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                შეიყვანე მიმდინარე პაროლი და ახალი შესაცვლელად
            </p>
            <div className="flex flex-col gap-5 mt-10  shadow-my rounded-xl p-5">
                <TextInput
                    PrefixIcon={BsKey}
                    name={"მიმდინარე პაროლი"}
                    placeholder={"მიმდინარე პაროლი"}
                    type={"text"}
                />
                <TextInput
                    PrefixIcon={BsKey}
                    name={"ახალი პაროლი"}
                    placeholder={"ახალი პაროლი"}
                    type={"text"}
                />
                <TextInput
                    PrefixIcon={BsKey}
                    name={"გაიმეორეთ ახალი პაროლი"}
                    placeholder={"გაიმეორეთ ახალი პაროლი"}
                    type={"text"}
                />
            </div>
            <div className="flex gap-5 items-center mt-10">
                <div className="flex items-center gap-2">
                    <BsShieldCheck className="w-10 h-10 text-blue-1000" />
                    <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                        ორდონიანი ავტორიზაცია
                    </h2>
                </div>
                <Switch />
            </div>
            <p className="text-slate-400 text-sm mb-3">
                დააყენეთ ორ ეტპიანი დაცვა თქვენი ბიზნესის უსაფრთხოებისთვის
            </p>
            <div className="flex flex-col mt-10">
                <p className="text-blue-1000 text-base mb-3">
                    როგორ მუშაობს ?
                </p>
                <p className="text-slate-400 text-sm mb-3">
                    როდესაც შეხვალთ BMSoft - ის პლატფორმაზე
                </p>
                <p className="text-blue-1000 text-sm mb-3">
                    1. შეიყვანეთ ელექტრონული ფოსტა და პაროლი
                </p>
                <p className="text-blue-1000 text-sm mb-3">
                    2. ელექტრონულ ფოსტაზე მიღებული კოდის მეშვეობით დაადასტურეთ თქვენი ვინაობა
                </p>
            </div>
            <div className="flex gap-2 items-center justify-end mt-10">
                <Button
                    style={"border border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm"}
                    text={"გასუფთავება"}
                />
                <Button
                    style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                    text={"შენახვა"}
                />
            </div>
        </div>
    )
}

export default Common