import { BsX } from "react-icons/bs"
import TextInput from "../../components/inputs/TextInput"

const InformationSupport = ({ closeFunction }) => {
    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-blue-1000 bg-opacity-80 flex items-center justify-center">
            <div className="rounded-2xl shadow-my p-5 gap-10 bg-white w-5/12 h-[50%] max-md:w-[90%]">
                <div className="flex items-center justify-between">
                    <p className="text-md text-slate-600">
                        საინფორმაციო ცენტრი
                    </p>
                    <BsX className="text-2xl text-slate-600 cursor-pointer" onClick={closeFunction} />
                </div>
                <div className="w-full grid grid-cols-1 gap-5 mt-5">
                    <TextInput
                        name={"ელექტრონული ფოსტა"}
                    />
                    <TextInput
                        name={"მობილურის ნომერი"}
                    />
                </div>
            </ div >
        </ div >
    )
}

export default InformationSupport