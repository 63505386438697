//library imports
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
//components
import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import FileInput from "../../components/inputs/FileInput"
//functions
import getSingleRecord from "../../Services/getSingle"
import updateSingleRecord from "../../Services/updateSingle"
//resources
import { BsEye } from "react-icons/bs"
import { FaUser, FaSackDollar, FaDatabase, FaEnvelope, FaPhone, FaLock } from "react-icons/fa6";

const UserEdit = () => {
    //get single user id from params
    const { userId } = useParams();
    //new user data
    const [userData, setUserData] = useState({
        email: "",
        password: "",
        role: "1",
    })

    //handle users data
    const handleUserData = (value, key) => {
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    //update new user
    const handleUpdateUser = () => {
        var payload = (userData.password && userData.password.length !== 0) ? {
            fname: userData.fname,
            lname: userData.lname,
            email: userData.email,
            phone: userData.phone,
            password: userData.password
        } : {
            fname: userData.fname,
            lname: userData.lname,
            email: userData.email,
            phone: userData.phone,
        }

        updateSingleRecord({
            url: "/admin/user/",
            id: userId,
            payload: payload
        })
    }

    //get users data from db
    useEffect(() => {
        getSingleRecord({
            url: "/admin/user/",
            id: userId,
            stateUpdater: setUserData
        })
    }, [])

    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                მომხმარებლის დამატება
            </h2>
            <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
                <div className="w-full col-span-2 max-lg:col-span-1 ">
                    <div className="w-full grid grid-cols-1 gap-5">
                        <TextInput
                            name={"სახელი"}
                            placeholder={"სახელი"}
                            inputSetter={handleUserData}
                            inputName={"fname"}
                            value={userData.fname}
                            PrefixIcon={FaUser}
                        />
                        <TextInput
                            name={"გვარი"}
                            placeholder={"გვარი"}
                            inputSetter={handleUserData}
                            inputName={"lname"}
                            value={userData.lname}
                            PrefixIcon={FaUser}
                        />
                        <TextInput
                            name={"ბალანსი"}
                            placeholder={"ბალანსი"}
                            inputSetter={handleUserData}
                            inputName={"ballance"}
                            value={userData.ballance}
                            PrefixIcon={FaSackDollar}
                        />
                        <TextInput
                            name={"ქულები"}
                            placeholder={"ქულები"}
                            inputSetter={handleUserData}
                            inputName={"points"}
                            value={userData.points}
                            PrefixIcon={FaDatabase}
                        />
                        <TextInput
                            name={"ელ-ფოსტა"}
                            placeholder={"...@gmail.com"}
                            inputSetter={handleUserData}
                            inputName={"email"}
                            value={userData.email}
                            PrefixIcon={FaEnvelope}
                        />
                        <TextInput
                            name={"ნომერი"}
                            placeholder={"XXX XX XX XX"}
                            inputSetter={handleUserData}
                            inputName={"phone"}
                            value={userData.phone}
                            PrefixIcon={FaPhone}
                        />
                        <TextInput
                            name={"რეგიონი"}
                            placeholder={"რეგიონი"}
                            inputSetter={handleUserData}
                            inputName={"region"}
                            value={userData.region}
                            PrefixIcon={FaPhone}
                        />
                        <TextInput
                            name={"ქალაქი"}
                            placeholder={"ქალაქი"}
                            inputSetter={handleUserData}
                            inputName={"city"}
                            value={userData.phone}
                            PrefixIcon={FaPhone}
                        />
                        <TextInput
                            name={"საფოსტო კოდი"}
                            placeholder={"საფოსტო კოდი"}
                            inputSetter={handleUserData}
                            inputName={"post_code"}
                            value={userData.phone}
                            PrefixIcon={FaPhone}
                        />
                        <TextInput
                            name={"პაროლი"}
                            placeholder={"მინიმუმ 6 სიმბოლო"}
                            inputSetter={handleUserData}
                            SuffixIcon={BsEye}
                            type={"password"}
                            inputName={"password"}
                            PrefixIcon={FaLock}
                        />

                    </div>
                </div>
                <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
                    <div className=" w-full flex gap-5 flex-col">
                        <div className="shadow-my p-5 rounded-2xl">
                            <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800 mb-5">
                                პროფილის ფოტო
                            </h2>
                            <div className="flex items-center gap-4">
                                <div className="w-16 h-16 rounded-full bg-gray-400 shrink-0"></div>
                                <FileInput />
                            </div>
                            <div className="w-full grid gap-2 mt-5 ">
                                <Button
                                    onClickFunction={handleUpdateUser}
                                    style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
                                    text={"განახლება"}
                                />
                            </div>
                        </div>
                        <div className="shadow-my p-5 rounded-2xl">
                            <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800">
                                მომხმარებლის მისამართები
                            </h2>
                            <div>
                                <div className="py-5 border-b border-slate-200">
                                    <p className="text-slate-600 text-sm">
                                        მისამართი 1
                                    </p>
                                </div>
                                <div className="py-5 border-b border-slate-200">
                                    <p className="text-slate-600 text-sm">
                                        მისამართი 1
                                    </p>
                                </div>
                                <div className="py-5 border-b border-slate-200">
                                    <p className="text-slate-600 text-sm">
                                        მისამართი 1
                                    </p>
                                </div>
                                <div className="py-5 border-b border-slate-200">
                                    <p className="text-slate-600 text-sm">
                                        მისამართი 1
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserEdit