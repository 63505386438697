import { useState } from "react"
import { Link } from "react-router-dom"
import { BsThreeDotsVertical } from "react-icons/bs"
import deleteRecord from "../../Services/delete"

const FilialsCard = ({ data, updateFunction }) => {
    const [toggleActions, setToggleActions] = useState(false)

    const handleToggling = () => {
        setToggleActions(prev => !prev)
    }
    return (
        <div className="flex flex-col gap-4 w-full shadow-my p-5 rounded-2xl">
            <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-row items-start justify-between w-full">
                    <div className="flex gap-3">
                        <div className="w-14 h-14 bg-gray-400 border-2 border-white relative z-[1] rounded-full">

                        </div>
                        <div className="flex flex-col gap-1 items-start justify-start mt-0.5">
                            <p
                                className="text-base text-slate-600 font-bold"
                            >
                                {data?.name}
                            </p>
                            <p
                                className="text-sm text-slate-500"
                            >
                                {data?.address}
                            </p>
                        </div>
                    </div>
                    <div className="relative">
                        <BsThreeDotsVertical
                            className={`text-slate-500 duration-300 cursor-pointer ${toggleActions && "rotate-90"}`}
                            onClick={() => { handleToggling() }}
                        />
                        {toggleActions &&
                            <div className="absolute top-[105%] right-0 bg-white rounded-lg border border-slate-200 z-50 overflow-hidden
  cursor-pointer">
                                <Link to={`/filial/${data.id}`}>
                                    <p className="p-3 hover:bg-slate-100 text-xs text-slate-500">რედაქტირება</p>
                                </Link>
                                <div className="w-full h-[1px] bg-slate-200"></div>
                                <p
                                    className="p-3 hover:bg-slate-100 text-xs text-slate-500"
                                    onClick={() => {
                                        deleteRecord({
                                            url: "/admin/branch/",
                                            id: data.id,
                                            updateFunction: updateFunction,
                                        })
                                    }}
                                >
                                    წაშლა
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FilialsCard