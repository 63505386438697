import protectedAxios from "../api/protectedAxios"

export default function getAllWithoutPaginate({
    url,
    stateUpdater
}) {
    protectedAxios.get(url)
        .then(({ data }) => {
            if (stateUpdater) {
                stateUpdater(data.data)
            }
        })
        .catch((err) => { })
}
