import { BsBox2, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const PaymentSystems = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                გადახდის სისტემები
            </h2>
            <div className="mt-5 grid grid-cols-1 gap-5">
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            ნაღდი ანგარიშსწორება
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
                <Link to={"/options/tbs-pay"}>
                    <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                        <div className='flex items-center gap-2'>
                            <div>
                                <BsBox2 className='text-blue-1000 text-base' />
                            </div>
                            <p className="text-md text-blue-1000">
                                უნაღდო ანგარიშსწორება (საბანკო ბარათები)
                            </p>
                        </div>
                        <div>
                            <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                        </div>
                    </div>
                </Link>
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            საბანკო განვადება
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            გადახდა ინვოისით
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            ჩარიცხვის აპარატები
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSystems