import { useEffect, useState } from "react";

const Switch = ({ inputSetter, inputName, value }) => {
  const [checkedValue, setCheckedValue] = useState(false);

  const handleCheckedValue = (e) => {
    setCheckedValue(e.target.checked);
    if (inputSetter) {
      inputSetter(e.target.checked, inputName);
    }
  };

  useEffect(() => {
    setCheckedValue(value);
  }, [value]);

  return (
    <div>
      <label className="relative inline-block w-[50px] h-[26px]">
        <input
          onChange={(e) => {
            handleCheckedValue(e);
          }}
          type="checkbox"
          className="opacity-0 w-0 h-0"
        ></input>
        <span
          className={`
                    rounded-full
                    slider absolute cursor-pointer top-0 left-0 right-0 before:rounded-full
                    bottom-0 before:content[''] before:w-[18px] before:h-[18px]
                    before:absolute before:bg-white before:left-[4px] before:top-[4px] 
                    ${
                      checkedValue
                        ? "before:translate-x-[24px] bg-blue-1000"
                        : "bg-gray-300"
                    } before:transition-all
                    before:ease-in`}
        ></span>
      </label>
    </div>
  );
};

export default Switch;
