import { Line } from "../../components/inputs/Line"

const MessageCard = ({ active = false }) => {
    return (
        <div className={`w-full cursor-pointer ${active ? "bg-gray-100_01" : "bg-white"}`}>
            <div className="w-full flex">
                <div className={`my-auto w-[3%] ${active ? "bg-deep_purple-600 h-[94px]" : "bg-white"}`}></div>
                <div className="flex items-center p-4 gap-2">
                    <div className="bg-gray-400 h-12 mt-3 rounded-[14px] w-12"></div>
                    <div className="mt-1">
                        <div className="flex items-center justify-between">
                            <p className="text-gray-900 text-sm font-bold">
                                გიორგი ბერიძე
                            </p>
                            <p className="text-gray-500 text-xs">
                                12:45 PM
                            </p>
                        </div>
                        <p className="mt-[3px] text-gray-900 text-sm">
                            დიდი მადლობა თქვენს კომპანიას!
                        </p>
                    </div>
                </div>
            </div>
            <Line className="bg-gray-400_71 h-px w-full" />
        </div >
    )
}

export default MessageCard