//library imports
import { useEffect, useState } from "react";
//components
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/inputs/Button";
import SelectInput from "../../components/inputs/SelectInput";
import MultipleFileInput from "../../components/inputs/MultipleFIleInput";
//functions
import { successToast, errorToast } from "../../components/toast/ToastAlerts";
import protectedAxios from "../../api/protectedAxios";
import getSingleRecord from "../../Services/getSingle";
import updateSingleRecord from "../../Services/updateSingle";
//resources
import { BsX } from "react-icons/bs";
import { useParams } from "react-router-dom";
import getListForSelection from "../../Services/getListForSelection";

const EditCategory = () => {
  //get single category id from params
  const { categoryId } = useParams();
  //new category data
  const [categoryData, setCategoryData] = useState({});
  //update state
  const [updateData, setUpdateData] = useState(null);
  //parent data
  const [parentData, setParentData] = useState([]);

  //handle categories data
  const handleCategoryData = (value, key) => {
    setCategoryData((prev) => ({ ...prev, [key]: value }));
  };

  //change category data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("type", categoryData.type);
    if (categoryData.parentCategoryId) {
      formData.append("parentCategoryId", categoryData.parentCategoryId);
    }

    updateSingleRecord({
      url: "/admin/category/",
      id: categoryId,
      payload: formData,
    });
  }

  //delete img
  const handleDeleteSingleImage = (imgId) => {
    protectedAxios
      .delete(`/admin/categoryPhoto/${categoryId}/${imgId}`)
      .then((res) => {
        setUpdateData(res);
        successToast("ფოტო წაიშალა!");
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //change category object and save it in new state
  useEffect(() => {
    getListForSelection({
      url: "/admin/category",
      stateUpdater: setCategoryData,
      keyForId: "id",
      keyForTitle: "name",
    });
  }, []);

  //get categories data from db
  useEffect(() => {
    getSingleRecord({
      url: "/admin/category/",
      id: categoryId,
      stateUpdater: setCategoryData,
    });
  }, [updateData]);

  return (
    <div className="min-h-screen w-full p-5">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        კატეგორიის რედაქტირება
      </h2>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        encType="multipart/form-data"
        className="w-full"
      >
        <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
          <div className="w-full col-span-2 max-lg:col-span-1 shadow-my rounded-2xl p-5">
            <div className="w-full grid grid-cols-1 gap-5">
              <TextInput
                name={"კატეგორიის სახელი"}
                inputName={"name"}
                value={categoryData.name}
              />

              <TextInput name={"SEO URL"} inputName={"link"} />
              <TextInput name={"SEO თეგები"} inputName={"ceo_keywords"} />
              <TextInput name={"SEO აღწერა"} inputName={"description"} />
              <p className="text-slate-500 text-sm">კატეგორია</p>
              <SelectInput
                name={"მშობელი კატეგორია"}
                options={parentData}
                inputSetter={handleCategoryData}
                inputName={"parentCategoryId"}
                value={categoryData.parentCategoryId}
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
            <div className=" w-full flex gap-5 p-5 flex-col shadow-my rounded-2xl">
              <div className="grid grid-cols-4 gap-2">
                {categoryData.imgs &&
                  categoryData.imgs.map((img) => {
                    return (
                      <div
                        key={img.id}
                        className={`w-full max-sm:h-auto relative rounded-xl bg-white border border-slate-200`}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${img.url}`}
                          className="w-full rounded-xl h-auto object-cover"
                          alt="#"
                        />
                        <div
                          className="rounded-full w-4 h-4 bg-red-400 flex items-center justify-center 
                                            absolute top-0 right-0 translate-x-[50%] translate-y-[-50%] cursor-pointer"
                          onClick={() => {
                            handleDeleteSingleImage(img.id);
                          }}
                        >
                          <BsX className="text-gray-100 text-2xl" />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <MultipleFileInput
                addonText={"კატეგორიის სურათის"}
                inputName={"category_imgs"}
              />
              <p className="text-slate-500 text-sm">
                ატვირთეთ ახალი კატეგორიის სურათი (სურვილისამებრ)
              </p>
              <Button
                style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
                text={"განახლება"}
                type={"submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCategory;
