import { Line } from "../inputs/Line";
import protectedAxios from "../../api/protectedAxios";
import { useState, useEffect, useRef } from "react";
import PaginatedItems from "../pagination/Pagination";
import MultipleFileInput from "../inputs/MultipleFIleInput";
import { successToast, errorToast } from "../toast/ToastAlerts";
import Button from "../inputs/Button";

import { BsFillCloudArrowUpFill, BsTrash, BsX } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";
import TextInput from "../inputs/TextInput";
import Textarea from "../inputs/Textarea";

const Gallery = ({ inputSetter }) => {
  //drop area ref
  const dropAreaRef = useRef(null);
  //gallery toggle
  const [isOpen, setIsOpen] = useState(false);
  //images
  const [image, setImages] = useState([]);
  //loading state
  const [isLoading, setIsLoading] = useState(false);
  //page tracker
  const [page, setPage] = useState(0);
  //total data number on this page
  const [total, setTotal] = useState(0);
  //update gallery data
  const [updateGalleryData, setUpdateGalleryData] = useState(null);
  //selected photos
  const [selectedImages, setSelectedImages] = useState(null);
  //section
  const [gallerySection, setGallerySection] = useState(1);
  //dragged files
  const [draggedFiles, setDraggedFiles] = useState([]);

  //get gallery photos
  const handleGetPhotos = () => {
    setIsLoading(true);
    protectedAxios
      .get(`/admin/galery?page=${page}&per_page=12`)
      .then(({ data }) => {
        setImages(data.data);
        setTotal(data.total);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  //handle image clicking
  const handleImageInteractions = (id) => {
    setSelectedImages(id);
  };

  //add new photos
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const listItems = Object.entries(draggedFiles).map((key, value) => {
      return key[1];
    });

    listItems.forEach((file) => {
      formData.append(`product_imgs`, file);
    });

    protectedAxios
      .post(`/admin/galery`, formData)
      .then((res) => {
        setUpdateGalleryData(res);
        successToast("სურათი წარმატებით დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  }

  //delete single img
  const handleDeleteSingleImage = (id) => {
    protectedAxios
      .delete(`/admin/galery/${id}`)
      .then((res) => {
        setUpdateGalleryData(res);
        successToast("სურათი წაიშალა დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  /////////////////////////// drag and drop area start /////////////////////////////////

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      setDraggedFiles(files);
    }
  };

  useEffect(() => {
    if (gallerySection === 0) {
      dropAreaRef.current.addEventListener("dragover", handleDragOver);
      dropAreaRef.current.addEventListener("drop", handleDrop);

      //   return () => {
      //     dropAreaRef.current.removeEventListener("dragover", handleDragOver);
      //     dropAreaRef.current.removeEventListener("drop", handleDrop);
      //   };
    }
  }, [gallerySection]);

  /////////////////////////// drag and drop area end /////////////////////////////////

  //get gallery photos based of effect
  useEffect(() => {
    handleGetPhotos();
  }, [page, updateGalleryData]);

  //retrieve selected images from this components
  useEffect(() => {
    if (inputSetter) {
      inputSetter(selectedImages);
    }
  }, [selectedImages]);

  return (
    <div>
      <div>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="rounded-2xl border-dashed border-2 p-3 cursor-pointer
                     border-gray-300 bg-gray-100 flex items-center gap-2"
        >
          <div className="bg-blue-1000 h-8 w-8 rounded-full p-2">
            <BsFillCloudArrowUpFill className="text-white w-full h-full" />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-slate-600">გალერია</p>
            <p className="text-xs text-slate-500">აირჩიეთ ფოტო გალერიიდან</p>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-black-900 bg-opacity-80 z-50 flex items-center justify-center p-10">
          <div className="w-full h-full bg-white rounded-2xl ">
            <div className="h-[8%] w-full">
              <div className="w-full flex justify-between items-center p-5">
                <div className="flex items-center gap-3">
                  <p
                    onClick={() => {
                      setGallerySection(0);
                    }}
                    className="text-slate-600 text-sm cursor-pointer"
                  >
                    ფაილის ატრვირთვა
                  </p>
                  <p
                    onClick={() => {
                      setGallerySection(1);
                    }}
                    className="text-slate-600 text-sm cursor-pointer"
                  >
                    ყველა ფაილი
                  </p>
                </div>
                <div>
                  <BsX
                    className="text-slate-500 text-2xl cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
              <Line className="h-[1px] w-full bg-slate-300" />
            </div>
            <div className="flex h-[92%] p-5">
              {gallerySection === 1 ? (
                <>
                  {isLoading ? (
                    <div className="mt-5 flex items-center justify-center min-h-[200px] w-full">
                      <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000 " />
                    </div>
                  ) : (
                    isLoading === false && (
                      <>
                        <div className="h-full w-8/12">
                          <div
                            className="max-sm:overflow-auto scrollbar grid grid-cols-4 grid-rows-3 gap-3 h-[90%] overflow-scroll scrollbar
                      max-lg:grid-cols-2 max-lg:grid-rows-none max-md:grid-cols-1"
                          >
                            {image.length === 0 ? (
                              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                <p className="text-slate-600 text-sm">
                                  მონაცემები არ მოიძებნა
                                </p>
                              </div>
                            ) : (
                              image.map((img) => {
                                return (
                                  <div
                                    key={img.id}
                                    className={`bg-slate-100 rounded-lg overflow-hidden h-full cursor-pointer relative
                                                      max-md:min-h-[240px] ${
                                                        selectedImages ===
                                                          img.id &&
                                                        "border-2 border-green-500"
                                                      }`}
                                  >
                                    <img
                                      onClick={() => {
                                        handleImageInteractions(img.id);
                                      }}
                                      src={`${process.env.REACT_APP_API_URL}/${img.url}`}
                                      className="w-full h-full object-contain max-lg:object-cover "
                                      loading="lazy"
                                    />
                                    <div
                                      onClick={() => {
                                        handleDeleteSingleImage(img.id);
                                      }}
                                      className="absolute top-2 right-2 w-8 h-8 rounded-lg p-2 bg-red-500"
                                    >
                                      <BsTrash className="text-white w-full h-full" />
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                          <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2 h-[10%]">
                            <PaginatedItems
                              dataLength={total}
                              itemsPerPage={8}
                              pageNumberSetter={setPage}
                              forcedPage={page}
                            />
                          </div>
                        </div>
                        <div className="w-4/12 overflow-auto scrollbar flex flex-col gap-5 p-2">
                          <div className="border-b border-slate-300 pb-4">
                            <div className="w-52 h-52 bg-green-500 rounded-lg"></div>
                            <p className="text-slate-600 text-[12px] mt-4">
                              WhatsApp-Image-2023-11-23-at-18.55.51.jpeg
                            </p>
                          </div>
                          <div className=" h-52">
                            <Textarea
                              placeholder={"ატრიბუტი ALT"}
                              inputSetter={() => {}}
                            />
                          </div>
                          <TextInput
                            placeholder={"სათაური"}
                            inputSetter={() => {}}
                          />
                          <div className=" h-52">
                            <Textarea
                              placeholder={"წარწერა"}
                              inputSetter={() => {}}
                            />
                          </div>
                          <div className=" h-52">
                            <Textarea
                              placeholder={"აღწერა"}
                              inputSetter={() => {}}
                            />
                          </div>
                          <TextInput
                            placeholder={"ფაილის URL მისამართი"}
                            inputSetter={() => {}}
                          />
                          <Button
                            style={
                              "bg-blue-1000 text-white rounded-lg py-2 px-4"
                            }
                            text={"შენახვა"}
                          />
                        </div>
                      </>
                    )
                  )}
                </>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <div
                    className="w-full h-full flex items-center justify-center"
                    ref={dropAreaRef}
                  >
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                      encType="multipart/form-data"
                      className="flex flex-col gap-2"
                    >
                      <MultipleFileInput
                        height={"max-h-[200px]"}
                        inputName={"product_imgs"}
                        draggedFiles={draggedFiles}
                      />
                      <Button
                        style={
                          "rounded-xl bg-blue-1000 text-white w-full p-2"
                        }
                        text={"დამახსოვრება"}
                        type={"submit"}
                      />
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
