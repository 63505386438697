import Button from "../inputs/Button";
import img from "../../img/finish-image.png";
import anim from "../../img/anim.json";
import useScreenSize from "../ScreenSize";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";

const QuestionFinish = () => {
  const screenSize = useScreenSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [imageDimensions, setImageDimensions] = useState({
    x: 500,
  });

  useEffect(() => {
    if (screenSize[0] >= 500 && screenSize[0] <= 650) {
      setImageDimensions({
        x: 500,
      });
    } else if (screenSize[0] >= 0 && screenSize[0] <= 500) {
      setImageDimensions({
        x: 500,
      });
    }
  }, [screenSize[0]]);

  return (
    <div className="w-full pl-[80px] pr-[100px] h-screen relative flex items-center justify-between">
      <div className="relative z-30 ml-20 w-[40%]">
        <h2 className="max-sm:text-md max-md:text-lg text-3xl text-blue-1000">
          გილოცავთ
        </h2>
        <p className="text-sm text-slate-600 mt-10">
          პირველი ეტაპი წარმატებით დასრულდა
        </p>
        <div className="flex gap-2 items-center mt-20">
          <Button
            style={
              "rounded-lg bg-blue-1000 font-bold text-white py-2 px-4 "
            }
            text={"ონლაინ მაღაზიის დათვალიერება"}
          />
          <Button
            style={
              "rounded-lg bg-blue-1000 font-bold text-white py-2 px-4 "
            }
            text={"ონლაინ მაღაზიის სამარტავი პანელი"}
          />
        </div>
      </div>
      <div className="flex justify-center w-[50%]">
        {/* <img src={img} className="w-full" /> */}
        <Lottie
          animationData={anim}
          loop
          autoplay
          style={{ height: imageDimensions.y, width: imageDimensions.x }}
        />
      </div>
    </div>
  );
};

export default QuestionFinish;
