import { Route, Routes } from "react-router-dom";
import BrandOptions from "../brandOptions/BrandOptions";
import Plugins from "../plugins/Plugins";
import Memberships from "../membership/Memberships";
import PaymentSystems from "../paymentSystems/PaymentSystems";
import { TbsPay } from "../membership/TbsPay";
import Common from "../common/Common";
import NotificationOptions from "../notificationOptions/NotificationOptions";
import BillingOptions from "../billingOptions/BillingOptions";
import CartOptions from "../cartOptions/CartOptions";
import DeliveryOptions from "../deliveryOptions/DeliveryOptions";
import Policy from "../Policy/Policy";
import LocationSettings from "../locationSettings/LocationSettings";
import AddLocation from "../locationSettings/AddLocation";
import Promocode from "../promocode/Promocode";
import Domens from "../domens/Domens";
import AddLanguage from "../addLanguage/AddLanguage";
import ActivityJournal from "../activityJournal/ActivityJournal";

const OptionsRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/location-options" element={<LocationSettings />} />

                <Route path="/market-config" element={<BrandOptions />} />

                <Route path="/plugin-options" element={<Plugins />} />
                <Route path="/membership" element={<Memberships />} />
                <Route path="/payment-systems" element={<PaymentSystems />} />
                <Route path="/tbs-pay" element={<TbsPay />} />
                <Route path="/common" element={<Common />} />
                <Route path="/notifications" element={<NotificationOptions />} />
                <Route path="/billing" element={<BillingOptions />} />
                <Route path="/cart-options" element={<CartOptions />} />
                <Route path="/delivery-options" element={<DeliveryOptions />} />
                <Route path="/promo-code" element={<Promocode />} />
                <Route path="/add-location" element={<AddLocation />} />
                <Route path="/domen" element={<Domens />} />
                <Route path="/add-language" element={<AddLanguage />} />
                <Route path="/activity-journal" element={<ActivityJournal />} />
                <Route path="/policy" element={<Policy />} />

            </Routes>
        </>
    )
}

export default OptionsRouter