const PluginRating = () => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">ვერსია</span> 5.7.8
      </p>
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">ავტორი</span> ჩიქო
      </p>
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">ბოლო განახლება</span> ხვალზეით
      </p>
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">აქტიური ინსტალაციები</span> 5+ მილიონზე
        მეტი
      </p>
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">ვერსია</span> 5.7.8
      </p>
      <p className="text-[#3c434a] text-[14px]">
        <span className="font-[600]">ვერსია</span> 5.7.8
      </p>

      <div className="flex flex-col gap-2 border-t border-slate-300 pt-2">
        <p className="text-[#3c434a] text-[14px] font-[600]">რეიტინგი</p>

        <p className="text-[#3c434a] text-[14px] font-[600]">
          5 ვარსკვლავი <span className="font-normal text-orange-400">1233</span>
        </p>
        <p className="text-[#3c434a] text-[14px] font-[600]">
          4 ვარსკვლავი <span className="font-normal text-orange-400">1233</span>
        </p>
        <p className="text-[#3c434a] text-[14px] font-[600]">
          3 ვარსკვლავი <span className="font-normal text-orange-400">1233</span>
        </p>
        <p className="text-[#3c434a] text-[14px] font-[600]">
          2 ვარსკვლავი <span className="font-normal text-orange-400">1233</span>
        </p>
        <p className="text-[#3c434a] text-[14px] font-[600]">
          1 ვარსკვლავი <span className="font-normal text-orange-400">1233</span>
        </p>

        <p className="text-[#3c434a] text-[14px] font-[600]">
          სულ <span className="font-normal text-orange-400">123123</span>
        </p>
      </div>
    </div>
  );
};

export default PluginRating;
