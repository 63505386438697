import { Line } from "../../components/inputs/Line"
import PaginatedItems from "../../components/pagination/Pagination"
import SearchInput from "../../components/inputs/SearchInput"

const ActivityJournal = () => {
    return (
        <div className="min-h-screen w-full p-5 ">

            <div className="mt-10 bg-white shadow-my rounded-2xl w-full">
                <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between p-5 pb-0 max-md:flex-col max-md:gap-2">
                        <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-md:w-full">
                            აქტივობის ჟურნალი
                        </h2>
                        <div className="gap-2 flex max-md:flex-col max-md:gap-2 max-md:w-full">

                            <div className="w-32 max-md:w-full ">
                                <SearchInput />
                            </div>
                        </div>
                    </div>
                    <div className="max-sm:overflow-auto scrollbar">
                        <div className="max-sm:w-[1000px]">
                            <div className="grid grid-cols-7 p-5">
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        თარიღი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        ავტორი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        IP
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        ტიპი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        იარლიყი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        მოქმედება
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        აღწერა
                                    </p>
                                </div>
                            </div>
                            <Line className="bg-gray-400_87 w-full h-[1px]" />
                            <div className="grid grid-cols-7 p-5">
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        10/23/12
                                    </p>
                                </div>
                                <div className="flex flex-col gap-1 items-center">
                                    <p className="text-slate-500 text-sm">
                                        სახელი
                                    </p>
                                    <p className="text-slate-500 text-xs">
                                        როლი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        12.23.12
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        ტექსტი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        პოსტი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        განაახლა
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        მოკლე აღწერა
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-5 max-sm:flex-col max-sm:gap-2 max-sm:items-start">
                        <p className="text-sm text-slate-600 max-sm:w-full">
                            ნაჩვენებია 1-10 სულ 46 შეკვეთა
                        </p>
                        <PaginatedItems />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivityJournal