//library imports
import { useState } from "react";
//components
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/inputs/Button";
//functions
import addNewRecord from "../../Services/addNew";
//resources
import { BsEye } from "react-icons/bs";
import SelectInput from "../../components/inputs/SelectInput";
import FileInput from "../../components/inputs/FileInput";

const AddAdmin = () => {
  //new admin data
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    password: "",
    role: "1",
  });
  //clear fields
  const [clearFields, setClearFields] = useState(false);

  //handle admins data
  const handleAdminData = (value, key) => {
    setAdminData((prev) => ({ ...prev, [key]: value }));
  };

  //clear fields
  const handleClearFields = () => {
    setClearFields(true);
  };

  //create new admin
  const handleCreateNewAdmin = () => {
    addNewRecord({
      url: "/admin/admins",
      payload: adminData,
      clearFieldsFunction: handleClearFields,
    });
  };

  return (
    <div className="min-h-screen w-full p-5">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        ადმინისტრატორის დამატება
      </h2>
      <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
        <div className="w-full col-span-2 max-lg:col-span-1 ">
          <div className="w-full grid grid-cols-1 gap-5 shadow-my p-5 rounded-2xl">
            <TextInput
              name={"ადმინისტრატორი"}
              placeholder={"სახელი გვარი"}
              inputSetter={handleAdminData}
              inputName={"name"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />
            <TextInput
              name={"ელ-ფოსტა"}
              placeholder={"...@gmail.com"}
              inputSetter={handleAdminData}
              inputName={"email"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />
            <TextInput
              name={"რეგიონი"}
              placeholder={"რეგიონი"}
              inputSetter={handleAdminData}
              inputName={"region"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />
            <TextInput
              name={"ქალაქი"}
              placeholder={"ქალაქი"}
              inputSetter={handleAdminData}
              inputName={"city"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />
            <p className="text-slate-500 text-sm">ადმინისტრატორის როლი</p>
            <SelectInput
              name={"ადმინისტრატორის როლი"}
              options={[
                {
                  id: 1,
                  title: "სუპერ ადმინი",
                },
                {
                  id: 2,
                  title: "მაღაზიის მმართველი",
                },
                {
                  id: 3,
                  title: "კონტენტის მმართველი",
                },
                {
                  id: 4,
                  title: "შეკვეთების მმართველი",
                },
                {
                  id: 5,
                  title: "კურიერი",
                },
              ]}
              inputSetter={handleAdminData}
              clearFields={clearFields}
              setClearFields={setClearFields}
              inputName={"role"}
            />
            <TextInput
              name={"პაროლი"}
              placeholder={"მინიმუმ 6 სიმბოლო"}
              inputSetter={handleAdminData}
              SuffixIcon={BsEye}
              type={"password"}
              inputName={"password"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />

            <TextInput
              name={"პაროლის გამეორება"}
              placeholder={"მინიმუმ 6 სიმბოლო"}
              inputSetter={handleAdminData}
              SuffixIcon={BsEye}
              type={"password"}
              inputName={"password"}
              clearFields={clearFields}
              setClearFields={setClearFields}
            />

            პაროლის სიძლიერე
          </div>
        </div>
        <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
          <div className="shadow-my p-5 rounded-2xl">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800 mb-5">
              პროფილის ფოტო
            </h2>
            <div className="flex items-center gap-4">
              <div className="w-16 h-16 rounded-full bg-gray-400 shrink-0"></div>
              <FileInput />
            </div>
            <div className="w-full grid gap-2 mt-5 ">
              <Button
                onClickFunction={handleCreateNewAdmin}
                style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
                text={"დამატება"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
