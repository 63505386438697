import protectedAxios from "../api/protectedAxios";
import { successToast, errorToast } from "../components/toast/ToastAlerts";

export default function updateSingleRecord({
    url,
    id,
    payload,
    successMessage = "მონაცემები განახლდა",
    errorMessage = "დაფიქსირდა შეცდომა",
}) {
    protectedAxios.post(`${url}${id}`, payload)
        .then((_) => {
            successToast(successMessage)
        })
        .catch((_) => {
            errorToast(errorMessage)
        })
}
