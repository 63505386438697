import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"
import RadioButton from "../../components/inputs/RadioButton"
import TextInput from "../../components/inputs/TextInput"

import { BsCheck } from "react-icons/bs"

const DeliveryOptions = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                გადაზიდვა და მიწოდება
            </h2>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        მიწოდების პარამეტრების მართვა
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        აირჩიეთ მიწოდების მეთოდი
                    </p>
                    <div className="w-full grid grid-cols-2 gap-2 max-sm:grid-cols-1">
                        <div className="w-full mt-4 flex flex-col gap-2">
                            <RadioButton
                                name={"მაღაზიიდან გატანა/თვიტმომსახურება"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"საკუთარი მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"გლოვოს მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"ვოლტის მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                        </div>
                        <div className="w-full mt-4 flex flex-col gap-2">
                            <RadioButton
                                name={"იზივეის მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"საქართველოს ფოსტის მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"უცხოეთში მიტანის სერვისი"}
                                uniqName={"111"}
                            />
                            <RadioButton
                                name={"დელივერონი"}
                                uniqName={"111"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        საკუთარი მიტანის სერვისი(მოცულობითი პარამეტრები)
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col gap-4">
                    <TextInput
                        name={"წონა (შეიყვანეთ მაქსიმუმი)"}
                    />
                    <TextInput
                        name={"სიმაღლე (შეიყვანეთ მაქსიმუმი)"}
                    />
                    <TextInput
                        name={"სიგრძე (შეიყვანეთ მაქსიმუმი)"}
                    />
                    <TextInput
                        name={"სიგანე (შეიყვანეთ მაქსიმუმი)"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full grid grid-cols-4">
                    <p className="text-blue-1000 text-sm max-sm:text-center max-sm:text-xs">
                        ლოკაცია
                    </p>
                    <p className="text-blue-1000 text-sm max-sm:text-center max-sm:text-xs">
                        მიწოდების ფასი
                    </p>
                    <p className="text-blue-1000 text-sm max-sm:text-center max-sm:text-xs">
                        უფასო მიწოდება იწყება
                    </p>
                    <p className="text-center max-sm:text-xs">
                        სტატუსი
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 w-full grid grid-cols-4">
                    <div>
                        <p className="text-slate-600 text-sm">
                            თბილისი
                        </p>
                    </div>
                    <div>
                        <p className="text-slate-600 text-sm max-sm:text-center">
                            5
                        </p>
                    </div>
                    <div>
                        <p className="text-slate-600 text-sm max-sm:text-center">
                            500
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        <Button
                            Icon={BsCheck}
                            style={"border bg-green-200 text-green-500 rounded-lg py-2 px-4 text-sm flex gap-2"}
                            text={"შენახვა"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 w-full grid grid-cols-4">
                    <div>
                        <p className="text-slate-600 text-sm">
                            თბილისი
                        </p>
                    </div>
                    <div>
                        <p className="text-slate-600 text-sm max-sm:text-center">
                            5
                        </p>
                    </div>
                    <div>
                        <p className="text-slate-600 text-sm max-sm:text-center">
                            500
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        <Button
                            Icon={BsCheck}
                            style={"border bg-green-200 text-green-500 rounded-lg py-2 px-4 text-sm flex gap-2"}
                            text={"შენახვა"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryOptions