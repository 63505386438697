import { useState, useRef } from "react";
import { BsFillCloudArrowUpFill, BsX } from "react-icons/bs";

const FileInput = ({
    addonText,
    inputName
}) => {
    const [fileState, setFileState] = useState(null);
    const inputElement = useRef(null);

    const handleChange = (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
            setFileState(URL.createObjectURL(selectedFile));
        } else {
            setFileState(null);
        }
    }

    const handleOpenInput = () => {
        inputElement.current.click();
    }

    const handleClear = () => {
        setFileState(null);
        inputElement.value = null
    }

    return (

        <div className="w-full">
            <div className="rounded-2xl border-dashed border-2 p-3 gap-3 cursor-pointer
                     border-gray-300 bg-gray-100 flex items-center " >
                <div
                    className="flex items-center gap-3"
                    onClick={handleOpenInput}
                >
                    <div className="bg-blue-1000 h-8 w-8 rounded-full p-2">
                        <BsFillCloudArrowUpFill className="text-white w-full h-full" />
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-sm text-slate-600">{addonText} ატვირთვა</p>
                        <p className="text-xs text-slate-500">JPG, PNG,SVG,WEBP</p>
                    </div>
                </div>

                {fileState &&
                    (<div className="relative max-sm:h-auto">
                        <div className="flex items-center gap-2 max-sm:flex-col">
                            <img src={fileState} className="w-12 h-12 cover rounded-lg" alt="#" />
                        </div>
                        <div className="rounded-full w-4 h-4 bg-red-400 flex items-center justify-center absolute top-0 right-0 translate-x-[50%] translate-y-[-50%]"
                            onClick={handleClear}>
                            <BsX className="text-gray-100 text-2xl" />
                        </div>
                    </div>)
                }
            </div>
            <input
                name={inputName}
                ref={inputElement}
                className="hidden"
                type="file"
                onChange={handleChange} />
        </div>
    )
}

export default FileInput