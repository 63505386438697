const PluginDiscussion = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 border-b border-slate-300 pb-4">
        <h2 className="text-[14px] text-[#3c434a] font-semibold">
          Not compatible with Compilanz
        </h2>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-green-500"></div>
          <p className="text-[#3c434a] text-[14px]">
            ავტორი: dominiks8318, თებერვალი 1, 2024
          </p>
        </div>

        <p className="text-[#3c434a] text-[14px]">
          Plugin is not compatible with Compilanz.
        </p>
      </div>
      <div className="flex flex-col gap-4 border-b border-slate-300 pb-4">
        <h2 className="text-[14px] text-[#3c434a] font-semibold">
          Not compatible with Compilanz
        </h2>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-green-500"></div>
          <p className="text-[#3c434a] text-[14px]">
            ავტორი: dominiks8318, თებერვალი 1, 2024
          </p>
        </div>

        <p className="text-[#3c434a] text-[14px]">
          Plugin is not compatible with Compilanz.
        </p>
      </div>
    </div>
  );
};

export default PluginDiscussion;
