//library imports
import { useEffect, useState } from "react";
//components
import SearchInput from "../../components/inputs/SearchInput";
import OrderStatusCardd from "../../components/dataCards/OrderStatusCardd";
import SelectInput from "../../components/inputs/SelectInput";
import PaginatedItems from "../../components/pagination/Pagination";
import Checkbox from "../../components/inputs/Checkbox";
import Button from "../../components/inputs/Button";
import { Line } from "../../components/inputs/Line";
//functions
import getAllRecords from "../../Services/getAll";
import deleteRecord from "../../Services/delete";
import getAllWithoutPaginate from "../../Services/getAllWithoutPaginate";
import updateManyRecords from "../../Services/updateMany";
import { errorToast } from "../../components/toast/ToastAlerts";
import useGetOrderStatuses from "../../components/generalData/useGetOrderStatuses";

//resources
import { BiLoaderAlt } from "react-icons/bi";
import { BsFillTrash3Fill } from "react-icons/bs";
import { FaRegSave } from "react-icons/fa";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState({
    branches: [],
    categories: [],
    specification: [
      {
        id: "1",
        title: "ახალი",
      },
      {
        id: "3",
        title: "ძველი",
      },
    ],
  });
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [updateOrderRecords, setUpdateOrderRecords] = useState(null);
  //filter and other input select values
  const [filterValues, setFilterValues] = useState({});
  //order statuses
  const orderStatuses = useGetOrderStatuses();

  const handleModifyFilterValues = (value, key) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddCheckOrder = (code) => {
    setCheckedOrders((prev) => [...prev, code]);
  };

  const handleUncheckOrder = (code) => {
    setCheckedOrders((prev) => prev.filter((item) => item !== code));
  };

  const handleGetBranches = () => {
    const setBranchesData = (value) => {
      setFilterData((prev) => ({
        ...prev,
        branches: value.map((val) => {
          return {
            id: val.id,
            title: val.name,
          };
        }),
      }));
    };
    getAllWithoutPaginate({
      url: "/admin/branch",
      stateUpdater: setBranchesData,
    });
  };

  const handleDeleteOrder = () => {
    deleteRecord({
      url: `/admin/order?orderIds=${JSON.stringify(checkedOrders)}`,
      updateFunction: setUpdateOrderRecords,
    });
  };

  //update statuses on many order at the same time
  const handleUpdateStatuses = () => {
    if (filterValues.orderStatus) {
      updateManyRecords({
        url: "admin/changeOrderStatus",
        payload: {
          orderIds: JSON.stringify(checkedOrders),
          status: filterValues.orderStatus,
        },
        updateFunction: setUpdateOrderRecords,
      });
    } else {
      errorToast("სტატუსის ველი ცარიელია");
    }
  };

  useEffect(() => {
    getAllRecords({
      url: "/admin/order",
      page: page,
      otherParams: [
        {
          key: "key",
          value: searchValue,
        },
        {
          key: "filial",
          value: "",
        },
      ],
      handleLoading: setIsLoading,
      handleTotal: setTotal,
      handleData: setOrders,
    });
  }, [page, searchValue, updateOrderRecords]);

  useEffect(() => {
    handleGetBranches();
  }, []);

  return (
    <div className="min-h-screen w-full">
      <div className="w-full p-5">
        <div className="mt-5 bg-white rounded-2xl w-full shadow-my">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between p-5 pb-0 max-sm:flex-col max-sm:gap-2">
              <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                შეკვეთები
              </h2>
              <div className="gap-2 flex max-sm:flex-col max-sm:gap-2 max-sm:w-full">
                {checkedOrders.length !== 0 ? (
                  <>
                    <div className="shrink-0">
                      <Button
                        text={"მონიშნულის წაშლა"}
                        style={`bg-red-500 cursor-pointer px-4 py-2 rounded-lg text-base text-center text-white
                                                flex items-center gap-2`}
                        Icon={BsFillTrash3Fill}
                        onClickFunction={handleDeleteOrder}
                      />
                    </div>
                    <div className="shrink-0">
                      <SelectInput
                        options={orderStatuses}
                        placeholder={"მონიშნულის სტატუსის შეცვლა"}
                        inputSetter={handleModifyFilterValues}
                        inputName={"orderStatus"}
                      />
                    </div>
                    <div className="shrink-0">
                      <Button
                        style={`bg-blue-1000 cursor-pointer w-9 h-9 rounded-lg text-base text-center text-white
                                                flex items-center justify-center gap-2`}
                        Icon={FaRegSave}
                        onClickFunction={handleUpdateStatuses}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="shrink-0">
                      <SearchInput
                        placeholder={
                          "ტელეფონი, მისამართი, კოდი, მეილი, მომხმარებლის ID"
                        }
                      />
                    </div>
                    <div className="shrink-0">
                      <SelectInput
                        options={filterData.branches}
                        placeholder={"ფილიალი"}
                      />
                    </div>
                    <div className="shrink-0">
                      <SelectInput
                        options={filterData.specification}
                        placeholder={"მახასიათებელი"}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {isLoading ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000" />
              </div>
            ) : isLoading === false && orders.length === 0 ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <p className="text-slate-600 text-sm">მონაცემები არ მოიძებნა</p>
              </div>
            ) : (
              <>
                <div className="max-sm:overflow-auto scrollbar">
                  <div className="max-sm:w-[1000px]">
                    <div className="grid grid-cols-6 p-5 border-b border-slate-200">
                      <div className="flex gap-2 items-center">
                        <Checkbox inputSetter={setAllChecked} />
                        <p className="text-slate-600 text-sm">კოდი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">სტატუსი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">მისამართი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">ტელეფონი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">ელ-ფოსტა</p>
                      </div>
                      <div className="flex gap-2 items-center justify-end">
                        <p className="text-slate-600 text-sm">მოქმედება</p>
                      </div>
                    </div>
                    {orders.map((data) => {
                      return (
                        <div key={data.id}>
                          <Line className="bg-gray-400_87 w-full h-[1px]" />
                          <OrderStatusCardd
                            data={data}
                            handleAddCheckOrder={handleAddCheckOrder}
                            handleUncheckOrder={handleUncheckOrder}
                            allChecked={allChecked}
                            setUpdateOrderRecords={setUpdateOrderRecords}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Line className="bg-gray-400_87 w-full h-[1px]" />
                <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2">
                  <PaginatedItems
                    dataLength={total}
                    itemsPerPage={8}
                    pageNumberSetter={setPage}
                    forcedPage={page}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
