import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    {
        day: 'ორშაბათი',
        c1: 3000,
        c2: 2400,
        c3: 2992,
    },
    {
        day: 'სამშაბათი',
        c1: 2000,
        c2: 1000,
        c3: 2991,
    },
    {
        day: 'ოთხშაბათი',
        c1: 1400,
        c2: 10100,
        c3: 293,
    },
    {
        day: 'ხუთშაბათი',
        c1: 3120,
        c2: 4400,
        c3: 294,
    },

    {
        day: 'პარასკევი',
        c1: 3120,
        c2: 4400,
        c3: 2992,
    },
    {
        day: 'შაბათი',
        c1: 3000,
        c2: 2400,
        c3: 2992,
    },
    {
        day: 'კვირა',
        c1: 2000,
        c2: 1000,
        c3: 2992,
    },
];

const LineChartTwo = ({ lineColor }) => {
    return (
        <ResponsiveContainer width="100%" aspect={2}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="day"
                    axisLine={{ stroke: 'none' }}
                    tickLine={false}
                    tick={{ fontSize: 12, fill: '#a4a4a4' }}
                />
                <YAxis
                    axisLine={{ stroke: 'none' }}
                    tickLine={false}
                    tick={{ fontSize: 12, fill: '#a4a4a4' }}
                />
                <Tooltip />
                <Line type="monotone" dataKey="c1" strokeWidth={3} stroke={lineColor.lineOne} />
                <Line type="monotone" dataKey="c2" strokeWidth={3} stroke={lineColor.lineTwo} />
                <Line type="monotone" dataKey="c3" strokeWidth={3} stroke={lineColor.lineThree} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default LineChartTwo