import { Link } from "react-router-dom";

import Checkbox from "../../components/inputs/Checkbox";
import Button from "../../components/inputs/Button";
import LanguageSwitcher from "../../components/langageSwitcher/LanguageSwitcher";

import { BsEnvelope, BsKey, BsFacebook, BsGoogle } from "react-icons/bs";

import logo from "../../img/bmlogo.png"

const ResetPassword = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="w-7/12 h-screen flex items-center justify-center bg-gray-100 max-lg:hidden ">
                <div>
                    <img src={logo} />
                </div>
            </div>
            <div className="w-5/12 h-screen bg-white gap-[30px] p-10 max-lg:w-8/12 max-md:w-10/12 max-sm:w-full max-sm:p-5 relative">
                <div className="flex gap-5 items-center justify-between absolute top-0 left-0 w-full p-10 max-sm:p-5">
                    <img src={logo} className="h-12" />
                    <LanguageSwitcher />
                </div>
                <div className="h-full flex flex-col justify-center items-center">
                    <div className="mt-4 w-full">
                        <p className="text-xl font-semibold text-blue-1000">აღადგინე პაროლი</p>
                        <p className="text-sm text-slate-500 mt-4">პაროლის აღსადგენად მიუთითე დარეგისტრირებული მეილი</p>
                    </div>
                    <div className="flex flex-col gap-5 mt-4 w-full">
                        <div className="pv-2 h-12 px-3.5 py-1 rounded-xl  group border border-gray-300">
                            <input className="pl-1.5 focus:outline-none text-blue-1000 h-full" type="text" placeholder="ელექტრონული ფოსტა"></input>
                        </div>

                        <div className="mt-4">
                            <Button
                                style={"rounded-xl bg-blue-1000 px-4 py-3 text-white font-bold w-full"}
                                text={"შესვლა"}
                            />
                            <div className="flex gap-2 items-center justify-end mt-4">
                                <Link
                                    to={-1}
                                    className="text-gray-500 text-sm">უკან დაბრუნება</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword


