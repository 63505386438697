const MultipleChoice = ({ name, options = null }) => {
    return (
        <div className='flex items-center gap-5 max-sm:flex-col'>
            <p className="text-slate-600 text-sm w-32 max-sm:w-full">
                {name}
            </p>
            <div className="flex items-center gap-2 max-sm:w-full">
                {options !== null &&
                    options.map((item) => {
                        return (
                            <div className='flex items-center gap-2 border border-slate-300 px-4 py-2 rounded-lg cursor-pointer max-sm:w-full'>
                                <item.Icon className="text-slate-600" />
                                <p className="text-slate-600 text-sm">{item.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default MultipleChoice