const PluginReleases = () => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-[14px] text-[#3c434a] font-semibold">5.8.7</h2>
      <p className="underline text-blue-400 cursor-pointer text-[14px]">
        https://contactform7.com/contact-form-7-587/
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">5.8.7</h2>
      <p className="underline text-blue-400 cursor-pointer text-[14px]">
        https://contactform7.com/contact-form-7-587/
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">5.8.7</h2>
      <p className="underline text-blue-400 cursor-pointer text-[14px]">
        https://contactform7.com/contact-form-7-587/
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">5.8.7</h2>
      <p className="underline text-blue-400 cursor-pointer text-[14px]">
        https://contactform7.com/contact-form-7-587/
      </p>
    </div>
  );
};

export default PluginReleases;
