import protectedAxios from "../api/protectedAxios"
import { errorToast, successToast } from "../components/toast/ToastAlerts"

export default function addNewRecord({
    url,
    payload,
    clearFieldsFunction,
    updateFunction,
    successMessage = "მონაცემი დაემატა",
    errorMessage = "დაფიქსირდა შეცდომა",
}) {
    protectedAxios.post(url, payload)
        .then((res) => {
            successToast(successMessage)
            if (clearFieldsFunction) {
                clearFieldsFunction()
            }
            if (updateFunction) {
                updateFunction(res)
            }
        })
        .catch((error) => {
            errorToast(errorMessage)
        })
}
