//library imports
import { useEffect, useState } from "react"
//components
import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import SelectInput from "../../components/inputs/SelectInput"
//functions
import getSingleRecord from "../../Services/getSingle"
import updateSingleRecord from "../../Services/updateSingle"
import getListForSelection from "../../Services/getListForSelection"
//resources
import { useParams } from "react-router-dom"

const EditFilters = () => {
    //get single filter id from params
    const { filterId } = useParams();
    //new filter data
    const [filterData, setFilterData] = useState({})
    //update state
    const [updateData, setUpdateData] = useState(null)
    //category data
    const [categoryData, setCategoryData] = useState([])

    //handle filters data
    const handleAddNewData = (value, key) => {
        setFilterData(prev => ({ ...prev, [key]: value }))
    }

    //change filters data
    function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        if (filterData.categoryId) {
            formData.append("categoryId", filterData.categoryId);
        }

        updateSingleRecord({
            url: "/admin/categoryFilters/",
            id: filterId,
            payload: formData
        })
    }

    //get filter data from db
    useEffect(() => {
        getSingleRecord({
            url: "/admin/categoryFilters/",
            id: filterId,
            stateUpdater: setFilterData
        })
    }, [updateData])

    //change category object and save it in new state
    useEffect(() => {
        getListForSelection({
            url: "/admin/category",
            stateUpdater: setCategoryData,
            keyForId: "id",
            keyForTitle: "name"
        })
    }, [])

    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                კატეგორიის რედაქტირება
            </h2>
            <form
                onSubmit={(e) => { handleSubmit(e) }}
                encType="multipart/form-data"
                className="w-full"
            >
                <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
                    <div className="w-full col-span-2 max-lg:col-span-1 shadow-my rounded-2xl p-5">
                        <div className="w-full grid grid-cols-1 gap-5">
                            <TextInput
                                name={"ფილტრის სახელი"}
                                inputName={"name"}
                                value={filterData.name}
                            />
                            <TextInput
                                name={"ფილტრის ტიპი"}
                                inputName={"type"}
                                value={filterData.type}
                            />
                            <p className="text-slate-500 text-sm">
                                კატეგორიაზე ფილტრის მიმაგრება
                            </p>
                            <SelectInput
                                name={"კატეგორიები"}
                                options={categoryData}
                                inputSetter={handleAddNewData}
                                inputName={"categoryId"}
                                value={filterData.categoryId}
                            />
                            <TextInput
                                name={"ფილტრის ველები"}
                                inputName={"filter_fields"}
                            />
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
                        <div className=" w-full flex gap-5 p-5 flex-col shadow-my rounded-2xl">
                            <Button
                                style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
                                text={"განახლება"}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default EditFilters
