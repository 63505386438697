import protectedAxios from "../api/protectedAxios";
import { successToast, errorToast } from "../components/toast/ToastAlerts";

export default function deleteRecord({
  url,
  id,
  updateFunction,
  successMessage = "მონაცემი წარმატებით წაიშალა",
  errorMessage = "დაფიქსირდა შეცდომა ცადეთ ხელახლა",
}) {
  protectedAxios
    .delete(`${url}${id ? id : ""}`)
    .then((res) => {
      successToast(successMessage);
      if (updateFunction) {
        updateFunction(res);
      }
    })
    .catch((error) => {
      errorToast(errorMessage);
    });
}
