import { useEffect, useState } from "react";
import Button from "../../components/inputs/Button";
import TextInput from "../../components/inputs/TextInput";
import addNewRecord from "../../Services/addNew";
import getAllWithoutPaginate from "../../Services/getAllWithoutPaginate";
import deleteRecord from "../../Services/delete";
import { TbLanguage } from "react-icons/tb";
import { BsX } from "react-icons/bs";

const AddLanguage = () => {
  //all languages
  const [allLanguages, setAllLanguages] = useState([]);
  //update state
  const [updateLanguages, setUpdateLanguages] = useState(null);
  //new language value container
  const [newLanguage, setNewLanguage] = useState({});

  //adding new values in payload state
  const handleAddNewLanguageValues = (value, key) => {
    setNewLanguage((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //add new record
  const handleAddNewLanguage = () => {
    addNewRecord({
      url: "/admin/language",
      payload: newLanguage,
      updateFunction: setUpdateLanguages,
    });
  };

  //delete record
  const handleDeleteLanguage = (id) => {
    deleteRecord({
      url: "admin/language/",
      id: id,
      updateFunction: setUpdateLanguages,
    });
  };

  useEffect(() => {
    getAllWithoutPaginate({
      url: "/admin/language",
      stateUpdater: setAllLanguages,
    });
  }, [updateLanguages]);

  return (
    <div className="min-h-screen w-full p-5 ">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        ენების დამატება
      </h2>

      <div className="flex flex-col mt-10 shadow-my rounded-2xl p-5">
        <div className="flex gap-2">
          <TbLanguage className={"w-10 h-10 text-slate-400"} />
          <div>
            <p className="text-blue-1000 text-sm">დაამატე ენა</p>
            <p className="text-slate-400 text-xs">
              ისაუბრე შენი მომხმარებლის ენაზე
            </p>
          </div>
        </div>

        <div className="mt-4">
          <TextInput
            placeholder={"(GE, EN, ... ან GEO, ENG, ... ან სხვა)"}
            inputName="code"
            inputSetter={handleAddNewLanguageValues}
            name={"კოდი "}
          />
        </div>

        <div className="flex gap-2 items-center mt-4">
          <Button
            onClickFunction={handleAddNewLanguage}
            style={"bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
            text={"დამატება"}
          />
        </div>
      </div>

      <div className="flex flex-col mt-10 shadow-my rounded-2xl p-5">
        <div>
          <p className="text-blue-1000 text-sm">გამოქვეყნებული ენები</p>
          <p className="text-slate-400 text-xs">
            მარკეტზე აქტიური და მომხმარებლისთვის ხილვადი ენები
          </p>
        </div>
        <div className="flex gap-2 items-center mt-4 flex-wrap">
          {allLanguages?.map((item) => (
            <div
              key={item.id}
              className="flex gap-2 items-center bg-blue-1000 rounded-full py-2 px-4"
            >
              <p className="text-white text-sm">{item.code}</p>
              <BsX
                className="text-white text-xl cursor-pointer"
                onClick={() => {
                  handleDeleteLanguage(item.id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddLanguage;
