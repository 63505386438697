import Button from "../inputs/Button"

const ActivityPopup = () => {
    return (
        <div className="absolute top-[140%] right-0 bg-white flex flex-col gap-5 p-5 rounded-2xl shadow-bs z-40 w-[400px]">
            <div className="flex flex-col gap-5 w-full">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-blue-1000">
                    შეტყობინებები
                </h2>
                <div className="w-full flex flex-col gap-4">
                    <ActivityPopupCard />
                    <ActivityPopupCard />
                    <ActivityPopupCard />
                </div>
            </div>
            <Button
                style="bg-blue-1000 cursor-pointer w-full font-bold p-4 rounded-2xl text-base text-center text-white"
                text={"ყველას ნახვა"}
            />
        </div>
    )
}

const ActivityPopupCard = () => {
    return (
        <div className="flex gap-4 w-full items-start">
            <div className="bg-blue-1000 h-2 rounded-full w-2 mt-1"></div>
            <div className="flex flex-col w-full">
                <div className="flex flex-col gap-2">
                    <p
                        className="text-sm"
                    >
                        <span className="text-slate-600 text-left">
                            მონიკამ გააკეთა კომენტარი {" "}
                        </span>
                        <span className="text-blue-1000 text-left">
                            Kleon Projects - პოსტზე{" "}
                        </span>
                        <span className="text-slate-600 text-left">
                            “ძალიან მაგრები ხართ”
                        </span>
                    </p>
                </div>
                <div className="flex justify-between items-center gap-2 mt-2">
                    <p className="text-[10px] text-slate-500">
                        იანვარი 12, 2023
                    </p>
                    <p className="text-[10px] text-slate-500">
                        2 წუთის წინ
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ActivityPopup