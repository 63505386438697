import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({
    inputSetter,
    value = null,
    title = null,
    inputName
}) => {
    const [editorState, setEditorState] = useState('');

    const handleEditorChange = (content) => {
        setEditorState(content);
    };

    useEffect(() => {
        if (inputSetter) {
            inputSetter(editorState)
        }
    }, [editorState])

    useEffect(() => {
        if (value) {
            setEditorState(value)
        }
    }, [value])

    const modules = {
        toolbar: [
            [
                { 'size': ['small', false, 'large', 'huge'] },
                { 'header': [1, 2, 3, 4, 5, 6, false] },
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { 'list': 'ordered' },
                { 'list': 'bullet' },
                { 'script': 'sub' },
                { 'script': 'super' },
                { 'color': [] },
                { 'background': [] },
                { 'indent': '-1' },
                { 'indent': '+1' },
                { 'align': [] },
                'clean'
            ],
        ]
    };

    return (
        <div>
            {title !== null &&
                <p className="text-slate-600 text-sm mb-3">
                    {title}
                </p>
            }
            <div className="shadow-my rounded-2xl overflow-hidden">
                <ReactQuill
                    value={editorState}
                    onChange={handleEditorChange}
                    modules={modules}
                    className='custom-quill text-slate-600 font-bold min-h-[200px]'
                />
                <textarea
                    name={inputName && inputName}
                    style={{ display: 'none' }}
                    value={editorState}
                    readOnly
                />
            </div>
        </div>
    );
};

export default TextEditor;
