import DropDown from "../../components/inputs/DropDown"
import TechnicalSupport from "./TechnicalSupport"
import SalesSupport from "./SalesSupport"
import InformationSupport from "./InformationSupport"
import { useState } from "react"

import { BiSupport } from "react-icons/bi"
import { BsCodeSlash, BsHandbag } from "react-icons/bs"

const SupportCenter = () => {

    const [currentSection, setCurrentSection] = useState(40)
    const [faqData, setFaqData] = useState([
        {
            id: 1,
            title: "როგორ შევქმნა ონლაინ მაღაზია?",
            text: "უყურეთ ვიდეო გაკვეთილს ონლაინ მაღაზიის შექმნაზე..... ნუ გვაწუხებთ call centre-ის ოპერატორებს...",
            isActive: false,
        },
        {
            id: 2,
            title: "როგორ შევქმნა ონლაინ მაღაზია?",
            text: "უყურეთ ვიდეო გაკვეთილს ონლაინ მაღაზიის შექმნაზე..... ნუ გვაწუხებთ call centre-ის ოპერატორებს...",
            isActive: false
        },
        {
            id: 3,
            title: "როგორ შევქმნა ონლაინ მაღაზია?",
            text: "უყურეთ ვიდეო გაკვეთილს ონლაინ მაღაზიის შექმნაზე..... ნუ გვაწუხებთ call centre-ის ოპერატორებს...",
            isActive: false
        }
    ]);

    const handleOpenFaq = (id) => {
        setFaqData(prev => prev.map((item) => {
            if (id === item.id) {
                return {
                    ...item,
                    isActive: true,
                }
            }
            return {
                ...item,
                isActive: false,
            }
        }))
    }

    const closeFunction = () => {
        setCurrentSection(10)
    }

    return (
        <div className="min-h-screen w-full">
            <div className="col-span-2 p-5 max-lg:col-span-1">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                    დახმარების ცენტრი
                </h2>
                <div className="mt-5 grid grid-cols-3 gap-5 max-sm:grid-cols-1">
                    <div className="rounded-2xl border border-slate-300 flex items-center flex-col justify-center p-5 gap-5 cursor-pointer"
                        onClick={() => { setCurrentSection(0) }}
                    >
                        <p className="text-blue-1000 text-md">გაყიდვების დეპარტამენტი</p>
                        <div className="bg-blue-1000 rounded-full w-12 h-12 p-4">
                            <BsCodeSlash className="w-full h-full text-white" />
                        </div>
                    </div>
                    <div className="rounded-2xl border border-slate-300 flex items-center flex-col justify-center p-5 gap-5 cursor-pointer"
                        onClick={() => { setCurrentSection(1) }}
                    >
                        <p className="text-blue-1000 text-md">გაყიდვების დეპარტამენტი</p>
                        <div className="bg-blue-1000 rounded-full w-12 h-12 p-4">
                            <BsHandbag className="w-full h-full text-white" />
                        </div>
                    </div>
                    <div className="rounded-2xl border border-slate-300 flex items-center flex-col justify-center p-5 gap-5 cursor-pointer"
                        onClick={() => { setCurrentSection(2) }}
                    >
                        <p className="text-blue-1000 text-md">საინფორმაციო ცენტრი</p>
                        <div className="bg-blue-1000 rounded-full w-12 h-12 p-4">
                            <BiSupport className="w-full h-full text-white" />
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    {currentSection === 0 &&
                        <TechnicalSupport
                            closeFunction={closeFunction}
                        />
                    }
                    {currentSection === 1 &&
                        <SalesSupport
                            closeFunction={closeFunction}
                        />
                    }
                    {currentSection === 2 &&
                        <InformationSupport
                            closeFunction={closeFunction}
                        />
                    }
                </div>

                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-5">
                    დახმარების ცენტრი
                </h2>

                <div className="mt-5 grid grid-cols-1 gap-5">
                    {faqData.map((item) => {
                        return (
                            <DropDown
                                key={item.id}
                                id={item.id}
                                openFunction={handleOpenFaq}
                                isActive={item.isActive}
                                title={item.title}
                                text={item.text}
                            />
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default SupportCenter