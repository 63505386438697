import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiChevronUp, BiChevronDown } from "react-icons/bi"

const LanguageSwitcher = () => {
    //handle toggler
    const [toggleSwitcher, setToggleSitcher] = useState(false);
    //current language 
    const [currenLanguage, setCurrentLanguage] = useState({ name: "ge" })
    //language item data
    const [langData, setLangData] = useState([
        {
            name: "ge"
        },
        {
            name: "en"
        }
    ])

    //current languuage handler
    const handleCurrentLang = (object) => {
        setCurrentLanguage({
            name: object.name,
        })
    }

    return (
        <div className="relative z-400">
            <div className="flex gap-1 items-center bg-white rounded-lg cursor-pointer
            px-4 py-2 max-lg:p-2 border-xl border border-slate-300"
                onClick={() => { setToggleSitcher(prev => !prev) }}>
                <p className="text-sm font-semibold w-6 uppercase text-slate-500">{currenLanguage.name}</p>
                {toggleSwitcher ?
                    <BiChevronUp className="text-md font-semibold text-slate-500" /> :
                    <BiChevronDown className="text-md font-semibold text-slate-500" />}
            </div>
            {toggleSwitcher &&
                <div className="absolute top-full right-0 bg-white w-full z-40
                flex flex-col rounded-lg translate-y-[5px] border border-slate-300">
                    {langData.map((item, index) => {
                        return (
                            <div className="flex gap-2 items-center bg-white hover:bg-slate-200 cursor-pointer
                            rounded-lg p-2"
                                key={index}
                                onClick={() => {
                                    handleCurrentLang(item);
                                    setToggleSitcher(false);
                                }} >
                                <p className="text-sm font-semibold uppercase text-slate-500">{item.name}</p>
                            </div>
                        )
                    })}
                </div>
            }
        </div >
    )
}

export default LanguageSwitcher