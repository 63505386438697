import { useEffect, useState } from "react"

const TimeInput = ({ inputSetter = null, inputValue, addStyle, name, inputName }) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (inputValue)
            setValue(inputValue)
    }, [inputValue])

    const handleSetter = (e) => {
        setValue(e.target.value)
        if (inputSetter) {
            inputSetter(e.target.value)
        }
    }

    return (
        <div className="w-full">
            {name !== null &&
                <p className="text-slate-600 text-sm mb-3">
                    {name}
                </p>
            }
            <input
                onChange={(e) => { handleSetter(e) }}
                value={value}
                type="time"
                name={inputName}
                className={`rounded-lg w-full py-2 cursor-pointer
      px-4 h-10 bg-white flex items-center justify-between gap-2
      border-gray-300 border outline-none text-slate-500
      ${addStyle && addStyle} cursor-pointer
      `} />
        </div>
    )
}

export default TimeInput