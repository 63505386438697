import { useState, useEffect } from "react";

import protectedAxios from "../../api/protectedAxios";
import { successToast, errorToast } from "../../components/toast/ToastAlerts";
import getAllRecords from "../../Services/getAll";

import { Line } from "../../components/inputs/Line";
import SearchInput from "../../components/inputs/SearchInput";
import SelectInput from "../../components/inputs/SelectInput";
import PaginatedItems from "../../components/pagination/Pagination";
import ProductsCard from "../../components/dataCards/ProductsCard";

import { BiLoaderAlt } from "react-icons/bi";
import getListForSelection from "../../Services/getListForSelection";

const Products = () => {
  //product data
  const [productData, setProductData] = useState([]);
  //if data is loading
  const [isLoading, setIsLoading] = useState(false);
  //total data number on this page
  const [total, setTotal] = useState(0);
  //current page
  const [page, setPage] = useState(0);
  //search value
  const [searchValue, setSearchValue] = useState("");
  //filter value - category_id
  const [categoryId, setCategoryId] = useState({
    category_id: "",
  });
  //category data
  const [categoryData, setCategoryData] = useState([]);
  //update
  const [update, setUpdate] = useState(null);

  //handle category id
  const handleCategoryId = (value, inputName) => {
    setCategoryId({ [inputName]: value });
  };

  //get all category function
  const handleGetCategory = () => {
    getAllRecords({
      url: "/admin/product",
      page: page,
      otherParams: [
        {
          key: "key",
          value: searchValue,
        },
        {
          key: "category_id",
          value: categoryId.category_id,
        },
      ],
      handleLoading: setIsLoading,
      handleTotal: setTotal,
      handleData: setProductData,
    });
  };

  //clone product
  const handleCloneSingle = (id) => {
    protectedAxios
      .get(`/products/clone/${id}`)
      .then(({ res }) => {
        successToast("მონაცემი წარმატებით გამრავლდა");
        handleGetCategory();
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა");
      });
  };

  //get data from api
  useEffect(() => {
    handleGetCategory();
  }, [page, searchValue, categoryId, update]);

  //change category data  and save it in state
  useEffect(() => {
    getListForSelection({
      url: "/admin/category",
      stateUpdater: setCategoryData,
      keyForId: "id",
      keyForTitle: "name",
    });
  }, []);

  return (
    <div className="min-h-screen w-full">
      <div className="w-full p-5">
        <div className="bg-white shadow-my rounded-2xl w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-5 p-5 pb-0 max-sm:flex-col max-sm:gap-2">
              <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                პროდუქტები
              </h2>
              <div className="gap-2 w-full grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1">
                <div className="w-full">
                  <SearchInput
                    placeholder={"კატეგორიის სახელი"}
                    inputSetter={setSearchValue}
                  />
                </div>
                <div className="w-full">
                  <SelectInput
                    name={"კატეგორიის არჩევა"}
                    options={categoryData}
                    inputSetter={handleCategoryId}
                    inputName={"category_id"}
                  />
                </div>
                <div className="w-full">
                  <SelectInput
                    name={"მარაგი"}
                    options={[
                      {
                        id: 1,
                        title: "მარაგშია",
                      },
                      {
                        id: 2,
                        title: "არაა მარაგში",
                      },
                      {
                        id: 3,
                        title: "შეკვეთით",
                      },
                    ]}
                    inputName={""}
                  />
                </div>
                <div className="w-full">
                  <SelectInput
                    name={"ტიპი"}
                    options={[
                      {
                        id: 1,
                        title: "გამოქვეყნებული",
                      },
                      {
                        id: 2,
                        title: "წაშლილი",
                      },
                      {
                        id: 3,
                        title: "დრაფტი",
                      },
                    ]}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000" />
              </div>
            ) : isLoading === false && productData.length === 0 ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <p className="text-slate-600 text-sm">მონაცემები არ მოიძებნა</p>
              </div>
            ) : (
              <>
                <div className="max-sm:overflow-auto scrollbar">
                  <div className="max-sm:w-[1000px]">
                    <div className="grid grid-cols-7 p-5 border-b border-slate-200">
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">სათაური</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">კოდი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">მარაგი</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">კატეგორია</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">BMstore</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <p className="text-slate-600 text-sm">სტატუსი</p>
                      </div>
                      <div className="flex gap-2 items-center justify-end">
                        <p className="text-slate-600 text-sm">მოქმედება</p>
                      </div>
                    </div>
                    {productData.map((user) => {
                      return (
                        <div key={user.id}>
                          <Line className="bg-gray-400_87 w-full h-[1px]" />
                          <ProductsCard
                            data={user}
                            updateFunction={setUpdate}
                            handleCloneSingle={handleCloneSingle}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Line className="bg-gray-400_87 w-full h-[1px]" />
                <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2">
                  <PaginatedItems
                    dataLength={total}
                    itemsPerPage={8}
                    pageNumberSetter={setPage}
                    forcedPage={page}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
