import { BsCart2 } from "react-icons/bs"
import { FaCoins } from "react-icons/fa";

const MostSoldProductsCard = ({
    name = "პროდუქტი",
    price = "10",
    amount = "17"
}) => {
    return (
        <div className="flex items-center p-2 rounded-lg w-full shadow-my justify-between">
            <div className="flex items-center gap-4">
                <div className="bg-slate-500 h-10 w-10 rounded-lg"></div>
                <p
                    className="text-slate-600 text-sm"
                >
                    {name}
                </p>
            </div>
            <div className="flex gap-[5px] items-center ">
                <div className="flex flex-row gap-4 items-center justify-between w-full">
                    <div className="flex flex-row gap-2 items-center justify-start">
                        <div>
                            <FaCoins className="text-sm text-slate-500" />
                        </div>
                        <p
                            className="text-slate-500 text-[10px] "
                        >
                            {price}
                        </p>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start">
                        <div>
                            <BsCart2 className="text-sm text-slate-500" />
                        </div>
                        <p
                            className="text-slate-500 text-[10px]"
                        >
                            {amount}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MostSoldProductsCard