import { Link } from "react-router-dom"
import Button from "../../components/inputs/Button"
import { BsPlus } from "react-icons/bs"
import { BiMap, BiPencil } from "react-icons/bi"
import { useEffect, useState } from "react"
import protectedAxios from "../../api/protectedAxios"
import { successToast, errorToast } from "../../components/toast/ToastAlerts"
import FilialsCard from "../../components/dataCards/FilialsCard"

const LocationSettings = () => {
    //branches data
    const [branchesData, setBranchesData] = useState([]);

    //get branches from db
    const handleGetBranches = () => {
        protectedAxios.get(`/admin/branch`)
            .then(({ data }) => {
                setBranchesData(data.data)
            })
            .catch((err) => { })
    }

    //on component mount
    useEffect(() => {
        handleGetBranches()
    }, [])

    return (
        <div className="min-h-screen w-full p-5 ">
            <div className="flex items-center justify-between w-full max-sm:flex-col max-sm:gap-2">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                    ადგილმდებარეობა
                </h2>
                <Link to={"../add-location"} className="max-sm:w-full">
                    <Button
                        Icon={BsPlus}
                        style="bg-blue-1000 text-white text-sm font-bold rounded-full px-4 py-2 flex gap-2 items-center max-sm:w-full
                        flex items-center justify-center gap-2"
                        text={"ახლის დამატება"}
                    />
                </Link>
            </div>
            <p className="text-slate-400 text-sm mt-5">
                დაამატეთ სასურველი ადგილმდებარეობები და მართეთ ისინი
            </p>

            <div className="mt-10">
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between max-sm:flex-col max-sm:gap-3 max-sm:rounded-lg">
                    <div className='flex items-center gap-2 max-sm:w-full'>
                        <div>
                            <BiMap className='text-blue-1000 text-3xl' />
                        </div>
                        <div>
                            <p className="text-sm text-blue-1000">
                                ადგილმდებარეობა #1
                            </p>
                            <p className="text-xs text-blue-1000">
                                საქართველო
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 max-sm:w-full max-sm:justify-between">
                        <Button
                            style={"bg-blue-1000 text-white text-sm font-bold rounded-full px-4 py-2"}
                            text={"ძირითადი"}
                        />
                        <div>
                            <BiPencil className='text-blue-1000 text-2xl' />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LocationSettings