import protectedAxios from "../api/protectedAxios"

export default function getListForSelection({
    url,
    stateUpdater,
    keyForId,
    keyForTitle,
}) {
    protectedAxios.get(url)
        .then(({ data }) => {
            var tmp = data.data.map((item) => {
                return {
                    id: item[keyForId],
                    title: item[keyForTitle]
                }
            })
            if (stateUpdater) {
                stateUpdater(tmp)
            }
        })
        .catch((err) => { })
}
