export default function useGetOrderStatuses() {
  return [
    {
      id: "1",
      title: "გაფორმებული",
    },
    {
      id: "2",
      title: "დადასტურებული",
    },
    {
      id: "3",
      title: "გზაშია",
    },
    {
      id: "4",
      title: "მიტანილია",
    },
    {
      id: "5",
      title: "გაუქმებულია",
    },
  ];
}
