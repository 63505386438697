//library imports
import { useState, useEffect } from "react";
//components
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import MultipleFileInput from "../../components/inputs/MultipleFIleInput";
//functions
import addNewRecord from "../../Services/addNew";
//resources
import { BiPlus } from "react-icons/bi";
import getListForSelection from "../../Services/getListForSelection";

const AddCategory = ({ dataUpdateFunction }) => {
  //new data
  const [newData, setNewData] = useState({});
  //category data
  const [categoryData, setCategoryData] = useState([]);

  //add new data in state
  const handleAddNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  const updateCategoryRecords = (res) => {
    if (dataUpdateFunction) {
      dataUpdateFunction(res);
    }
  };

  //change category data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("type", newData.type);
    if (newData.parentCategoryId) {
      formData.append("parentCategoryId", newData.parentCategoryId);
    }

    addNewRecord({
      url: "/admin/category",
      payload: formData,
      updateFunction: updateCategoryRecords,
    });
  }

  //change category object and save it in new state
  useEffect(() => {
    getListForSelection({
      url: "/admin/category",
      stateUpdater: setCategoryData,
      keyForId: "id",
      keyForTitle: "name",
    });
  }, []);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      encType="multipart/form-data"
      className="w-full"
    >
      <div className="col-span-1 p-5 bg-white rounded-2xl shadow-my flex flex-col gap-3 max-lg:col-span-1">
        <h2 className="text-md text-slate-600">ახალი კატეგორიის დამატება</h2>
        <TextInput name={"კატეგორიის სახელი"} inputName={"name"} />
        <TextInput name={"SEO URL"} inputName={"link"} />
        <TextInput name={"SEO თეგები"} inputName={"ceo_keywords"} />
        <TextInput name={"SEO აღწერა"} inputName={"description"} />
        <p className="text-slate-500 text-sm">მიუთითეთ მშობელი კატეგორია</p>
        <SelectInput
          name={"მშობელი კატეგორია"}
          options={categoryData}
          inputSetter={handleAddNewData}
          inputName={"parentCategoryId"}
        />
        <MultipleFileInput
          addonText={"კატეგორიის სურათის"}
          inputName={"category_imgs"}
        />
        <p className="text-slate-500 text-sm">
          ატვირთეთ კატეგორიის სურათი (სურვილისამებრ)
        </p>
        <button
          type="submit"
          className="bg-blue-1000 cursor-pointer flex items-center justify-center p-5 rounded-2xl gap-2"
        >
          <div className="bg-white h-8 w-8 rounded-full p-2">
            <BiPlus className="text-blue-1000 w-full h-full" />
          </div>
          <p className="text-white text-sm">ახალი კატეგორიის დამატება</p>
        </button>
      </div>
    </form>
  );
};

export default AddCategory;
