import { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

const DropDown = ({
    title,
    text,
    id,
    openFunction,
    isActive,
}) => {
    //toggler
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    //toggler handler
    const handleIOpen = () => {
        setIsOpen((prev) => !prev);
        openFunction(id)
    };


    useEffect(() => {
        if (isOpen) {
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        } else {
            contentRef.current.style.maxHeight = "0";
        }
    }, [isOpen]);

    useEffect(() => {
        setIsOpen(isActive)
    }, [isActive])

    return (
        <div className="relative w-[100%] transition-all">
            <div
                className={` w-[100%] bg-white rounded-2xl shadow-my p-5`}
            >
                <div
                    onClick={handleIOpen}
                    className="flex flex-row gap-[24px] items-center justify-between w-[100%] cursor-pointer">
                    <div className="flex w-full">
                        <p
                            className="text-slate-600 text-left w-auto text-base"
                        >
                            {title}
                        </p>
                    </div>
                    <FaChevronDown className={`font-semibold text-blue-1000 transition-all ${isOpen && "rotate-180"}`} />
                </div>
                <div
                    ref={contentRef}
                    className={`transition-all overflow-hidden max-h-0`}
                >
                    <div className="flex md:flex-col flex-row gap-6 items-center justify-start w-full mt-5 
                     h-full">
                        <p
                            className="text-slate-500 text-left w-[100%] sm:w-full text-sm"
                        >
                            {text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropDown;
