import { useState } from "react";

const RadioButton = ({ name, uniqName, value, radioValue, inputSetter }) => {
    const [checked, setChecked] = useState(false);

    const handleChecking = () => {
        setChecked(prev => !prev)
    }

    const handleCheckingInput = (e) => {
        handleChecking()
        setChecked(e.target.checked)
        inputSetter(e.target.value, uniqName)
    }

    return (
        <div className="flex gap-2 items-center">
            <input
                type="radio"
                id={name}
                name={uniqName}
                value={radioValue}
                checked={value === radioValue}
                onChange={(e) => { handleCheckingInput(e) }}
                className="cursor-pointer"
            />
            {name !== null &&
                <label htmlFor={name} className={`text-sm ${checked ? "text-slate-600" : "text-gray-500"}`}>{name}</label>
            }
        </div>
    );
}

export default RadioButton