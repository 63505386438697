import { useState } from "react";
import Switch from "../../components/inputs/Switch";
import PluginDetails from "./PluginDetails";
import Button from "../../components/inputs/Button";

const PluginCard = ({ img, name, smallDesk, bigDesk, keyFeatures }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggleChange = () => {
    setToggle((prev) => !prev);
  };
  return (
    <div className="bg-white rounded-2xl border dark:bg-slate-700 dark:border-slate-600">
      <div className="flex justify-between items-center px-5 pt-5">
        <div className="flex gap-2 items-center max-sm:flex-col max-sm:items-start">
          <img className="w-14 h-14" src={img}></img>
          <p className="text-slate-600 text-sm">{name}</p>
        </div>
        <Switch />
      </div>
      <div className="mt-4 border-t px-5 py-2 flex justify-end gap-4">
        <Button
          style={
            "px-4 py-2 rounded-full text-sm text-white font-bold bg-blue-1000"
          }
          text={" დაწვრილებით"}
          onClickFunction={handleToggleChange}
        />
        <Button
          style={
            "px-4 py-2 rounded-full text-sm text-blue-1000 font-bold border border-blue-1000"
          }
          text={"განახლება"}
          onClickFunction={handleToggleChange}
        />
      </div>
      {toggle && (
        <PluginDetails
          handleToggleChange={handleToggleChange}
          IntegrationName={name}
          integrationImg={img}
          integrationBigDescription={bigDesk}
          keyFeatures={keyFeatures}
        />
      )}
    </div>
  );
};

export default PluginCard;
