import { Link } from "react-router-dom";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import deleteRecord from "../../Services/delete";
import { GoXCircle } from "react-icons/go";


const UsersCard = ({
  data,
  updateFunction,
  columnNumber,
  userTableColumns,
}) => {
  return (
    <div className={`grid grid-cols-${columnNumber} p-5`}>
      <div className="flex gap-2 items-center">
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">
            {data.fname} {data.lname}
          </p>
        </div>
      </div>
      {userTableColumns?.registration_date && (
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">12.23.1233</p>
        </div>
      )}
      {userTableColumns?.last_activity && (
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">12.23.1233</p>
        </div>
      )}
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-xs">{data.email}</p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-xs">{data.phone}</p>
      </div>
      {userTableColumns?.spent_money && (
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">3123</p>
        </div>
      )}
      {userTableColumns?.place && (
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">ქართლი - თბილისი</p>
        </div>
      )}
      {userTableColumns?.postal_code && (
        <div className="flex gap-2 items-center">
          <p className="text-slate-600 text-xs">3123</p>
        </div>
      )}
      <div className="flex gap-2 items-center justify-end">
        <Link to={`/users/${data.id}`}>
          <div className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer">
            <BsPencilSquare className="w-full h-full text-white" />
          </div>
        </Link>
        <div
          onClick={() => {
            deleteRecord({
              url: "/admin/user/",
              id: data.id,
              updateFunction: updateFunction,
            });
          }}
          className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer"
        >
          <BsFillTrash3Fill className="w-full h-full text-white" />
        </div>
        <div
          className="w-8 h-8 bg-slate-500 rounded-lg p-2 cursor-pointer"
        >
          <GoXCircle className="w-full h-full text-white" />
        </div>
      </div>
    </div>
  );
};

export default UsersCard;
