//library imports
import { useState, useEffect } from "react"
//components
import SelectInput from "../../components/inputs/SelectInput"
import TextInput from "../../components/inputs/TextInput"
//functions
import addNewRecord from "../../Services/addNew"
import getListForSelection from "../../Services/getListForSelection"
//resources
import { BiPlus } from "react-icons/bi"

const AddFilter = ({ dataUpdateFunction }) => {
  //new data
  const [newData, setNewData] = useState({});
  //category data
  const [categoryData, setCategoryData] = useState([])

  //add new data in state
  const handleAddNewData = (value, key) => {
    setNewData(prev => ({ ...prev, [key]: value }))
  }

  const updateFilterRecords = (res) => {
    if (dataUpdateFunction) {
      dataUpdateFunction(res);
    }
  }

  //change category data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    if (newData.categoryId) {
      formData.append("categoryId", newData.categoryId);
    }

    addNewRecord({
      url: "/admin/categoryFilters",
      payload: formData,
      updateFunction: updateFilterRecords
    })
  }

  //change category object and save it in new state
  useEffect(() => {
    getListForSelection({
      url: "/admin/category",
      stateUpdater: setCategoryData,
      keyForId: "id",
      keyForTitle: "name"
    })
  }, [])

  return (
    <form
      onSubmit={(e) => { handleSubmit(e) }}
      encType="multipart/form-data"
      className="w-full"
    >
      <div className="col-span-1 p-5 bg-white rounded-2xl shadow-my flex flex-col gap-3 max-lg:col-span-1">
        <h2 className="text-md text-slate-600">
          კატეგორიის ფილტრის დამატება
        </h2>
        <TextInput
          name={"ფილტრის სახელი"}
          inputName={"name"}
        />
        <TextInput
          name={"ფილტრის ტიპი"}
          inputName={"type"}
        />
        <p className="text-slate-500 text-sm">
          კატეგორიაზე ფილტრის მიმაგრება
        </p>
        <SelectInput
          name={"კატეგორიები"}
          options={categoryData}
          inputSetter={handleAddNewData}
          inputName={"categoryId"}
        />
        <TextInput
          name={"ფილტრის ველები"}
          inputName={"filter_fields"}
        />
        <button
          type="submit"
          className="bg-blue-1000 cursor-pointer flex items-center justify-center p-5 rounded-2xl gap-2">
          <div className="bg-white h-8 w-8 rounded-full p-2">
            <BiPlus className="text-blue-1000 w-full h-full" />
          </div>
          <p className="text-white text-sm">
            ახალი ფილტრის დამატება
          </p>
        </button>
      </div>
    </form>
  )
}

export default AddFilter