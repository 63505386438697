//library imports
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//components
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/inputs/Button";
import SelectWithListBelow from "../../components/inputs/SelectWithListBelow";
//functions
import getSingleRecord from "../../Services/getSingle";
import updateSingleRecord from "../../Services/updateSingle";
import getListForSelection from "../../Services/getListForSelection";

//resources
import { BsX } from "react-icons/bs";
import FileInput from "../../components/inputs/FileInput";

const EditBranch = () => {
  //get single branch id from params
  const { branchId } = useParams();
  //new branch data
  const [newBranch, setNewBranch] = useState({
    admins: [],
  });
  //admin data
  const [adminsData, setAdminsData] = useState([]);
  //clear fields
  const [clearFields, setClearFields] = useState(false);

  //handle new branch
  const handleNewBranch = (value, key) => {
    setNewBranch((prev) => ({ ...prev, [key]: value }));
  };

  //get all admins
  const handleGetAllAdmins = () => {
    getListForSelection({
      url: "/admin/admins",
      stateUpdater: setAdminsData,
      keyForId: "id",
      keyForTitle: "name",
    });
  };

  //clear all fields
  const handleClearFields = () => {
    setClearFields(true);
    handleGetAllAdmins();
    setNewBranch({
      admins: [],
    });
  };

  //update new branch in db
  const handleUpdateBranch = () => {
    const { productBranches, admins, region, ...rest } = newBranch;

    updateSingleRecord({
      url: "/admin/branch/",
      id: newBranch.id,
      payload: rest,
    });
  };

  //get single branch data from db
  useEffect(() => {
    getSingleRecord({
      url: "/admin/branch/",
      id: branchId,
      stateUpdater: setNewBranch,
    });
  }, []);

  //on component mount effect
  useEffect(() => {
    handleGetAllAdmins();
  }, []);

  return (
    <div className="min-h-screen w-full">
      <div className="w-full p-5">
        <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
          ფილიალების რედაქტირება
        </h2>
        <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
          <div className="w-full col-span-2 max-lg:col-span-1 ">
            <div className="flex flex-col gap-3 shadow-my p-5 rounded-2xl">
              <TextInput
                name={"სახელი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"name"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.name}
              />
              <TextInput
                name={"ქვეყანა"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_country"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.country}
              />
              <TextInput
                name={"რაიონი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"region"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.region}
              />
              <TextInput
                name={"ქალაქი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"city"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.city}
              />
              <TextInput
                name={"მისამართი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"address"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.address}
              />
              <TextInput
                name={"ბრაუზერის URL"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"link"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.link}
              />
              <TextInput
                name={"საფოსტო ინდექსი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_zip"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.branch_zip}
              />
              <TextInput
                name={"შენობის ნომერი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_b_number"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.branch_b_number}
              />
              <TextInput
                name={"ტელეფონის ნომერი"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_phone"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.branch_phone}
              />
              <TextInput
                name={"ტელეფონის ნომერი 2"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_phone"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.branch_phone}
              />
              <TextInput
                name={"ელ-ფოსტა"}
                type={"text"}
                inputSetter={handleNewBranch}
                inputName={"branch_email"}
                clearFields={clearFields}
                setClearFields={setClearFields}
                value={newBranch.branch_email}
              />
              <SelectWithListBelow
                text={"თანამშრომლები"}
                nameOnInput={"თანამშრომლები"}
                inputName={"admins"}
                passedOptions={adminsData}
                clearFields={clearFields}
                setClearFields={setClearFields}
                retrieveData={setNewBranch}
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
            <div className="shadow-my p-5 rounded-2xl">
              <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800 mb-5">
                ფილიალის ფოტო
              </h2>
              <div className="flex items-center gap-4">
                <div className="w-16 h-16 rounded-full bg-gray-400 shrink-0"></div>
                <FileInput />
              </div>
              <div className="w-full grid gap-2 mt-5 ">
                <Button
                  onClickFunction={handleClearFields}
                  style={
                    "text-blue-1000 rounded-lg py-2 px-4 w-full border border-blue-1000"
                  }
                  text={"გასუფთავება"}
                />

                <Button
                  onClickFunction={handleUpdateBranch}
                  style={
                    "bg-blue-1000 text-white rounded-lg py-2 px-4 w-full mt-5"
                  }
                  text={"შენახვა"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBranch;
