import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { BiCategory, BiChevronDown, BiSupport } from "react-icons/bi";
import {
  BsList,
  BsPersonFillGear,
  BsBoxSeam,
  BsFillBuildingFill,
  BsCartPlus,
  BsCartCheck,
  BsGear,
  BsFilter,
} from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";

import logo from "../../img/bmlogo.png";

const HeaderSideBar = ({ toggleSidebar, setToggleSideBar }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);
  const [subMenuOpenId, setSubMenuOpenId] = useState(null);
  const [menuData, setMenuData] = useState([
    {
      id: 1,
      link: "home",
      Icon: BsPersonFillGear,
      name: "სამართავი პანელი",
      active: true,
    },
    {
      id: 2,
      link: "orders",
      Icon: BsBoxSeam,
      name: "შეკვეთები",
      active: true,
    },
    {
      id: 3,
      link: "blank",
      Icon: FaRegUser,
      name: "მომხმარებლები",
      active: true,
      subOptions: [
        {
          id: 1,
          link: "users",
          active: true,
          name: "მომხმარებლები",
        },
        {
          id: 2,
          link: "admins",
          active: true,
          name: "ადმინისტრატორები",
        },
      ],
    },
    {
      id: 4,
      link: "blank",
      Icon: BsCartPlus,
      name: "ქვე მენიუ",
      active: true,
      subOptions: [
        {
          id: 1,
          link: "products",
          active: true,
          name: "პროდუქტები",
        },
        {
          id: 2,
          link: "add-products",
          active: true,
          name: "პროდუქტეის დამატება",
        },
        {
          id: 3,
          link: "category",
          active: true,
          name: "კატეგორიები",
        },
        {
          id: 4,
          link: "filter",
          active: true,
          name: "ფილტრი",
        },
      ],
    },
    {
      id: 5,
      link: "filial",
      Icon: BsFillBuildingFill,
      name: "ფილიალები",
      active: true,
    },
    {
      id: 6,
      link: "support-center",
      Icon: BiSupport,
      name: "დახმარების ცენტრი",
      active: true,
    },
    {
      id: 7,
      link: "options",
      Icon: BsGear,
      name: "პარამეტრები",
      active: true,
    },
  ]);

  useEffect(() => {
    setActive(toggleSidebar);
  }, [toggleSidebar]);

  useEffect(() => {
    setMenuData((prev) =>
      prev.map((item) => {
        if (item.link === pathname.split("/")[1]) {
          return {
            ...item,
            active: true,
          };
        }

        if (item.link === "blank") {
          item.subOptions = item.subOptions.map((subItem) => {
            if (subItem.link === pathname.split("/")[1]) {
              return {
                ...subItem,
                active: true,
              };
            }
            return {
              ...subItem,
              active: false,
            };
          });
        }
        return {
          ...item,
          active: false,
        };
      })
    );
  }, [pathname]);

  return (
    <div
      className={`fixed top-0 left-0 h-screen overflow-y-auto overflow-x-hidden p-5 transition-all bg-white no-scrollbar
        ${
          active
            ? "w-[300px] max-sm:translate-x-[0]"
            : "w-[94px] max-sm:translate-x-[-100%]"
        } z-50 shadow-my`}
    >
      <div
        className={`flex gap-2 items-center ${
          active ? "justify-between" : "justify-center"
        }`}
      >
        {active && <img src={logo} alt="#" className="h-10" />}
        <div onClick={() => setToggleSideBar((prev) => !prev)}>
          <BsList className="text-2xl text-gray-600 cursor-pointer" />
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-1">
        {menuData.map((item) => {
          return (
            <div key={item.id}>
              {item.link === "blank" ? (
                <div>
                  <div
                    onClick={() =>
                      setSubMenuOpenId((prev) =>
                        item.id === prev ? null : item.id
                      )
                    }
                    className={`flex items-center justify-between gap-2 rounded-xl group cursor-pointer p-4
                            ${
                              item.active
                                ? "bg-blue-1000"
                                : "hover:bg-blue-1000 delay-150"
                            }`}
                  >
                    <div className="flex items-center gap-2">
                      <div>
                        <item.Icon
                          className={`text-base cursor-pointer 
                                    ${
                                      item.active
                                        ? "text-white"
                                        : "text-slate-600 group-hover:text-white delay-150"
                                    }`}
                        />
                      </div>
                      <h2
                        className={`text-sm overflow-hidden 
                                    ${
                                      active
                                        ? "max-w-auto opacity-100 delay-150"
                                        : "max-w-0 opacity-0 h-0"
                                    } 
                                    ${
                                      item.active
                                        ? "text-white"
                                        : "text-slate-600 group-hover:text-white"
                                    }`}
                      >
                        {item.name}
                      </h2>
                    </div>

                    <BiChevronDown
                      className={`text-base cursor-pointer transition-all text-slate-600 group-hover:text-white delay-150
                                    ${
                                      subMenuOpenId === item.id && "rotate-180"
                                    }`}
                    />
                  </div>
                  {item.subOptions && item.id === subMenuOpenId && (
                    <div className="ml-3">
                      {item.subOptions.map((subItem) => (
                        <Link to={`/${subItem.link}`} key={subItem.id}>
                          <div
                            className={`flex items-center gap-2 rounded-xl group cursor-pointer p-4
                ${
                  subItem.active
                    ? "bg-blue-1000"
                    : "hover:bg-blue-1000 delay-150"
                }`}
                          >
                            <h2
                              className={`text-sm overflow-hidden 
                        ${
                          active
                            ? "max-w-auto opacity-100 delay-150"
                            : "max-w-0 opacity-0 h-0"
                        } 
                        ${
                          subItem.active
                            ? "text-white"
                            : "text-slate-600 group-hover:text-white"
                        }`}
                            >
                              {subItem.name}
                            </h2>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link to={`/${item.link}`}>
                  <div
                    className={`flex items-center gap-2 rounded-xl group cursor-pointer p-4
                            ${
                              item.active
                                ? "bg-blue-1000"
                                : "hover:bg-blue-1000 delay-150"
                            }`}
                  >
                    <div>
                      <item.Icon
                        className={`text-base cursor-pointer 
                                    ${
                                      item.active
                                        ? "text-white"
                                        : "text-slate-600 group-hover:text-white delay-150"
                                    }`}
                      />
                    </div>
                    <h2
                      className={`text-sm overflow-hidden 
                                    ${
                                      active
                                        ? "max-w-auto opacity-100 delay-150"
                                        : "max-w-0 opacity-0 h-0"
                                    } 
                                    ${
                                      item.active
                                        ? "text-white"
                                        : "text-slate-600 group-hover:text-white"
                                    }`}
                    >
                      {item.name}
                    </h2>
                  </div>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderSideBar;
