//library imports
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
//components
import HeaderSideBar from "./HeaderSideBar";
import ActivityPopup from "../popups/ActivityPopup";
import { Line } from "../inputs/Line";
import { PrimaryContext } from "../MainContext/MainContext";
//functions
import { errorToast, successToast } from "../toast/ToastAlerts";
import protectedAxios from "../../api/protectedAxios";
//resources
import { BsDoorOpen, BsFillBellFill, BsFillGearFill, BsList } from "react-icons/bs";

const Header = ({ toggleSidebar, setToggleSideBar }) => {
    //navigation
    const navigation = useNavigate();
    //activity toggler
    const [activityToggle, setActivityToggle] = useState(false);
    //data and functions from context 
    const { setUser, setToken } = useContext(PrimaryContext)

    //logout function
    const handleLogout = async () => {
        protectedAxios.get("/adminAuth/logOut")
            .then(() => {
                setUser(null)
                setToken()
                successToast("დრობეით")

                setTimeout(() => {
                    navigation("/");
                }, 2000);
            })
            .catch(() => {
                errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა")
            });
    }

    return (
        <div>
            <div className="w-full  p-5 flex items-center gap-5 justify-end max-sm:justify-between shadow-my relative z-40">
                <div onClick={() => setToggleSideBar(prev => !prev)}>
                    <BsList className="text-4xl text-slate-600 cursor-pointer sm:hidden" />
                </div>
                <div className="relative">
                    <div className="relative" onClick={() => { setActivityToggle(prev => !prev) }}>
                        <div >
                            <BsFillBellFill className="text-xl cursor-pinter text-slate-600 cursor-pointer" />
                        </div>
                        <div className="absolute top-[-50%] right-[-50%] rounded-full w-5 h-5 bg-light_blue-300 border-2 border-white
                    flex items-center justify-center">
                            <p className="text-white font-bold text-[10px]">12</p>
                        </div>
                    </div>
                    {activityToggle &&
                        <ActivityPopup />
                    }
                </div>
                <div>
                    <BsFillGearFill className="text-xl cursor-pinter text-slate-600 cursor-pointer" />
                </div>
                <div>
                    <BsDoorOpen
                        className="text-xl cursor-pinter text-slate-600 cursor-pointer"
                        onClick={handleLogout}
                    />
                </div>
                <Line className="bg-gray-400_87 h-8 w-[1px] max-sm:hidden" />
                <div className="flex gap-2 max-sm:hidden">
                    <div className="rounded-lg bg-slate-600 w-10 h-10">

                    </div>
                    <div className="flex flex-col">
                        <p className="text-base text-slate-600 font-bold ">გიორგი</p>
                        <p className="text-sm text-slate-500 ">სუპერ ადმინი</p>
                    </div>
                </div>
            </div>
            <HeaderSideBar
                toggleSidebar={toggleSidebar}
                setToggleSideBar={setToggleSideBar}
            />
        </div>
    )
}

export default Header