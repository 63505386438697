import { LineChart, Line, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'data1',
        uv: 4000,
    },
    {
        name: 'data2',
        uv: 3000,
    },
    {
        name: 'data3',
        uv: 2000,
    },
    {
        name: 'data4',
        uv: 2780,
    },
    {
        name: 'data5',
        uv: 1890,
    },
    {
        name: 'data6',
        uv: 2390,
    },
    {
        name: 'data7',
        uv: 3490,
    },
];

const LineChartt = ({ lineColor }) => {
    return (
        <ResponsiveContainer width="100%" aspect={2}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <Tooltip />
                <Line type="monotone" dataKey="uv" strokeWidth={3} stroke={lineColor} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default LineChartt