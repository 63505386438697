//library imports
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
//components
import { Line } from "../../components/inputs/Line"
import SearchInput from "../../components/inputs/SearchInput"
import AdminsCard from "../../components/dataCards/AdminsCard"
import PaginatedItems from "../../components/pagination/Pagination"
import SelectInput from "../../components/inputs/SelectInput"
//functions
import getAllRecords from "../../Services/getAll"
//resources
import { BiLoaderAlt, BiPlus } from "react-icons/bi"

const Admins = () => {
    //new admin data
    const [adminsData, setAdminsData] = useState([])
    //update user data
    const [updateAdminData, setUpdateAdminData] = useState(null)
    //if data is loading
    const [isLoading, setIsLoading] = useState(false)
    //admin roles
    const [adminRoles, setAdminRoles] = useState([
        {
            id: 1,
            title: "სუპერ ადმინი"
        },
        {
            id: 2,
            title: "მაღაზიის მმართველი"
        },
        {
            id: 3,
            title: "კონტენტის მმართველი"
        },
        {
            id: 4,
            title: "შეკვეთების მმართველი"
        },
        {
            id: 5,
            title: "კურიერი"
        },
    ])
    //selected role
    const [selectedRole, setSelectedRole] = useState("")
    //total data number on this page
    const [total, setTotal] = useState(0)
    //current page
    const [page, setPage] = useState(0)
    //search value
    const [searchValue, setSearchValue] = useState("")

    //get all admins function
    const handleGetAdmins = () => {
        getAllRecords({
            url: "/admin/admins",
            page: page,
            otherParams: [
                {
                    key: "key",
                    value: searchValue,
                },
                {
                    key: "role",
                    value: selectedRole,
                },
            ],
            handleLoading: setIsLoading,
            handleTotal: setTotal,
            handleData: setAdminsData,
        })
    }

    //get data from api
    useEffect(() => {
        handleGetAdmins()
    }, [page, searchValue, selectedRole, updateAdminData])

    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                ადმინისტრატორები
            </h2>
            <div className="w-full mt-10">
                <div className="w-full grid grid-cols-3  max-lg:grid-cols-2 max-md:grid-cols-1 gap-5">
                    <Link to={"/add-admins"} className="w-full">
                        <div className="p-5 bg-white rounded-2xl shadow-my flex items-center justify-center gap-3 cursor-pointer w-full h-full">
                            <div className="bg-blue-1000 w-12 h-12 p-2 rounded-full">
                                <BiPlus className="w-full h-full text-white" />
                            </div>
                            <p className="text-md text-slate-600 w-9/12">
                                ახალი ადმინისტრატორის დამატება
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="w-full mt-5">
                    <div className="bg-white shadow-my rounded-2xl w-full">

                        <div className="flex flex-col w-full">
                            <div className="flex items-center justify-between p-5 pb-0 max-sm:flex-col max-sm:gap-2">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                                    ადმინისტრატორები
                                </h2>
                                <div className="flex gap-2 items-center shrink-0 max-sm:flex-col max-sm:w-full">
                                    <SearchInput
                                        placeholder={"სახელი + გვარი , ელ-ფოსტა , იდენთიფიკატორი"}
                                        inputSetter={setSearchValue}
                                    />
                                    < SelectInput
                                        name={"როლი"}
                                        options={adminRoles}
                                        inputSetter={setSelectedRole}
                                        getWithTiTitle={true}
                                    />
                                </div>
                            </div>
                            {isLoading ?
                                <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                    <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000" />
                                </div> :
                                (isLoading === false && adminsData.length === 0) ?
                                    <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                                        <p className="text-slate-600 text-sm">
                                            მონაცემები არ მოიძებნა
                                        </p>
                                    </div>
                                    : <>
                                        <div className="max-sm:overflow-auto scrollbar">
                                            <div className="max-sm:w-[1000px]">
                                                <div className="grid grid-cols-4 p-5">
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            ადმინისტრატორი
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            ელექტრონული ფოსტა
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-slate-600 text-sm">
                                                            როლი
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center justify-end">
                                                        <p className="text-slate-600 text-sm">
                                                            მოქმედება
                                                        </p>
                                                    </div>
                                                </div>
                                                {adminsData.map((admin) => {
                                                    return (
                                                        <div key={admin.id}>
                                                            <Line className="bg-gray-400_87 w-full h-[1px]" />
                                                            <AdminsCard
                                                                data={admin}
                                                                updateFunction={setUpdateAdminData}
                                                            />
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                        <Line className="bg-gray-400_87 w-full h-[1px]" />
                                        <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2">
                                            <PaginatedItems
                                                dataLength={total}
                                                itemsPerPage={8}
                                                pageNumberSetter={setPage}
                                                forcedPage={page}
                                            />
                                        </div>
                                    </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default Admins