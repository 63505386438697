const CircularProgress = ({ progress }) => {
    const circumference = 2 * Math.PI * 120;
    const offset = circumference - (progress / 100) * circumference;

    return (
        <div className="flex items-center justify-center">
            <svg className="transform-rotate-90 w-72 h-72">
                <circle
                    cx="145"
                    cy="145"
                    r="120"
                    stroke="currentColor"
                    strokeWidth="45"
                    fill="transparent"
                    className="text-gray-100"
                />
                <circle
                    cx="145"
                    cy="145"
                    r="120"
                    stroke="currentColor"
                    strokeWidth="45"
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    className="text-[#010125]"
                />
            </svg>
            <div className="absolute">
                <p
                    className="sm:text-3xl md:text-[32px] text-[34px] text-blue-1000"
                >
                    {`${progress}%`}
                </p>
                <p
                    className="text-slate-500 text-sm"
                >
                    მიტანილი
                </p>
            </div>
        </div>
    );
};

export default CircularProgress;


