import { useEffect, useState } from "react"
import { BiSearch } from "react-icons/bi"

const SearchInput = ({
    placeholder = null,
    inputSetter = null
}) => {
    //search value
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (inputSetter !== null) {
            inputSetter(searchValue)
        }
    }, [searchValue])

    return (
        <div className="border border-gray-300 rounded-xl w-full h-10 px-2 py-2 bg-white flex gap-2 items-center">
            <div>
                <BiSearch className="text-xl cursor-pinter text-slate-600" />
            </div>
            <input
                className="border-none outline-none bg-white w-full text-slate-600 text-sm h-full"
                placeholder={placeholder !== null && placeholder}
                onChange={(e) => { setSearchValue(e.target.value) }}
            />
        </div>
    )
}

export default SearchInput