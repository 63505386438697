import { useState } from "react"

import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import FileInput from "../../components/inputs/FileInput"
import SelectInput from "../../components/inputs/SelectInput"
import Checkbox from "../../components/inputs/Checkbox"
import RadioButton from "../../components/inputs/RadioButton"
import TextEditor from "../../components/inputs/TextEditor"
import DateInput from "../../components/inputs/DateInput"

import { errorToast } from "../../components/toast/ToastAlerts"
import getSingleRecord from "../../Services/getSingle"
import updateSingleRecord from "../../Services/updateSingle"

import { TbArrowsRightLeft } from "react-icons/tb"
import TimeInput from "../../components/inputs/TimeInput"
import AddCategory from "../category/AddCategory"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import getListForSelection from "../../Services/getListForSelection"
import SelectWithListBelow from "../../components/inputs/SelectWithListBelow"

const EditProduct = () => {
    //product id
    const { productId } = useParams()
    //colors
    const [colors, setColors] = useState([
        {
            color: "#2E006A"
        },
        {
            color: "#177A00"
        },
        {
            color: "#A50028"
        },
        {
            color: "#C99F00"
        },
        {
            color: "#0079CF"
        },
        {
            color: "#533400"
        },
        {
            color: "#74006F"
        },
        {
            color: "#890000"
        },
        {
            color: "#5B323B"
        },
        {
            color: "#FCC800"
        },
    ]);
    //new data 
    const [newData, setNewData] = useState({
        category_ids: [],
        sizes: [],
        colors: [],
        connectedProductIds: [],
        code: ''
    })
    //admin data
    const [categoryData, setCategoryData] = useState([])
    // all products
    const [allProducts, setAllProducts] = useState([])

    //handle new data
    const handleSetNewData = (value, key) => {
        setNewData(prev => ({ ...prev, [key]: value }))
    }

    //adding check box style values 
    const handleAddCheckboxes = (value, key, checkedValue) => {
        if (value) {
            setNewData(prev => ({ ...prev, [key]: [...prev[key], checkedValue] }))
        } else {
            setNewData(prev => ({ ...prev, [key]: prev[key].filter(item => item !== checkedValue) }))
        }
    }

    //add new product
    function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        if (newData.weight_unit) {
            formData.append("weight_unit", newData.weight_unit);
        }
        if (newData.dimX && newData.dimY && newData.dimZ) {
            formData.append("dementions", JSON.stringify({
                x: newData.dimX,
                y: newData.dimY,
                Z: newData.dimZ,
            }))
        } else {
            errorToast("თუ გსურთ განზომილებების დამატება შეავსეთ ყველა ველი")
        }
        if (newData.product_status) {
            formData.append("product_status", newData.product_status);
        }
        if (newData.category_ids.length !== 0) {
            formData.append("category_ids", newData.category_ids);
        }
        if (newData.sizes.length !== 0) {
            formData.append("product_sizes", JSON.stringify(newData.sizes));
        }

        updateSingleRecord({
            url: "/admin/product/",
            id: productId,
            payload: formData
        })
    }

    const generateRandomCode = () => {
        const codeCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomCode = '';
        for (let i = 0; i < 10; i++) {
            randomCode += codeCharacters.charAt(Math.floor(Math.random() * codeCharacters.length));
        }
        setNewData(prev => ({ ...prev, code: randomCode }))
    };

    //get all products
    useEffect(() => {
        getListForSelection({
            url: "/admin/product",
            stateUpdater: setAllProducts,
            keyForId: "id",
            keyForTitle: "name"
        })
    }, [])

    useEffect(() => {
        getSingleRecord({
            url: "/admin/product/",
            id: productId,
            stateUpdater: setNewData
        })
        setNewData(prev => ({ ...prev, sizes: prev.product_sizes ? [...prev.product_sizes.split(", ")] : [] }))
    }, [productId])

    //change category object and save it in new state
    useEffect(() => {
        getListForSelection({
            url: "/admin/category",
            stateUpdater: setCategoryData,
            keyForId: "id",
            keyForTitle: "name"
        })
    }, [])

    return (
        <div className="min-h-screen w-full p-5">

            <div className="w-full flex justify-between items-center">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                    პროდუქტების დამატება
                </h2>
                <div className="flex gap-3 items-center">
                    <Button
                        type={"submit"}
                        style={"bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                        text={"გამოქვეყნება"}
                    />
                </div>
            </div>

            <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">

                <div className="w-full col-span-2 max-lg:col-span-1 ">
                    <form
                        onSubmit={(e) => { handleSubmit(e) }}
                        encType="multipart/form-data"
                        className="w-full"
                    >
                        <div className="w-full grid grid-cols-1 gap-5">
                            <TextInput
                                name={"პროდუქტის სახელი"}
                                placeholder={"პროდუქტის სახელი"}
                                inputName={"name"}
                                value={newData?.name}
                            />
                            <TextInput
                                name={"ლინკი"}
                                placeholder={"ლინკი"}
                                inputName={"link"}
                                value={newData?.link}
                            />
                            <TextInput
                                name={"ინდექსი"}
                                placeholder={"ინდექსი"}
                                inputName={"sort"}
                                value={newData?.sort}
                            />
                            <TextInput
                                name={"მოკლე აღწერა"}
                                placeholder={"მოკლე აღწერა"}
                                inputName={"short_description"}
                                value={newData?.short_description}
                            />
                            <TextEditor
                                title={"ადმინისტრატორის აღწერა"}
                                inputName={"admin_description"}
                                value={newData?.admin_description}
                            />
                            <TextEditor
                                title={"აღწერა"}
                                inputName={"description"}
                                value={newData?.description}
                            />
                            <div className="shadow-my p-5 rounded-2xl">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                    ფასი
                                </h2>
                                <div className="flex gap-2 w-full mt-5 flex-col">
                                    <div className="flex gap-2 w-full items-center max-md:flex-col">
                                        <TextInput
                                            name={"მიმდინარე ფასი"}
                                            placeholder={"მიუთთეთ ფასი"}
                                            inputName={"price"}
                                            type={"number"}
                                            value={newData.price}
                                        />
                                        <div className="max-md:hidden">
                                            < TbArrowsRightLeft className="text-slate-500" />
                                        </div>
                                        <TextInput
                                            name={"ფასდაკლების ფასი"}
                                            placeholder={"მიუთთეთ ფასი"}
                                            inputName={"discount_price"}
                                            type={"number"}
                                            value={newData.discount_price}
                                        />
                                    </div>
                                    <div className="flex justify-end items-end w-full gap-2">
                                        <DateInput
                                            name={"ფასდაკლების თარიღები დასაწყისი"}
                                            inputName={"discount_time"}
                                        // inputValue={newData.discount_time.split(" ")[0]}
                                        />
                                        <DateInput
                                            name={"ფასდაკლების თარიღები დასასრული"}
                                            inputName={"discount_time"}
                                        // inputValue={newData.discount_time.split(" ")[0]}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-2 w-full mt-5">
                                    <TextInput
                                        name={"სარგებელი"}
                                        placeholder={"- - -"}
                                        inputName={"profit"}
                                        value={newData.profit}
                                    />
                                    <TextInput
                                        name={"სხვაობა"}
                                        placeholder={"- - -"}
                                        inputName={"difference"}
                                        value={newData.difference}
                                    />
                                </div>
                            </div>

                            <div className="shadow-my p-5 rounded-2xl">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                    მიწოდების პარამეტრები
                                </h2>
                                <p className="text-slate-600 text-sm mt-5">
                                    პროდუქტის მიწოდების ტიპი
                                </p>
                                <div className="mt-5 flex gap-2 flex-wrap max-md:flex-col">
                                    <RadioButton
                                        name={"ფიზიკური"}
                                        uniqName={"delivery_type"}
                                        value={newData.delivery_type}
                                        inputSetter={handleSetNewData}
                                        radioValue={"1"}
                                    />
                                    <RadioButton
                                        name={"ციფრული"}
                                        uniqName={"delivery_type"}
                                        value={newData.delivery_type}
                                        inputSetter={handleSetNewData}
                                        radioValue={"2"}

                                    />
                                    <RadioButton
                                        name={"ჩამოტვირთვადი"}
                                        uniqName={"delivery_type"}
                                        value={newData.delivery_type}
                                        inputSetter={handleSetNewData}
                                        radioValue={"3"}
                                    />
                                </div>

                                {newData.delivery_type === "1" &&
                                    <>
                                        <div className="flex gap-2 w-6/12 mt-5 max-md:w-full max-md:flex-col">
                                            <TextInput
                                                name={"წონა"}
                                                type={"number"}
                                                inputName={"weight"}
                                                value={newData.weight}
                                            />
                                            <div className="flex flex-col justify-end items-end w-full">
                                                <SelectInput
                                                    name={"ერთეული"}
                                                    options={[
                                                        {
                                                            id: "1",
                                                            title: "კგ"
                                                        },
                                                        {
                                                            id: "2",
                                                            title: "გრამი"
                                                        },
                                                        {
                                                            id: "3",
                                                            title: "lbs"
                                                        },
                                                    ]}
                                                    inputSetter={handleSetNewData}
                                                    inputName={"weight_unit"}
                                                    value={newData.weight_unit}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-2 w-full mt-5">
                                            <TextInput
                                                name={"სიგრძე"}
                                                type={"number"}
                                                inputName={"dimX"}
                                                inputSetter={handleSetNewData}
                                            />
                                            <TextInput
                                                name={"სიგანე"}
                                                type={"number"}
                                                inputName={"dimY"}
                                                inputSetter={handleSetNewData}
                                            />
                                            <TextInput
                                                name={"სიმაღლე"}
                                                type={"number"}
                                                inputName={"dimZ"}
                                                inputSetter={handleSetNewData}
                                            />
                                        </div>
                                    </>
                                }
                                {newData.delivery_type === "3" &&
                                    <div className="mt-5">
                                        <TextInput
                                            name={"ჩამოტვირთვადი ლინკი"}
                                            placeholder={""}
                                            inputName={"discount_percent"}
                                        />
                                        <div className="mt-5">
                                            <FileInput
                                                addonText={""}
                                                inputName={""}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="shadow-my p-5 rounded-2xl">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                    მარაგი
                                </h2>
                                <div className="mt-5 flex gap-2 items-end">
                                    <TextInput
                                        name={"კოდი"}
                                        placeholder={"კოდი"}
                                        inputName={"code"}
                                        value={newData?.code}
                                    />
                                    <div
                                        className="rounded-xl px-4 py-2 bg-blue-1000 text-white shrink-0 cursor-pointer"
                                        onClick={generateRandomCode}
                                    >
                                        <p>კოდის გენერირება</p>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <TextInput
                                        name={"მარაგის რაოდენობა"}
                                        type={"number"}
                                        inputName={"stock_amount"}
                                        value={newData.stock_amount}
                                    />
                                </div>
                                <div className="mt-5">
                                    <Checkbox
                                        name={"გააგრძელეთ გაყიდვა მარაგის ამოწურვისას"}
                                        inputName={"show_when_out_of"}
                                        value={newData.show_when_out_of}
                                    />
                                </div>
                                <div className="flex gap-2 w-full mt-5">
                                    <p className="text-gray-800 text-sm">რაოდენობა</p>
                                </div>
                                <div className="mt-5 flex flex-col gap-2">
                                    <RadioButton
                                        name={"მარაგშია"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                    <RadioButton
                                        name={"არ არის მარაგში"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                    <RadioButton
                                        name={"შეკვეთით"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                </div>
                                <div className="flex gap-2 w-full mt-5">
                                    <p className="text-gray-800 text-sm">ნება დაერთოს წინასწარ შეკვეთებს?</p>
                                </div>
                                <div className="mt-5 flex flex-col gap-2">
                                    <RadioButton
                                        name={"არ დართო ნება"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                    <RadioButton
                                        name={"ნების დართვა მაგრამ მომხმარებლის გაფრთხილებით"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                    <RadioButton
                                        name={"ნების დართვა"}
                                        uniqName={"stock_status"}
                                        value={newData.stock_status}
                                    />
                                </div>
                                <div className="mt-5">
                                    <TextInput
                                        name={"მომხმარებელს შეუძლია შეიძინოს მაქსიმუმ"}
                                        type={"number"}
                                        inputName={"stock_amount"}
                                        value={newData.stock_amount}
                                    />
                                </div>
                            </div>

                            <div className="shadow-my p-5 rounded-2xl">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                    გამოქვეყნება სასურველი თარიღით
                                </h2>
                                <div className="mt-5 flex gap-2 flex-col">
                                    <DateInput
                                        name={"გამოქვეყნების თარიღი"}
                                        inputName={"publish_date"}
                                        inputValue={newData.publish_date}
                                    />
                                    <TimeInput
                                        name={"გამოქვეყნების დრო"}
                                        inputName={"publish_time"}
                                        inputValue={newData.publish_time}
                                    />
                                </div>
                                <div className="mt-5">
                                    <Checkbox
                                        name={"მარქეთფლეისი"}
                                        inputName={"on_marketplace"}
                                        value={newData.on_marketplace}
                                    />
                                </div>
                            </div>

                            <SelectInput
                                name={"სტატუსი"}
                                options={[
                                    {
                                        id: "1",
                                        title: "აქტიური"
                                    },
                                    {
                                        id: "2",
                                        title: "პასიური"
                                    },
                                ]}
                                inputSetter={handleSetNewData}
                                inputName={"product_status"}
                                value={newData.product_status}
                            />

                            <FileInput
                                addonText={"მთავარი ფოტოს"}
                                inputName={"uploadedImages"}
                            />

                            <div className="shadow-my p-5 rounded-2xl">
                                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                    პროდუქტის მიმაგრება
                                </h2>
                                <div className="mt-5 flex gap-2 flex-col">
                                    <SelectWithListBelow
                                        text={"კატეგორიაზე მიმაგრება"}
                                        nameOnInput={"კატეგორია"}
                                        inputName={"category_ids"}
                                        passedOptions={categoryData}
                                        retrieveData={setNewData}
                                    />
                                </div>
                                <div className="mt-5 flex gap-2 flex-col">
                                    <SelectWithListBelow
                                        text={"პროდუქტებზე მიმაგრება"}
                                        nameOnInput={"პროდუქტები"}
                                        inputName={"connectedProductIds"}
                                        passedOptions={allProducts}
                                        retrieveData={setNewData}
                                    />
                                </div>
                            </div>


                            <div className="grid grid-cols-2 gap-3 max-md:grid-cols-1">
                                <div className="shadow-my p-5 rounded-2xl">
                                    <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                        ზომები
                                    </h2>
                                    <div className="mt-5 gap-2 grid grid-cols-3">
                                        <Checkbox
                                            name={"XS"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                        <Checkbox
                                            name={"L"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                        <Checkbox
                                            name={"S"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                        <Checkbox
                                            name={"XL"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                        <Checkbox
                                            name={"M"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                        <Checkbox
                                            name={"XLL"}
                                            inputSetter={handleAddCheckboxes}
                                            inputName="sizes"
                                        />
                                    </div>
                                </div>
                                <div className="shadow-my p-5 rounded-2xl">
                                    <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                                        ფერები
                                    </h2>
                                    <div className="mt-5 gap-2 grid grid-cols-5 w-full">
                                        {colors.map((item, index) => {
                                            return (
                                                <div className="flex items-center justify-center py-2" key={index}>
                                                    <div className={`rounded-full h-6 w-6`} style={{ backgroundColor: `${item.color}` }}>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
                    <div className=" w-full flex gap-5 flex-col">
                        <AddCategory />
                    </div>
                </div>
            </div>

        </div >
    )
}

export default EditProduct