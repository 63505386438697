const Button = ({ style, text, Icon = null, onClickFunction = null, type }) => {
    return (
        <button
            type={type && type}
            className={style}
            onClick={() => { onClickFunction !== null && onClickFunction() }}
        >
            {Icon !== null &&
                <Icon />
            }
            {text}
        </button>
    )
}

export default Button