//library imports
import { useState } from "react";
import { Link } from "react-router-dom";
//components
import Checkbox from "../../components/inputs/Checkbox";
import Button from "../../components/inputs/Button";
import LanguageSwitcher from "../../components/langageSwitcher/LanguageSwitcher";
import TextInput from "../../components/inputs/TextInput";
//resources
import { BsEye, BsFacebook, BsGoogle } from "react-icons/bs";
import logo from "../../img/bmlogo.png"

const Signup = () => {

    //user credentials for registration
    const [credentials, setCredentials] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role: "1",
        name: function () {
            return this.first_name + " " + this.last_name
        }
    })

    //handle credentials 
    const handleCredentials = (value, key) => {
        setCredentials(prev => ({ ...prev, [key]: value }))
    }

    return (
        <div className="flex items-center justify-center">
            <div className="w-7/12 h-screen flex items-center justify-center bg-gray-100 max-lg:hidden ">
                <div>
                    <img src={logo} />
                </div>
            </div>
            <div className="w-5/12 h-screen max-lg:h-full bg-white gap-[30px] p-10 max-lg:w-8/12 max-md:w-10/12 max-sm:w-full max-sm:p-5 relative">
                <div className="flex gap-5 items-center justify-between absolute top-0 left-0 w-full p-10 max-lg:p-0 max-lg:relative">
                    <img src={logo} className="h-12" />
                    <LanguageSwitcher />
                </div>
                <div className="h-full flex flex-col justify-center items-center">
                    <div className="mt-4 w-full">
                        <p className="text-sm text-slate-500 mt-4">გაიარეთ რეგისტრაცია</p>
                    </div>
                    <div className="flex flex-col gap-5 mt-4 w-full">
                        <div className="grid grid-cols-2 gap-3 max-lg:grid-cols-1">
                            <TextInput
                                placeholder={"სახელი"}
                                inputSetter={handleCredentials}
                                inputName={"first_name"}
                            />
                            <TextInput
                                placeholder={"გვარი"}
                                inputSetter={handleCredentials}
                                inputName={"last_name"}
                            />
                            <TextInput
                                placeholder={"ელექტრონული ფოსტა"}
                                inputSetter={handleCredentials}
                                inputName={"email"}
                            />
                            <TextInput
                                placeholder={"კომპანიის სახელი"}
                                inputSetter={handleCredentials}
                            />
                            <TextInput
                                placeholder={"ნომერი"}
                                inputSetter={handleCredentials}
                            />
                            <TextInput
                                placeholder={"პაროლი"}
                                inputSetter={handleCredentials}
                                SuffixIcon={BsEye}
                                type={"password"}
                                inputName={"password"}
                            />
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                name={"დაიმახსოვრე"}
                            />
                        </div>
                        <div className="mt-4">
                            <Button
                                style={"rounded-xl bg-blue-1000 px-4 py-3 text-white font-bold w-full"}
                                text={"შესვლა"}
                            />
                            <div className="flex gap-2 items-center justify-center mt-4">
                                <p className="text-sm text-slate-500">გაქვს ანგარიში? </p>
                                <Link
                                    to={"../login"}
                                    className="text-blue-1000 text-sm">აუტორიზაცია </Link>
                            </div>
                            <div className="flex gap-2 items-center justify-center mt-4">
                                <div className="h-[1px] w-5/12 border border-gray-200"></div>
                                <p className="text-base text-slate-500">ან</p>
                                <div className="h-[1px] w-5/12 border border-gray-200"></div>
                            </div>
                            <div className="flex space-x-4 mt-4">
                                <button className="py-2 flex gap-2 items-center justify-center w-full rounded-xl border border-slate-300 font-medium text-slate-800 hover:bg-slate-100 focus:bg-slate-200 ">
                                    <div>
                                        <BsGoogle className="text-base text-blue-1000" />
                                    </div>
                                    <p className="text-blue-1000 text-sm">Google</p>
                                </button>
                                <button className="py-2  flex gap-2 items-center justify-center w-full rounded-xl border border-slate-300 font-medium text-slate-800 hover:bg-slate-100 focus:bg-slate-200">
                                    <div>
                                        <BsFacebook className="text-base" />
                                    </div>
                                    <p className="text-blue-1000 text-sm">Facebook</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Signup


