import { useState, useEffect } from "react";

import getAllWithoutPaginate from "../../Services/getAllWithoutPaginate";

import FilialsCard from "../../components/dataCards/FilialsCard";

import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";

const Filials = () => {
    //branches data
    const [branchesData, setBranchesData] = useState([]);

    //get branches from db
    const handleGetBranches = () => {
        getAllWithoutPaginate({
            url: "/admin/branch",
            stateUpdater: setBranchesData
        })
    }

    //on component mount
    useEffect(() => {
        handleGetBranches()
    }, [])

    return (
        <div className="min-h-screen w-full">
            <div className="w-full p-5">
                <div className="flex justify-between items-center w-full max-sm:flex-col max-sm:gap-2">
                    <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                        სამართავი პანელი
                    </h2>
                </div>
                <div className="w-full grid grid-cols-3  max-lg:grid-cols-2 max-md:grid-cols-1 gap-5 mt-10">
                    <Link to={"../add-filial"}>
                        <div className="p-5 bg-white rounded-2xl shadow-my flex items-center justify-center gap-3 cursor-pointer w-full h-full">
                            <div className="bg-blue-1000 w-12 h-12 p-2 rounded-full">
                                <BsPlus className="w-full h-full text-white" />
                            </div>
                            <p className="text-md text-slate-600 w-9/12">
                                ახალი ფილიალის დამატება
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="w-full grid grid-cols-4 mt-5 gap-5 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">

                    {branchesData &&
                        branchesData.map((item) => {
                            return (
                                <FilialsCard
                                    key={item.id}
                                    data={item}
                                    updateFunction={handleGetBranches}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div >
    )
}

export default Filials