const PluginDescription = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <p className="text-[#3c434a] text-[14px] indent-2">
        Contact Form 7 can manage multiple contact forms, plus you can customize
        the form and the mail contents flexibly with simple markup. The form
        supports Ajax-powered submitting, CAPTCHA, Akismet spam filtering and so
        on.
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">
        Docs and support
      </h2>
      <p className="text-[#3c434a] text-[14px] indent-2">
        Contact Form 7 can manage multiple contact forms, plus you can customize
        the form and the mail contents flexibly with simple markup. The form
        supports Ajax-powered submitting, CAPTCHA, Akismet spam filtering and so
        on.
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">
        Contact Form 7 needs your support
      </h2>
      <p className="text-[#3c434a] text-[14px] indent-2 line">
        It is hard to continue development and support for this free plugin
        without contributions from users like you. If you enjoy using Contact
        Form 7 and find it useful, please consider making a donation. Your
        donation will help encourage and support the plugin’s continued
        development and better user support.
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">
        Docs and support
      </h2>
      <p className="text-[#3c434a] text-[14px] indent-2">
        Contact Form 7 can manage multiple contact forms, plus you can customize
        the form and the mail contents flexibly with simple markup. The form
        supports Ajax-powered submitting, CAPTCHA, Akismet spam filtering and so
        on.
      </p>
      <h2 className="text-[14px] text-[#3c434a] font-semibold">
        Contact Form 7 needs your support
      </h2>
      <p className="text-[#3c434a] text-[14px] indent-2 line">
        It is hard to continue development and support for this free plugin
        without contributions from users like you. If you enjoy using Contact
        Form 7 and find it useful, please consider making a donation. Your
        donation will help encourage and support the plugin’s continued
        development and better user support.
      </p>
    </div>
  );
};

export default PluginDescription;
