import { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import img from "../../img/georgia.png";

const SelectInput = ({
  upperName,
  name = "",
  options,
  inputName,
  inputSetter = null,
  mFill,
  value,
  clearFields = "",
  setClearFields,
  getWithTiTitle = null,
  keyIsName = null,
  placeholder = "აირჩიე",
}) => {
  //toggle variable
  const [isOpen, setIsOpen] = useState(false);
  //current selected value
  const [selectValue, setSelectValue] = useState("");
  //selected value title
  const [selectValueTitle, setSelectValueTitle] = useState("");
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("below");

  //select handler
  const handleSelect = (index, title) => {
    setSelectValue(index);
    setSelectValueTitle(title);
    if (inputSetter) {
      if (getWithTiTitle) {
        inputSetter(title);
      } else {
        inputSetter(index, inputName);
      }
    }
  };

  //toggle handler
  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  //whenever filed is required and user does not
  //filled it
  useEffect(() => {
    if (selectValueTitle !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, selectValueTitle]);

  useEffect(() => {
    if (value !== undefined) {
      if (selectValue !== value && inputSetter) {
        setSelectValue(value);
        inputSetter(value, inputName);
      }
      options.forEach((element) => {
        if (parseInt(element.id) === parseInt(value)) {
          setSelectValueTitle(element.title);
        }
      });
    }
  }, [value, options]);

  // clear field if nesecity
  useEffect(() => {
    if (clearFields !== "" && clearFields) {
      setSelectValue("");
      setSelectValueTitle("");
      setClearFields(false);
    }
  }, [clearFields]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handlePosition = () => {
      if (dropdownRef.current) {
        const toggleRect = dropdownRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - toggleRect.bottom;
        const spaceAbove = toggleRect.top;

        if (spaceBelow < toggleRect.height) {
          setDropdownPosition("above");
        }

        if (spaceAbove < toggleRect.height) {
          setDropdownPosition("below");
        }
      }
    };

    if (isOpen) {
      handlePosition();
    }

    window.addEventListener("resize", handlePosition);
    window.addEventListener("scroll", handlePosition);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("resize", handlePosition);
      window.removeEventListener("scroll", handlePosition);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`w-full bg-white rounded-lg relative`}>
      {upperName && <p className="text-slate-600 text-sm mb-3">{upperName}</p>}
      <div
        onClick={() => {
          handleOpen();
        }}
        className="w-full rounded-xl focus:outline-slate-300 h-[42px] p-2
                 cursor-pointer border border-gray-300 flex items-center"
      >
        <div className="flex justify-between items-center w-full mx-2">
          <div className="flex items-center gap-2">
            <img src={img} alt="flag" width={30} />
            <p className="text-sm text-slate-500">
              {selectValueTitle !== ""
                ? selectValueTitle
                : name
                ? name
                : placeholder}
            </p>
          </div>

          <div>
            <BiChevronDown
              className={`text-lg text-blue-1000 transition-all ${
                isOpen && "rotate-180"
              }`}
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          ref={dropdownRef}
          className={`absolute left-0 bg-white border border-gray-300 rounded-[8px] w-full overflow-hidden z-50 
            ${dropdownPosition === "below" ? "top-[105%]" : "bottom-[105%]"}
            `}
        >
          {options.map((value) => {
            return (
              <div
                onClick={() => {
                  handleSelect(value.id, value.title);
                  handleOpen();
                }}
                key={keyIsName ? value.title : value.id}
                className="p-2 w-full hover:bg-slate-300 group cursor-pointer"
              >
                <p className="text-sm text-slate-500 group-hover:text-gray-800">
                  {value.title}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
