//library imports
import { useEffect, useState } from "react";
//components
import { Line } from "../../components/inputs/Line";
import SearchInput from "../../components/inputs/SearchInput";
import PaginatedItems from "../../components/pagination/Pagination";
import UsersCard from "../../components/dataCards/UsersCard";
import SubMenu from "../../components/subMenu/SubMenu";
import Switch from "../../components/inputs/Switch";
//functions
import getAllRecords from "../../Services/getAll";
//resources
import { BiLoaderAlt, BiUserPlus } from "react-icons/bi";
import SelectInput from "../../components/inputs/SelectInput";
import { BsFiletypeCsv, BsFiletypePdf, BsFiletypeXls } from "react-icons/bs";

const Users = () => {
  //users data
  const [usersData, setUsersData] = useState([]);
  //update user data
  const [updateUsersData, setUpdateUsersData] = useState(null);
  //if data is loading
  const [isLoading, setIsLoading] = useState(false);
  //total data number on this page
  const [total, setTotal] = useState(0);
  //current page
  const [page, setPage] = useState(0);
  //search value
  const [searchValue, setSearchValue] = useState("");
  // user table data
  const [userTableColumns, setUserTableColumns] = useState({
    registration_date: false,
    last_activity: false,
    spent_money: false,
    place: false,
    postal_code: false,
  });
  //column count
  const [columnNumber, setColumnNumber] = useState(4);

  const handleUserTableColumns = (value, key) => {
    setUserTableColumns((prev) => ({ ...prev, [key]: value }));
  };

  //get all users function
  const handleGetUsers = () => {
    getAllRecords({
      url: "/admin/user",
      page: page,
      otherParams: [
        {
          key: "key",
          value: searchValue,
        },
      ],
      handleLoading: setIsLoading,
      handleTotal: setTotal,
      handleData: setUsersData,
    });
  };

  //get data from api
  useEffect(() => {
    handleGetUsers();
  }, [page, searchValue, updateUsersData]);

  useEffect(() => {
    const list = Object.values(userTableColumns);
    let newCount = 0;

    list.forEach((value) => {
      if (value) {
        newCount++;
      }
    });

    setColumnNumber(4 + newCount);
  }, [userTableColumns]);

  return (
    <div className="min-h-screen w-full p-5">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        მომხმარებლები
      </h2>
      <div className="w-full mt-10">
        <div className="w-full grid grid-cols-3  max-lg:grid-cols-2 max-md:grid-cols-1 gap-5">
          <div className="p-5 bg-white rounded-2xl shadow-my flex items-center gap-3">
            <div className="bg-blue-1000 w-12 h-12 p-2 rounded-lg">
              <BiUserPlus className="w-full h-full text-white" />
            </div>
            <div>
              <p className="text-sm text-slate-600">მომხმარებლების რაოდენობა</p>
              <div className=" flex items-center">
                <p className="text-sm text-slate-600">2500</p>
              </div>
            </div>
          </div>
          <div className="p-5 bg-white rounded-2xl shadow-my flex items-center gap-3">
            <div className="bg-blue-1000 w-12 h-12 p-2 rounded-lg">
              <BiUserPlus className="w-full h-full text-white" />
            </div>
            <div>
              <p className="text-sm text-slate-600">
                ბოლო 30 დღეში დარეგისტრირებული
              </p>
              <div className=" flex items-center justify-between">
                <p className="text-sm text-slate-600">2500</p>
              </div>
            </div>
          </div>
          <div className="p-5 bg-white rounded-2xl shadow-my flex items-center gap-3">
            <div className="bg-blue-1000 w-12 h-12 p-2 rounded-lg">
              <BiUserPlus className="w-full h-full text-white" />
            </div>
            <div>
              <p className="text-sm text-slate-600">დაბლოკილი მომხმარებლები</p>
              <div className=" flex items-center justify-between">
                <p className="text-sm text-slate-600">2500</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5">
          <div className="bg-white shadow-my rounded-2xl w-full">
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between p-5 pb-0 max-sm:flex-col max-sm:gap-2">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full">
                  დარეგისტრირებული მომხმარებლები
                </h2>
                <div className="flex items-center gap-3">
                  <div className="shrink-0">
                    <SearchInput
                      placeholder={
                        "იდენთიფიკატორი, ტელეფონი, ელ-ფოსტა, სახელი გვარი"
                      }
                      inputSetter={setSearchValue}
                    />
                  </div>
                  <div className="shrink-0">
                    <SelectInput
                      options={[
                        {
                          id: 1,
                          title: "ახალი დარეგისტრირებულები",
                        },
                        {
                          id: 2,
                          title: "აქტიური მომხმარებლები",
                        },
                        {
                          id: 3,
                          title: "შემსყიდველები",
                        },
                      ]}
                      placeholder={"სორტირება"}
                    />
                  </div>
                  <SubMenu>
                    <div className="flex items-center gap-2">
                      <Switch
                        inputSetter={handleUserTableColumns}
                        inputName={"registration_date"}
                        value={userTableColumns.registration_date}
                      />
                      <p className="text-xs text-slate-600">
                        რეგისტრაციის თარიღი
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Switch
                        inputSetter={handleUserTableColumns}
                        inputName={"last_activity"}
                        value={userTableColumns.last_activity}
                      />
                      <p className="text-xs text-slate-600">ბოლო აქტივობა</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Switch
                        inputSetter={handleUserTableColumns}
                        inputName={"spent_money"}
                        value={userTableColumns.spent_money}
                      />
                      <p className="text-xs text-slate-600">
                        დახარჯული თანხები
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Switch
                        inputSetter={handleUserTableColumns}
                        inputName={"place"}
                        value={userTableColumns.place}
                      />
                      <p className="text-xs text-slate-600">რეგიონი / ქალაქი</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Switch
                        inputSetter={handleUserTableColumns}
                        inputName={"postal_code"}
                        value={userTableColumns.postal_code}
                      />
                      <p className="text-xs text-slate-600">საფოსტო კოდი</p>
                    </div>
                    <div className="flex items-center gap-2 w-full justify-between mt-3">
                      <BsFiletypePdf className="cursor-pointer text-2xl text-red-500" />
                      <BsFiletypeXls className="cursor-pointer text-2xl text-green-500" />
                      <BsFiletypeCsv className="cursor-pointer text-2xl text-orange-500" />
                    </div>
                  </SubMenu>
                </div>
              </div>
              {isLoading ? (
                <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                  <BiLoaderAlt className="w-20 h-20 animate-spin text-blue-1000" />
                </div>
              ) : isLoading === false && usersData.length === 0 ? (
                <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                  <p className="text-slate-600 text-sm">
                    მონაცემები არ მოიძებნა
                  </p>
                </div>
              ) : (
                <>
                  <div className="max-sm:overflow-auto scrollbar">
                    <div className="max-sm:w-[1000px]">
                      <div className={`grid grid-cols-${columnNumber} p-5`}>
                        <div className="flex gap-2 items-center">
                          <p className="text-slate-600 text-xs">
                            მომხმარებლები
                          </p>
                        </div>
                        {userTableColumns.registration_date && (
                          <div className="flex gap-2 items-center">
                            <p className="text-slate-600 text-xs">
                              რეგისტრაციის თარიღი
                            </p>
                          </div>
                        )}
                        {userTableColumns.last_activity && (
                          <div className="flex gap-2 items-center">
                            <p className="text-slate-600 text-xs">
                              ბოლო აქტივობა
                            </p>
                          </div>
                        )}
                        <div className="flex gap-2 items-center">
                          <p className="text-slate-600 text-xs">ელ-ფოსტა</p>
                        </div>
                        <div className="flex gap-2 items-center">
                          <p className="text-slate-600 text-xs">
                            მობილური ნომერი
                          </p>
                        </div>
                        {userTableColumns.spent_money && (
                          <div className="flex gap-2 items-center">
                            <p className="text-slate-600 text-xs">
                              დახარჯული თანხა
                            </p>
                          </div>
                        )}
                        {userTableColumns.place && (
                          <div className="flex gap-2 items-center">
                            <p className="text-slate-600 text-xs">
                              რეგიონი - ქალაქი
                            </p>
                          </div>
                        )}
                        {userTableColumns.postal_code && (
                          <div className="flex gap-2 items-center">
                            <p className="text-slate-600 text-xs">
                              საფოსტო ინდექსი
                            </p>
                          </div>
                        )}
                        <div className="flex gap-2 items-center justify-end">
                          <p className="text-slate-600 text-xs">მოქმედება</p>
                        </div>
                      </div>
                      {usersData.map((user) => {
                        return (
                          <div key={user.id}>
                            <Line className="bg-gray-400_87 w-full h-[1px]" />
                            <UsersCard
                              data={user}
                              updateFunction={setUpdateUsersData}
                              columnNumber={columnNumber}
                              userTableColumns={userTableColumns}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <div className="flex items-center justify-end p-5 max-sm:flex-col max-sm:gap-2">
                    <PaginatedItems
                      dataLength={total}
                      itemsPerPage={8}
                      pageNumberSetter={setPage}
                      forcedPage={page}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
