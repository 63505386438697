import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"
import RadioButton from "../../components/inputs/RadioButton"
import Checkbox from "../../components/inputs/Checkbox"

import { BiPlus } from "react-icons/bi"

const CartOptions = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                კალათის პარამეტრები
            </h2>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        მომხმარებელტან კონტაქტის მეთოდი
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        აირჩიეთ რომლი იქნება თქვენი ძირიტადი საკონტაქტო ინფორმაცია
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"ტელეფონის ნომერი და ელ-ფოსტა"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"ელ-ფოსტა"}
                            uniqName={"111"}
                        />
                    </div>
                    <p className="text-red-500 text-sm mt-4">
                        SMS სერვისის გასააქტიურებლად აუცილებელია აირჩო SMS Provider
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <Checkbox
                            name={"ჩაირთოს კალაის გაზიარების ინფორმაცია"}
                        />
                        <Checkbox
                            name={"გავხადოთ აუცილებელი მომხმარებლის დარეგისტრირება შეკვეთის გასაფორმებლად"}
                        />
                        <Checkbox
                            name={"შეძენა კალატაში გადამისამართების გარეშე"}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        მომხმარებლის ინფორმაცია
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        სახელი გვარი
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"მხოლოდ მოითხოვოს გვარი"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"მოითხოვოს მთლიანი სახელი"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        კმპანიის სახელი
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"სურვილისამებრ"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"აუცილებელი"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        მისამართი
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"სურვილისამებრ"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"აუცილებელი"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        მიტანის მისამართი
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"სურვილისამებრ"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"აუცილებელი"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        ტელეფონის ნომერი
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"სურვილისამებრ"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"აუცილებელი"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col">
                    <p className="text-blue-1000 text-sm">
                        ელ-ფოსტა
                    </p>
                    <div className="w-full mt-4 flex flex-col gap-2">
                        <RadioButton
                            name={"არ ჭირდება"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"სურვილისამებრ"}
                            uniqName={"111"}
                        />
                        <RadioButton
                            name={"აუცილებელი"}
                            uniqName={"111"}
                        />
                    </div>
                </div>
            </div>

            <div className="flex gap-2 items-center justify-end mt-10 max-sm:flex-col">
                <Button
                    Icon={BiPlus}
                    style={"border flex gap-2 items-center border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm max-sm:w-full"}
                    text={"ახალი ველების დამატება სურვილისამებრ"}
                />
                <Button
                    style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm max-sm:w-full"}
                    text={"შენახვა"}
                />
            </div>
        </div>
    )
}

export default CartOptions