import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"
import TextInput from "../../components/inputs/TextInput"
import Switch from "../../components/inputs/Switch"
import SelectInput from "../../components/inputs/SelectInput"

const AddLocation = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                ადგილმდებარეობის დამატება
            </h2>
            <p className="text-slate-400 text-sm mt-5">
                შეიყვანეთ სასურველი ადგილმდებარეობის ინფორმაცია
            </p>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="flex gap-5 items-center px-8 py-4">
                    <p className="text-slate-600 text-sm">
                        საქართველო
                    </p>
                    <Switch />
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col gap-3">
                    <SelectInput
                        name={"ქვეყანა/რეგიონი"}
                        options={[
                            {
                                id: "1",
                                title: "123123"
                            },
                        ]}
                    />
                    <TextInput
                        name={"ქალაქი"}
                        type={"text"}
                    />
                    <TextInput
                        name={"მისამართი"}
                        type={"text"}
                    />
                    <TextInput
                        name={"საფოსტო ინდექსი"}
                        type={"text"}
                    />
                    <TextInput
                        name={"შენობის ნომერი"}
                        type={"text"}
                    />
                    <TextInput
                        name={"ტელეფონის ნომერი"}
                        type={"text"}
                    />
                    <TextInput
                        name={"ელ-ფოსტა"}
                        type={"text"}
                    />
                    <TextInput
                        name={"ინტერნეტ ბანკი"}
                        type={"text"}
                    />
                </div>
            </div>

            <div className="flex gap-2 items-center justify-end mt-10">
                <Button
                    style={"border flex gap-2 items-center border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm"}
                    text={"გასუფთავება"}
                />
                <Button
                    style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                    text={"შენახვა"}
                />
            </div>
        </div>
    )
}

export default AddLocation