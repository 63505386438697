//library imports
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//components
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/inputs/Button";
import SelectInput from "../../components/inputs/SelectInput";
import OrderDetailsCard from "./OrderDetailsCard";
//functions
import getSingleRecord from "../../Services/getSingle";
import getAllWithoutPaginate from "../../Services/getAllWithoutPaginate";
import updateSingleRecord from "../../Services/updateSingle";
import useGetOrderStatuses from "../../components/generalData/useGetOrderStatuses";

const EditOrder = () => {
  //get single user id from params
  const { orderId } = useParams();
  //order details
  const [orderData, setOrderData] = useState([]);
  //update order data
  const [updateOrderRecords, setUpdateOrderRecords] = useState(null);
  //branches
  const [branches, setBranches] = useState([]);
  //payload data for update api
  const [newData, setNewData] = useState({
    // "delivery_cost": "number;",
    // "delivery_method": "string;",
    // "other_cost": "number;",
    // "price_details": "string;",
    // "status": "string;",
    // "country": "string;",
    // "city": "string;",
    // "street": "string;",
    // "address_details": "string;",
    // "name": "string;",
    // "phone": "string;",
    // "email": "string;",
    // "comment": "string;"
  });
  //order statuses
  const orderStatuses = useGetOrderStatuses();

  //set values on specific keys in new data state
  const handleNewData = (value, key) => {
    setNewData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //update record
  const handleUpdateOrder = (id) => {
    updateSingleRecord({
      url: "admin/order/",
      id: id,
      payload: newData,
    });
  };

  const handleGetBranches = () => {
    const setBranchesData = (value) => {
      setBranches(
        value.map((val) => {
          return {
            id: val.id,
            title: val.name,
          };
        })
      );
    };
    getAllWithoutPaginate({
      url: "/admin/branch",
      stateUpdater: setBranchesData,
    });
  };

  useEffect(() => {
    if (orderId) {
      getSingleRecord({
        url: "/admin/order/",
        id: orderId,
        stateUpdater: setOrderData,
      });
    }
  }, [orderId, updateOrderRecords]);

  useEffect(() => {
    handleGetBranches();
  }, []);

  return (
    <div className="min-h-screen w-full p-5">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
        მომხმარებლის დამატება
      </h2>
      <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
        <div className="w-full col-span-2 max-lg:col-span-1 ">
          <div className="w-full grid grid-cols-1 gap-5">
            <TextInput
              name={"კოდი"}
              placeholder={"კოდი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <SelectInput
              options={orderStatuses}
              placeholder={"სტატუსი"}
              value={orderData.status}
            />
            <TextInput
              name={"მისამართი"}
              placeholder={"მისამართი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.address_details}
            />
            <TextInput
              name={"ტელეფონი"}
              placeholder={"ტელეფონი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.phone}
            />
            <TextInput
              name={"ელექტრონული ფოსტა"}
              placeholder={"...@gmail.com"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.email}
            />
            <TextInput
              name={"კომენტარი"}
              placeholder={"კომენტარი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.comment}
            />
          </div>
          <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-5">
            გადახდა
          </h2>
          <div className="w-full grid grid-cols-2 max-sm:grid-cols-1 gap-5 mt-5">
            <TextInput
              name={"სახელი"}
              placeholder={"სახელი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"გვარი"}
              placeholder={"გვარი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"კომპანია"}
              placeholder={"კომპანია"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ტელეფონი"}
              placeholder={"ტელეფონი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.phone}
            />
            <TextInput
              name={"გადახდის მისამართი 1"}
              placeholder={"გადახდის მისამართი 1"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"გადახდის მისამართი 2"}
              placeholder={"გადახდის მისამართი 2"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ქალაქი"}
              placeholder={"ქალაქი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.city}
            />
            <TextInput
              name={"საიდენთიფიკაციო კოდი"}
              placeholder={"საიდენთიფიკაციო კოდი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ქვეყანა"}
              placeholder={"ქვეყანა"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.country}
            />
            <TextInput
              name={"ქუჩა"}
              placeholder={"ქუჩა"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.street}
            />
            <TextInput
              name={"ელ-ფოსტა"}
              placeholder={"ელ-ფოსტა"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.email}
            />
            <TextInput
              name={"ტრანზაქციის იდენთიფიკატორი"}
              placeholder={"ტრანზაქციის იდენთიფიკატორი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <SelectInput
              options={[
                {
                  id: "1",
                  title: "არ არის",
                },
                {
                  id: "2",
                  title: "გადახდა ჩეკით",
                },
                {
                  id: "3",
                  title: "გადახდა ნაღდი ფულით მიღებისას",
                },
                {
                  id: "4",
                  title: "სხვა",
                },
              ]}
              placeholder={"სტატუსი"}
            />
          </div>
          <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 mt-5">
            მიწოდება
          </h2>
          <div className="w-full grid grid-cols-2 max-sm:grid-cols-1 gap-5 mt-5">
            <TextInput
              name={"სახელი"}
              placeholder={"სახელი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"გვარი"}
              placeholder={"გვარი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"კომპანია"}
              placeholder={"კომპანია"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ტელეფონი"}
              placeholder={"ტელეფონი"}
              inputSetter={() => {}}
              inputName={""}
              value={orderData.phone}
            />
            <TextInput
              name={"გადახდის მისამართი 1"}
              placeholder={"გადახდის მისამართი 1"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"გადახდის მისამართი 2"}
              placeholder={"გადახდის მისამართი 2"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ქალაქი"}
              placeholder={"ქალაქი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"საიდენთიფიკაციო კოდი"}
              placeholder={"საიდენთიფიკაციო კოდი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"ქვეყანა"}
              placeholder={"ქვეყანა"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
            <TextInput
              name={"მუნიციპალიტეტი"}
              placeholder={"მუნიციპალიტეტი"}
              inputSetter={() => {}}
              inputName={""}
              value={""}
            />
          </div>
        </div>
        <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
          <div className=" w-full flex gap-5 flex-col">
            <Button
              onClickFunction={() => {
                handleUpdateOrder(orderId);
              }}
              style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
              text={"განახლება"}
            />

            <div className="shadow-my p-5 rounded-2xl flex flex-col gap-3">
              <p className="text-sm text-slate-600">
                განახლების თარიღი : 10.12.2022
              </p>
              <p className="text-sm text-slate-600">
                დადასტურების თარიღი : 10.12.2022
              </p>

              <p className="text-sm text-slate-600">
                მიტანის თარიღი : 10.12.2022
              </p>
              <p className="text-sm text-slate-600">
                გაუქმების თარიღი : 10.12.2022
              </p>
            </div>

            <div className="shadow-my p-5 rounded-2xl">
              <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800">
                შეკვეთის დეტალები
              </h2>
              <div>
                <div className="grid grid-cols-3 py-5 border-b border-slate-200">
                  <div className="flex gap-2 items-center">
                    <p className="text-slate-600 text-sm">სახელი</p>
                  </div>
                  <div className="flex gap-2 items-center justify-center">
                    <p className="text-slate-600 text-sm">კოდი</p>
                  </div>
                  <div className="flex gap-2 items-center justify-end">
                    <p className="text-slate-600 text-sm">მოქმედება</p>
                  </div>
                </div>

                {orderData?.ordersDetails?.map((item) => (
                  <OrderDetailsCard
                    orderItem={item}
                    product={item.product}
                    orderId={orderId}
                    setUpdateOrderRecords={setUpdateOrderRecords}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
