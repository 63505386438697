import { BsBox2, BsFillArrowRightCircleFill } from 'react-icons/bs'

const Memberships = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                პაკეტები
            </h2>
            <div className="mt-5 grid grid-cols-1 gap-5">
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            სტარტაპი
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            მედიუმი
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
                <div className="bg-gray-100 p-5 rounded-full flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                        <div>
                            <BsBox2 className='text-blue-1000 text-base' />
                        </div>
                        <p className="text-md text-blue-1000">
                            ენტერპრეისი
                        </p>
                    </div>
                    <div>
                        <BsFillArrowRightCircleFill className='text-blue-1000 text-2xl' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Memberships