import { useState } from "react";
import SelectInput from "../inputs/SelectInput";

const QuestionFour = () => {
  const [answer, setAnswer] = useState({
    shopCondition: 0,
  });

  const handleShopCondition = (newCondition) => {
    setAnswer({
      shopCondition: newCondition,
    });
  };
  return (
    <div>
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-blue-1000">
        სად განათავსებთ ბიზესს
      </h2>
      <p className="text-sm text-slate-600">
        ჩვენ გამოვიყენებთ ადგილმდებარეობას უკეთესი მიწოდების ტარიფებისა და
        რეკომენდირებული პლაგინების გამოყენებით
      </p>

      <div className="mt-5 grid grid-cols-2 gap-5">
        <SelectInput
          name={"ქვეყანა / რეგიონი"}
          options={[
            {
              id: "1",
              title: "123123",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default QuestionFour;
