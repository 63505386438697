//library imports
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//components
import Checkbox from "../../components/inputs/Checkbox";
import Button from "../../components/inputs/Button";
import LanguageSwitcher from "../../components/langageSwitcher/LanguageSwitcher";
import TextInput from "../../components/inputs/TextInput";
import unprotectedAxios from "../../api/unprotectedAxios";
import { errorToast, successToast } from "../../components/toast/ToastAlerts";
import { PrimaryContext } from "../../components/MainContext/MainContext";
//resources
import { BsEye } from "react-icons/bs";
import logo from "../../img/bmlogo.png"

const Login = () => {

    //navigation
    const navigation = useNavigate();

    //functions from api
    const { setToken, setUser, isAuthorized } = useContext(PrimaryContext)

    //user credentials for registration
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    })
    //check if fields are empty
    const [credentialsEmpty, setCredentialsEmpty] = useState({
        emailIsEmpty: false,
        passwordIsEmpty: false,
    })

    //when value of credentials is filled set "this" state to filled
    const handleEmailIsNotEmpty = () => {
        setCredentialsEmpty(prev => ({ ...prev, emailIsEmpty: false }))
    }

    //when value of credentials is filled set "this" state to filled
    const handlePasswordIsNotEmpty = () => {
        setCredentialsEmpty(prev => ({ ...prev, passwordIsEmpty: false }))
    }

    //handle credentials 
    const handleCredentials = (value, key) => {
        setCredentials(prev => ({ ...prev, [key]: value }))
    }

    //handle submit
    const handleSubmit = () => {
        if (credentials.email === "" || credentials.password === "") {
            if (credentials.email === "") {
                setCredentialsEmpty(prev => ({ ...prev, emailIsEmpty: true }))
            }
            if (credentials.password === "") {
                setCredentialsEmpty(prev => ({ ...prev, passwordIsEmpty: true }))
            }
        } else {
            unprotectedAxios.post("/adminAuth/loginAdmin", credentials)
                .then(({ data }) => {
                    setUser(data.admin)
                    setToken(data.access_token)
                    successToast("კეთილი იყოს თქვენი დაბრუნება");

                    setTimeout(() => {
                        navigation("/home");
                    }, 2000);
                })
                .catch(({ response }) => {
                    if (response) {
                        if (response.data.statusCode === 422) {
                            errorToast("ელ-ფოსტა არასწორია")
                        } else if (response.data.statusCode === 403) {
                            errorToast("პაროლი არასწორია")
                        }
                    }
                })
        }

    }

    //if user is already authorized navigate to home page
    useEffect(() => {
        if (isAuthorized) {
            navigation("/home");
        }
    }, [isAuthorized])

    return (
        <div className="flex items-center justify-center">
            <div className="w-7/12 h-screen flex items-center justify-center bg-gray-100 max-lg:hidden">
                <div>
                    <img src={logo} />
                </div>
            </div>
            <div className="w-5/12 h-screen bg-white  gap-[30px] p-10 max-lg:w-8/12 max-md:w-10/12 max-sm:w-full max-sm:p-5 relative">
                <div className="flex gap-5 items-center justify-between absolute top-0 left-0 w-full p-10 max-sm:p-5">
                    <img src={logo} className="h-12" />
                    <LanguageSwitcher />
                </div>
                <div className="h-full flex flex-col justify-center items-center">
                    <div className="mt-4 w-full">
                        <p className="text-sm text-slate-500 mt-4">გასაგრძელებლად გაიარეთ ავტორიზაცია</p>
                    </div>
                    <div className="flex flex-col gap-5 mt-4 w-full">
                        <TextInput
                            placeholder={"ელექტრონული ფოსტა"}
                            inputSetter={handleCredentials}
                            inputName={"email"}
                            isEmpty={credentialsEmpty.emailIsEmpty}
                            setIsNotEmpty={handleEmailIsNotEmpty}
                        />
                        <TextInput
                            placeholder={"პაროლი"}
                            inputSetter={handleCredentials}
                            SuffixIcon={BsEye}
                            type={"password"}
                            inputName={"password"}
                            isEmpty={credentialsEmpty.passwordIsEmpty}
                            setIsNotEmpty={handlePasswordIsNotEmpty}
                        />
                        <div className="flex justify-between items-center">
                            <Checkbox
                                name={"დაიმახსოვრე"}
                            />
                            <Link to={"../reset-password"} className="text-sm text-slate-500">
                                დაგავიწყდა პაროლი?
                            </Link>
                        </div>
                        <div className="mt-4">
                            <Button
                                style={"rounded-xl bg-blue-1000 px-4 py-3 text-white font-bold w-full"}
                                text={"შესვლა"}
                                onClickFunction={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login


