import { useState } from "react";

const QuestionsThree = () => {
  const [answer, setAnswer] = useState({
    shopCondition: 0,
  });

  const handleShopCondition = (newCondition) => {
    setAnswer({
      shopCondition: newCondition,
    });
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-blue-1000">
        რის გაყიდვასს გეგმავთ
      </h2>
      <p className="text-sm text-slate-600">
        აირჩიეთ რით გსურთ დაიწყოთ თქვენი ონლაინ მაღაზია, ჩვენ დაგეხმარებით
        თქვენი მაღაზიის მარაგების მართვასა და მოწესრიგებაში
      </p>

      <div className="mt-5 grid grid-cols-2 gap-5">
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(0);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 0
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-md text-blue-1000 font-bold">
              ფიზიკური პროდუქტი
            </p>
            <p className="text-sm text-slate-600">ვყიდულობ ან ვმზადებ</p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(1);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 1
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-md text-blue-1000">დროპშიპინგ პროდუქტი</p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(2);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 2
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <div>
            <p className="text-md text-blue-1000 font-bold">
              ციფრული პროდდუქტი
            </p>
            <p className="text-sm text-slate-600">ვყიდულობ ან ვამზდებ</p>
          </div>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(3);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 3
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <p className="text-md text-blue-1000">მომსაურება სერვისი</p>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(4);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 4
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <p className="text-md text-blue-1000">მოგვიაენბით გადავწყვეტ</p>
        </div>
      </div>
    </div>
  );
};

export default QuestionsThree;
