import { useState } from "react";

import { Line } from "../inputs/Line";
import Button from "../inputs/Button";
import QuestionsOne from "./QuestionsOne";
import QuestionTwo from "./QuestionTwo";
import QuestionsThree from "./QuestionsThree";
import QuestionFour from "./QuestionFour";
import QuestionSix from "./QuestionSix";

import { BsArrowLeftShort } from "react-icons/bs";

import logo from "../../img/bmlogo.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const QuestionsLayout = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleCurrentQuestion = (option) => {
    if (option === "-" && currentQuestion !== 0) {
      setCurrentQuestion((prev) => prev - 1);
    } else if (option === "+" && currentQuestion !== 7) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (currentQuestion === 4) {
      navigate("../congratulations");
    }
  }, [currentQuestion]);

  return (
    <div
      className="w-full h-full fixed top-0 left-0 bg-blue-1000 bg-opacity-80 flex 
        items-center justify-center z-[60]"
    >
      <div className="bg-white rounded-2xl p-10 max-md:p-5 w-[60%] h-[90%] flex flex-col justify-between">
        <div>
          <img src={logo} className="w-[120px]" />
          <div className="w-full flex items-center gap-2 mt-5">
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 0 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div>
            <Line className={"w-6 h-[1px] bg-blue-1000"} />
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 1 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div>
            <Line className={"w-6 h-[1px] bg-blue-1000"} />
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 2 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div>
            <Line className={"w-6 h-[1px] bg-blue-1000"} />
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 3 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div>
            {/* <Line className={"w-6 h-[1px] bg-blue-1000"} />
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 4 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div> */}
            {/* <Line className={"w-6 h-[1px] bg-blue-1000"} />
            <div className="w-8 h-8 rounded-full bg-blue-1000 flex justify-center items-center">
              {currentQuestion === 5 && (
                <div className="w-4 h-4 rounded-full bg-white"></div>
              )}
            </div> */}
          </div>
          <div className="mt-10">
            {currentQuestion === 0 && <QuestionsOne />}
            {currentQuestion === 1 && <QuestionTwo />}
            {currentQuestion === 2 && <QuestionsThree />}
            {currentQuestion === 3 && <QuestionFour />}
            {/* {currentQuestion === 4 && <QuestionSix />} */}
            {/* {currentQuestion === 5 && <QuestionSix />} */}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              handleCurrentQuestion("-");
            }}
          >
            <BsArrowLeftShort className="text-blue-1000 text-md" />
            <p className="text-blue-1000">უკან</p>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              handleCurrentQuestion("+");
            }}
          >
            <Button
              style={
                "bg-blue-1000 px-5 py-3 text-[12px] rounded-full text-white font-bold"
              }
              text={"შემდეგი"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsLayout;
