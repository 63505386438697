import { useState } from "react"
import { BiMessageRoundedDots, BiMessageX, BiPaperPlane } from "react-icons/bi";
import { BsArchive, BsList } from "react-icons/bs"
import SearchInput from "../../components/inputs/SearchInput";
import MessageCard from "./MessageCard";
import Button from "../../components/inputs/Button";

const Inbox = () => {

    const [menu, setMenu] = useState([
        {
            Icon: BiMessageRoundedDots,
            name: "შეტყობინება",
            link: "",
            quantity: 15,
        },
        {
            Icon: BiPaperPlane,
            name: "გაგზავნილი",
            link: "",
            quantity: 15,
        },
        {
            Icon: BiMessageX,
            name: " დრაფტი",
            link: "",
            quantity: 15,
        },
        {
            Icon: BsArchive,
            name: "არქივი",
            link: "",
            quantity: 15,
        },
    ])
    const [menuToggle, setMenuToggle] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    return (
        <div className="min-h-screen w-full p-5">
            <div className="flex items-center justify-between w-full">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-900">
                    კატეგორიები
                </h2>
                <div className="relative">
                    <div className="bg-white rounded-2xl w-10 h-10 p-2 shadow-bs1 cursor-pointer"
                        onClick={() => { setMenuToggle(prev => !prev) }}
                    >
                        <BsList className="w-full h-full text-gray-500" />
                    </div>
                    {menuToggle &&
                        <div className="bg-white rounded-2xl w-[300px] p-5 shadow-bs1 absolute top-[110%] right-0">
                            {menu.map((item, index) => {
                                return (
                                    <div
                                        className={`rounded-2xl p-4 flex items-center justify-between cursor-pointer ${currentPage === index && "bg-gray-100"}`}
                                        key={index}
                                        onClick={() => setCurrentPage(index)}
                                    >
                                        <div className="flex items-center gap-2">
                                            <div>
                                                <item.Icon className={`text-lg ${currentPage === index ? "text-deep_purple-600" : "text-gray-500"}`} />
                                            </div>
                                            <p className={`text-md ${currentPage === index ? "text-deep_purple-600" : "text-gray-500"}`}>
                                                {item.name}
                                            </p>
                                        </div>
                                        <div className="w-6 h-6 p-2 flex items-center justify-center rounded-full bg-light_blue-400">
                                            <p className="text-white text-[10px] font-bold mt-1">
                                                {item.quantity}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    }
                </div>

            </div>
            <div className="w-full rounded-2xl bg-white shadow-bs1 grid grid-cols-3 mt-5">
                <div className="col-span-1 p-5 flex flex-col gap-5">
                    <SearchInput />
                    <p className="text-base text-gray-500 font-bold">
                        შემოსულები
                    </p>
                    <div className="max-h-[600px] overflow-y-auto scrollbar">
                        <MessageCard active={true} />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                        <MessageCard />
                    </div>
                </div>
                <div className="col-span-2 w-full h-full">
                    <div className="p-5 flex items-center gap-2 h-[14%]">
                        <div className="bg-gray-400 h-12 w-12 rounded-2xl"></div>
                        <div className="flex flex-col gap-1">
                            <p className="text-gray-900 text-xl font-bold">
                                გიორგი ბერიძე
                            </p>
                            <p className="text-gray-500 text-sm">
                                შპს ბიზნესმეიქერს
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 rounded-br-2xl border-r border-b border-solid border-white h-[86%]">
                        <div className="w-full h-[70%] overflow-y-auto flex flex-col gap-5 scrollbar">
                            <div className="w-full p-5 max-w-[50%]">
                                <div className="flex items-center justify-between">
                                    <p className="text-gray-900 text-lg">
                                        გიორგი ბერიძე
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        12:45 PM
                                    </p>
                                </div>
                                <div className="w-full rounded-2xl bg-white p-4 mt-4">
                                    <p className="text-gray-900 text-sm">
                                        გამარჯობა! მე შევუკვეთე თქვენს ონლაინ მაღაზიაში პროდუქტები,
                                        მსურს რაოდენობის დამატება, 2 მამაკაცის ზედა, 2
                                        შარვალი, 5 პიჯაკი მინდა, მადლობას გიხდით წინასწარ.
                                        <br />
                                        ასევე გიგზავნით ფოტოს და რომ ნახოთ მსგავსი თუ
                                        გაქვთ მარაგში. ველოდები თქვენგან პასუხს.
                                    </p>
                                </div>
                            </div>
                            <div className="p-5 self-end max-w-[50%]">
                                <div className="flex items-center justify-between">
                                    <p className="text-gray-500 text-sm">
                                        12:45 PM
                                    </p>
                                    <p className="text-gray-900 text-lg">
                                        გიორგი ბერიძე
                                    </p>
                                </div>
                                <div className="w-full rounded-2xl bg-deep_purple-600 p-4 mt-4">
                                    <p className="text-white text-sm">
                                        გამარჯობა! მე შევუკვეთე თქვენს ონლაინ მაღაზიაში პროდუქტები,
                                        მსურს რაოდენობის დამატება, 2 მამაკაცის ზედა, 2
                                        შარვალი, 5 პიჯაკი მინდა, მადლობას გიხდით წინასწარ.
                                        <br />
                                        ასევე გიგზავნით ფოტოს და რომ ნახოთ მსგავსი თუ
                                        გაქვთ მარაგში. ველოდები თქვენგან პასუხს.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-[30%] p-5 ">
                            <divc className="h-[100%] shadow-bs1 flex flex-col rounded-2xl">
                                <textarea className="w-full rounded-t-2xl bg-white outline-none pl-2 pt-2 h-[70%] scrollbar"
                                    style={{ resize: "none" }}
                                >

                                </textarea>
                                <div className="bg-white rounded-b-2xl h-[30%] p-2">
                                    <Button
                                        style="bg-deep_purple-600 cursor-pointer flex items-center justify-center px-4 rounded-xl
                                         shadow-bs1 h-full text-white font-bold text-sm"
                                        text="გაგზავნა"
                                    />
                                </div>
                            </divc>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox