import { useState, useEffect } from "react";

const Textarea = ({
    name = null,
    value,
    placeholder
}) => {
    //value variable
    const [currentValue, setCurrentValue] = useState("");

    //value handler
    const handleCurrentValue = (e) => {
        setCurrentValue(e.target.value);
    }

    //set passed value
    useEffect(() => {
        setCurrentValue(value);
    }, [])

    return (
        <div className="h-full">
            <p className={`text-slate-600 text-sm  ${name !== null && "mb-3"}`}>
                {name}
            </p>
            <div className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-2xl dark:bg-slate-800 border-gray-300
            border dark:border-slate-600">
                <textarea
                    onChange={(e) => { handleCurrentValue(e) }}
                    value={currentValue}
                    placeholder={placeholder}
                    className="w-full pl-1.5 focus:outline-none outline-none
                    dark:bg-slate-800 border-0 min-h-[200px] text-slate-500"/>
            </div>
        </div>
    )
}

export default Textarea;