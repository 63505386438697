import React from 'react'
import { BsClockFill } from 'react-icons/bs'
import { Line } from '../../components/inputs/Line'

const Calendar = () => {
    return (
        <div className="min-h-screen w-full">
            <div className="w-full p-5">
                <div className="flex justify-between items-center w-full">
                    <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-900">
                        კალენდარი
                    </h2>
                </div>
                <div className="w-full grid grid-cols-3 mt-5 gap-5">
                    <div className='col-span-1 grid grid-cols-1 gap-5'>
                        <div className='w-full flex flex-col gap-4'>
                            <div className="bg-white flex gap-5 items-center p-4 rounded-2xl w-full">
                                <div className="bg-gray-100_01 flex h-14 w-14 items-center justify-center rounded-lg">
                                    <p
                                        className="text-md text-deep_purple-600 text-center"
                                    >
                                        12 <br />
                                        იან
                                    </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-gray-900 text-lg">
                                        127 შეკვეთა
                                    </p>
                                    <div className='flex items-center gap-2'>
                                        <div>
                                            <BsClockFill className='text-gray-500 text-sm' />
                                        </div>
                                        <p className="text-gray-500 text-sm">
                                            127 შეკვეთა
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white flex gap-5 items-center p-4 rounded-2xl w-full">
                                <div className="bg-teal-50_87 flex h-14 w-14 items-center justify-center rounded-lg">
                                    <p
                                        className="text-md text-light_blue-300 text-center"
                                    >
                                        12 <br />
                                        იან
                                    </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-gray-900 text-lg">
                                        127 შეკვეთა
                                    </p>
                                    <div className='flex items-center gap-2'>
                                        <div>
                                            <BsClockFill className='text-gray-500 text-sm' />
                                        </div>
                                        <p className="text-gray-500 text-sm">
                                            127 შეკვეთა
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white flex gap-5 items-center p-4 rounded-2xl w-full">
                                <div className="bg-pink-50 flex h-14 w-14 items-center justify-center rounded-lg">
                                    <p
                                        className="text-md text-pink-400 text-center"
                                    >
                                        12 <br />
                                        იან
                                    </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-gray-900 text-lg">
                                        127 შეკვეთა
                                    </p>
                                    <div className='flex items-center gap-2'>
                                        <div>
                                            <BsClockFill className='text-gray-500 text-sm' />
                                        </div>
                                        <p className="text-gray-500 text-sm">
                                            127 შეკვეთა
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded-2xl p-4">
                            <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-900">
                                ინსტრუქცია
                            </h2>
                            <div className='flex flex-col gap-2'>
                                <div className='flex items-center gap-2'>
                                    <Line className="bg-deep_purple-600 h-10 w-1" />
                                    <p className="text-gray-900 text-sm">
                                        კარგი
                                    </p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <Line className="bg-light_blue-300 h-10 w-1" />
                                    <p className="text-gray-900 text-sm">
                                        საშუალო
                                    </p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <Line className="bg-pink-400 h-10 w-1" />
                                    <p className="text-gray-900 text-sm">
                                        საშუალოზე დაბალი
                                    </p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <Line className="bg-orange-400 h-10 w-1" />
                                    <p className="text-gray-900 text-sm">
                                        დაბალი
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 bg-white rounded-2xl p-5'>

                    </div>
                </div>
            </div>

        </div >
    )
}

export default Calendar