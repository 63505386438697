import { useState } from "react";

const QuestionsOne = () => {
  const [answer, setAnswer] = useState({
    shopCondition: 0,
  });

  const handleShopCondition = (newCondition) => {
    setAnswer({
      shopCondition: newCondition,
    });
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="max-sm:text-md max-md:text-lg text-xl text-black-900">
        ჩვენ დაგეხმარებით ბიზნესის სწორად განვითარებაში. როგორია თქვენი <br />{" "}
        მაღაზზიის მდგომარეობა?
      </h2>

      <div className="mt-5 grid grid-cols-2 gap-5">
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(0);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 0
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <p className="text-md text-blue-1000">მე ახლა ვიწყებ</p>
        </div>
        <div
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 cursor-pointer"
          onClick={() => {
            handleShopCondition(1);
          }}
        >
          <div
            className={`w-6 h-6 rounded-full flex justify-center items-center transition-all duration-300
                    ${
                      answer.shopCondition === 1
                        ? "bg-blue-1000"
                        : "bg-slate-100"
                    }`}
          ></div>
          <p className="text-md text-blue-1000">
            ვყიდი უკვე ონლაინ ან პირადად.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionsOne;
