import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"

import { BsCheck, BsDownload, BsFillFileBarGraphFill, BsFillPlusCircleFill } from "react-icons/bs"
import img from "../../img/google.jpg"

const BillingOptions = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                ბილინგის პარამეტრები
            </h2>
            <p className="text-slate-400 text-sm mb-3">
                აქ შეგიზლიათ განაახლოთ ბარათების ინფორმაცია
            </p>
            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <img src={img} className={"w-10 h-10"} />
                        <div>
                            <p className="text-blue-1000 text-sm">
                                სახელი **** 55555
                            </p>
                            <p className="text-slate-400 text-xs">
                                მოქმედების ვადა
                            </p>
                        </div>
                    </div>
                    <Button
                        style={"bg-gray-100 text-blue-1000 rounded-lg text-sm py-2 px-4"}
                        text={"Edit"}
                    />
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <img src={img} className={"w-10 h-10"} />
                        <div>
                            <p className="text-blue-1000 text-sm">
                                სახელი **** 55555
                            </p>
                            <p className="text-slate-400 text-xs">
                                მოქმედების ვადა
                            </p>
                        </div>
                    </div>
                    <Button
                        style={"bg-gray-100 text-blue-1000 rounded-lg text-sm py-2 px-4"}
                        text={"Edit"}
                    />
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <img src={img} className={"w-10 h-10"} />
                        <div>
                            <p className="text-blue-1000 text-sm">
                                სახელი **** 55555
                            </p>
                            <p className="text-slate-400 text-xs">
                                მოქმედების ვადა
                            </p>
                        </div>
                    </div>
                    <Button
                        style={"bg-gray-100 text-blue-1000 rounded-lg text-sm py-2 px-4"}
                        text={"Edit"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2 items-center">
                        <div >
                            <BsFillPlusCircleFill className="text-blue-1000 text-3xl " />
                        </div>
                        <p className="text-sm text-blue-1000">დაამატე ახალი ბარათი</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        ვალუტა
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <p className="text-slate-400 text-sm">
                            თქვენი მიმდინარე ვალუტა არის ლარი
                        </p>
                    </div>
                    <Button
                        style={"bg-gray-100 text-blue-1000 rounded-lg text-sm py-2 px-4"}
                        text={"Edit"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        გამოწერები
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between max-sm:flex-col max-sm:gap-2">
                    <div className="flex gap-2">
                        <img src={img} className={"w-10 h-10"} />
                        <div>
                            <p className="text-blue-1000 text-sm">
                                საცდელი ვერსია
                            </p>
                            <p className="text-slate-400 text-xs">
                                თქვენი საცდელი ვერსიის ბოლო ვადააა 12/23/12
                            </p>
                        </div>
                    </div>
                    <Button
                        style={"bg-blue-1000 text-white rounded-lg text-sm py-2 px-4 max-sm:w-full"}
                        text={"პაკეტის ძებნა"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        გადასახდელი თანხა
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between max-sm:flex-col max-sm:gap-2">
                    <div className="flex gap-2">
                        <img src={img} className={"w-10 h-10"} />
                        <div>
                            <p className="text-blue-1000 text-sm">
                                თქვენი საცდელი ვერსიის ბოლო ვადააა 12/23/12
                            </p>
                            <p className="text-red-500 text-xs">
                                გადახდამდე დარჩა 9 დღე
                            </p>
                        </div>
                    </div>
                    <Button
                        style={"bg-blue-1000 text-white rounded-lg text-sm py-2 px-4 max-sm:w-full"}
                        text={"99$ გადახდა"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 max-sm:px-4 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        ინვოისი
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <BsFillFileBarGraphFill className="text-slate-600 " />
                        <p className="text-sm text-slate-600">ინვოისი #12312</p>
                    </div>
                    <div className="flex justify-center max-sm:w-12 max-sm:items-center">
                        <Button
                            Icon={BsCheck}
                            style={"bg-green-300 text-green-500 rounded-lg text-sm p-1"}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            Icon={BsDownload}
                            style={"bg-blue-1000 text-white rounded-lg flex gap-2 text-sm py-2 px-4"}
                            text={"ჩამოტვირთვა"}
                        />
                    </div>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 max-sm:px-4 py-4 flex items-center justify-between">
                    <div className="flex gap-2">
                        <BsFillFileBarGraphFill className="text-slate-600 " />
                        <p className="text-sm text-slate-600">ინვოისი #12312</p>
                    </div>
                    <div className="flex justify-center max-sm:w-12 max-sm:items-center">
                        <Button
                            Icon={BsCheck}
                            style={"bg-green-300 text-green-500 rounded-lg text-sm p-1"}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            Icon={BsDownload}
                            style={"bg-blue-1000 text-white rounded-lg flex gap-2 text-sm py-2 px-4"}
                            text={"ჩამოტვირთვა"}
                        />
                    </div>
                </div>
            </div>

            <div className="flex gap-2 items-center justify-end mt-10">
                <Button
                    style={"border border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm"}
                    text={"გასუფთავება"}
                />
                <Button
                    style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                    text={"შენახვა"}
                />
            </div>
        </div>
    )
}

export default BillingOptions