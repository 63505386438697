import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import {
  BsList,
  BsFillBuildingFill,
  BsBagPlus,
  BsEnvelope,
  BsCash,
  BsCart,
  BsTruck,
  BsMap,
  BsTicket,
  BsGlobe,
  BsGear,
} from "react-icons/bs";
import { useState } from "react";
import { FaRegNewspaper, FaRegUser, FaScroll } from "react-icons/fa";
import { TbLanguage } from "react-icons/tb";

const OptionsMenu = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [menuData, setMenuData] = useState([
    {
      id: 1,
      link: "market-config",
      Icon: BsGear,
      name: "პარამეტრები",
      active: true,
    },
    {
      id: 2,
      link: "plugin-options",
      Icon: BsBagPlus,
      name: "პლაგინები",
      active: true,
    },
    {
      id: 3,
      link: "membership",
      Icon: BsBagPlus,
      name: "პაკეტები",
      active: true,
    },
    {
      id: 4,
      link: "payment-systems",
      Icon: BsFillBuildingFill,
      name: "გადახდის სიტემები",
      active: true,
    },
    {
      id: 5,
      link: "common",
      Icon: FaRegUser,
      name: "პროფილი",
      active: true,
    },
    {
      id: 6,
      link: "notifications",
      Icon: BsEnvelope,
      name: "შეტყობინებები",
      active: true,
    },
    {
      id: 7,
      link: "billing",
      Icon: BsCash,
      name: "ბილინგი",
      active: true,
    },
    {
      id: 8,
      link: "cart-options",
      Icon: BsCart,
      name: "კალათა",
      active: true,
    },
    {
      id: 9,
      link: "delivery-options",
      Icon: BsTruck,
      name: "გადაზიდვა და მიწოდება",
      active: true,
    },
    {
      id: 10,
      link: "location-options",
      Icon: BsMap,
      name: "ადგილმდებარეობა",
      active: true,
    },
    {
      id: 11,
      link: "promo-code",
      Icon: BsTicket,
      name: "პრომო კოდი",
      active: true,
    },
    {
      id: 12,
      link: "domen",
      Icon: BsGlobe,
      name: "დომენი",
      active: true,
    },
    {
      id: 13,
      link: "add-language",
      Icon: TbLanguage,
      name: "ენის დამატება",
      active: true,
    },
    {
      id: 14,
      link: "activity-journal",
      Icon: FaRegNewspaper,
      name: "აქტივობის ჟურნალი",
      active: true,
    },
    {
      id: 15,
      link: "policy",
      Icon: FaScroll,
      name: "პოლიტიკა",
      active: true,
    },
  ]);

  useEffect(() => {
    setMenuData((prev) =>
      prev.map((item) => {
        if (item.link === pathname.split("/")[2]) {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
          active: false,
        };
      })
    );
  }, [pathname]);

  return (
    <div
      className={`lg:overflow-y-auto lg:p-5 bg-white w-full lg:shadow-my lg:rounded-2xl max-lg:relative`}
    >
      <div className="max-lg:flex max-lg:justify-between">
        <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-sm:w-full lg:ml-4">
          პარამეტრები
        </h2>
        <div className="lg:hidden">
          <BsList
            className="text-slate-600 text-2xl cursor-pointer"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        </div>
        <div
          className={`max-lg:shadow-my max-lg:rounded-2xl max-lg:absolute  max-lg:top-[105%]  max-lg:right-0 bg-white z-50
                flex flex-col gap-1 ${
                  isOpen ? "max-lg:block" : "max-lg:hidden"
                }`}
        >
          {menuData.map((item) => {
            return (
              <Link to={`/options/${item.link}`} key={item.id}>
                <div
                  className={`flex items-center gap-2 rounded-xl group cursor-pointer p-4
                                        ${
                                          item.active
                                            ? "bg-blue-1000"
                                            : "hover:bg-blue-1000"
                                        }`}
                >
                  <div>
                    <item.Icon
                      className={`text-base cursor-pointer 
                                            ${
                                              item.active
                                                ? "text-white"
                                                : "text-slate-600 group-hover:text-white"
                                            }`}
                    />
                  </div>
                  <h2
                    className={`text-sm overflow-hidden 
                                            ${
                                              item.active
                                                ? "text-white"
                                                : "text-slate-600 group-hover:text-white"
                                            }`}
                  >
                    {item.name}
                  </h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OptionsMenu;
