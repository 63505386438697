//library imports
import { useEffect, useState } from "react"
//components
import TextInput from "../../components/inputs/TextInput"
import Button from "../../components/inputs/Button"
import SelectInput from "../../components/inputs/SelectInput"
//functions
import getSingleRecord from "../../Services/getSingle"
import updateSingleRecord from "../../Services/updateSingle"
//resources
import { BsEye } from "react-icons/bs"
import { useParams } from "react-router-dom"
import FileInput from "../../components/inputs/FileInput"

const AdminEdit = () => {
    //get single user id from params
    const { adminId } = useParams();
    //new admin data
    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        password: "",
        role: "1",
    })

    //handle admins data
    const handleAdminData = (value, key) => {
        setAdminData(prev => ({ ...prev, [key]: value }))
    }

    //create new admin
    const handleUpdateAdmin = () => {
        updateSingleRecord({
            url: "/admin/admins/",
            id: adminId,
            payload: adminData
        })
    }

    //get admins data from db
    useEffect(() => {
        getSingleRecord({
            url: "/admin/admins/",
            id: adminId,
            stateUpdater: setAdminData
        })
    }, [])

    return (
        <div className="min-h-screen w-full p-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                ადმინისტრატორის დამატება
            </h2>
            <div className="w-full grid grid-cols-3 pt-5 gap-5 max-lg:grid-cols-1">
                <div className="w-full col-span-2 max-lg:col-span-1 ">
                    <div className="w-full grid grid-cols-1 gap-5 shadow-my p-5 rounded-2xl">
                        <TextInput
                            name={"ადმინისტრატორი"}
                            placeholder={"სახელი გვარი"}
                            inputSetter={handleAdminData}
                            inputName={"name"}
                            value={adminData.name}
                        />
                        <TextInput
                            name={"ელ-ფოსტა"}
                            placeholder={"...@gmail.com"}
                            inputSetter={handleAdminData}
                            inputName={"email"}
                            value={adminData.email}
                        />
                        <TextInput
                            name={"რეგიონი"}
                            placeholder={"რეგიონი"}
                            inputSetter={handleAdminData}
                            inputName={"email"}
                        />
                        <TextInput
                            name={"ქალაქი"}
                            placeholder={"ქალაქი"}
                            inputSetter={handleAdminData}
                            inputName={"email"}
                        />
                        <SelectInput
                            name={"ადმინისტრატორის როლი"}
                            options={[
                                {
                                    id: 1,
                                    title: "სუპერ ადმინი"
                                },
                                {
                                    id: 2,
                                    title: "მაღაზიის მმართველი"
                                },
                                {
                                    id: 3,
                                    title: "კონტენტის მმართველი"
                                },
                                {
                                    id: 4,
                                    title: "შეკვეთების მმართველი"
                                },
                                {
                                    id: 5,
                                    title: "კურიერი"
                                },
                            ]}
                            inputSetter={handleAdminData}
                            inputName={"role"}
                            value={adminData.role}
                        />
                        <TextInput
                            name={"პაროლი"}
                            placeholder={"მინიმუმ 6 სიმბოლო"}
                            inputSetter={handleAdminData}
                            SuffixIcon={BsEye}
                            type={"password"}
                            inputName={"password"}
                        />

                    </div>
                </div>
                <div className="col-span-1 flex flex-col gap-3 max-lg:col-span-1">
                    <div className=" w-full flex gap-5 flex-col">
                        <div className="shadow-my p-5 rounded-2xl">
                            <h2 className="max-sm:text-md max-md:text-lg text-xl text-gray-800 mb-5">
                                პროფილის ფოტო
                            </h2>
                            <div className="flex items-center gap-4">
                                <div className="w-16 h-16 rounded-full bg-gray-400 shrink-0"></div>
                                <FileInput />
                            </div>
                            <div className="w-full grid gap-2 mt-5 ">
                                <Button
                                    onClickFunction={handleUpdateAdmin}
                                    style={"bg-blue-1000 text-white rounded-lg py-2 px-4"}
                                    text={"განახლება"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AdminEdit