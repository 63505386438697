import { useEffect } from "react";
import { useState } from "react"

const Checkbox = ({
    name = null,
    inputName,
    inputSetter = null,
    value
}) => {
    const [checked, setChecked] = useState(false);

    const handleCheckingInput = (e) => {
        setChecked(e.target.checked)
    }

    const handleChecking = () => {
        setChecked(prev => !prev)
        inputSetter(!checked, inputName, name)
    }

    useEffect(() => {
        setChecked(value)
    }, [value])

    return (
        <div>
            <div className="flex gap-2 items-center">
                <div className="relative">
                    <div className={`border-2  w-4 h-4 flex items-center  relative z-20
            justify-center cursor-pointer rounded-[2px] hover:border-blue-1000 bg-white
            ${checked ? "border-blue-1000" : "border-gray-500"}`}
                        onClick={handleChecking}
                    >
                        <div className={`bg-blue-1000 w-2 h-2 rounded-[2px] 
                transition-all ${checked ? "rotate-180 opacity-100" : "opacity-0"}`}>

                        </div>
                    </div>
                    <input
                        type="checkbox"
                        value={checked}
                        className="absolute z-10 top-0 left-0"
                        onChange={(e) => { handleCheckingInput(e) }}
                        id={name !== null && name}
                        name={inputName}
                    />
                </div>
                {name !== null &&
                    <label htmlFor={name} className={`text-sm max-sm:w-10/12 ${checked ? "text-gray-600" : "text-slate-500"}`}>{name}</label>
                }
            </div>
        </div >
    )
}

export default Checkbox