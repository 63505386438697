import { createContext, useState, useEffect } from "react";
import protectedAxios from "../../api/protectedAxios";

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import translationGe from "../Languages/Ge";
// import translationEn from "../Languages/En";
// import translationRu from "../Languages/Ru";

// i18n
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: { translation: translationEn },
//       ge: { translation: translationGe },
//       ru: { translation: translationRu },
//     },
//     lng: "ge",
//     fallbackLng: 'ge',
//     interpolation: { escapeValue: false },
//   });

const PrimaryContext = createContext(null);

const MainContext = ({ children }) => {
  //userInfo
  const [user, setUser] = useState({});
  //update user
  const [updateUser, setUpdateUser] = useState(null);
  //token
  const [token, _setToken] = useState(localStorage.getItem("BM_USER_ADMIN"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //language
  const [language, setLanguage] = useState("ge");

  //setting token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("BM_USER_ADMIN", token);
      setIsAuthorized(true);
    } else {
      localStorage.removeItem("BM_USER_ADMIN");
      setIsAuthorized(false);
    }
  };

  //language
  // useEffect(() => {
  //   i18n.changeLanguage(language);
  // }, [language]);

  //get current logged in user
  useEffect(() => {
    protectedAxios.get("/admin")
      .then(({ data }) => {
        setUser(data.admin);
        setIsAuthorized(true);
      })
      .catch((err) => { })
  }, [token, updateUser]);

  return (
    <PrimaryContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthorized,
        setIsAuthorized,
        language,
        setLanguage,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
};

export { PrimaryContext, MainContext };
