import { Link } from "react-router-dom"
import { BsPencilSquare, BsFillTrash3Fill } from "react-icons/bs"
import { BiCopy } from "react-icons/bi"
import deleteRecord from "../../Services/delete"

const ProductsCard = ({
    data,
    updateFunction,
    handleCloneSingle
}) => {
    return (
        <div className="grid grid-cols-7 p-5 border-b border-slate-200">
            <div className="flex gap-2 items-center">
                <div className="flex gap-2 items-center">
                    <div>
                        {data?.imgs.length === 0 ?
                            <div className="rounded-xl w-10 h-10 bg-slate-400">
                            </div> :
                            <img
                                src={`${process.env.REACT_APP_API_URL}/${data?.imgs[0]?.url}`}
                                className="w-10 h-10 rounded-xl object-cover" alt="#" />
                        }
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-slate-600 text-sm">
                            {data.name}
                        </p>
                        <p className="text-gray-500 text-[10px]">
                            price: {data.price}
                        </p>
                    </div>
                </div>

            </div>
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {data.code}
                </p>
            </div>
            <div className="flex gap-2 items-center">
                <p className={`text-green-500 text-sm 
                    ${data.stock_status === 1 ? "text-green-500" :
                        data.stock_status === 2 ? "text-red-500" :
                            data.stock_status === 3 ? "text-yellow-500" : "text-slate-500"
                    }`}>
                    {data.stock_status === 1 ? "მარაგშია" :
                        data.stock_status === 2 ? "არაა მარაგში" :
                            data.stock_status === 3 ? "შეკვეთით" : "---"
                    }
                </p>
            </div>
            <div className="flex gap-2 items-center">
                <p className="text-slate-600 text-sm">
                    {(data.productCategories && data.productCategories.length !== 0) ?
                        data.productCategories.map((item) => { return item.name }).toString() : "---"}
                </p>
            </div>
            <div className="flex gap-2 items-center">
                <div className="h-4 w-4 flex items-center justify-center rounded-full border border-gray-500">
                    {data.on_marketplace === 1 ?
                        <div className="w-2 h-2 bg-green-500 rounded-full">
                        </div> :
                        <div className="w-2 h-2 bg-red-500 rounded-full">
                        </div>
                    }
                </div>
            </div>
            <div className="flex gap-2 items-center">
                {data.product_status === 1 ?
                    <div className="w-2 h-2 bg-green-500 rounded-full">
                    </div> :
                    data.product_status === 2 ?
                        <div className="w-2 h-2 bg-red-A200 rounded-full">
                        </div> :
                        <div className="w-2 h-2 bg-orange-400 rounded-full">
                        </div>
                }
                <p className="text-slate-600 text-sm">
                    {data.product_status === 1 ? "აქტიური" :
                        data.product_status === 2 ? "არააქტიური" :
                            data.product_status === 3 ? "მომლოდინე" : "---"
                    }
                </p>
            </div>
            <div className="flex gap-2 items-center justify-end">
                <Link to={`/products/${data.id}`}>
                    <div className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer">
                        <BsPencilSquare className="w-full h-full text-white" />
                    </div>
                </Link>
                <div
                    onClick={() => { handleCloneSingle(data.id) }}
                    className="w-8 h-8 bg-purple-500 rounded-lg p-2 cursor-pointer">
                    <BiCopy className="w-full h-full text-white" />
                </div>
                <div
                    onClick={() => {
                        deleteRecord({
                            url: "/admin/product/",
                            id: data.id,
                            updateFunction: updateFunction,
                        })
                    }}
                    className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer">
                    <BsFillTrash3Fill className="w-full h-full text-white" />
                </div>
            </div>
        </div>
    )
}

export default ProductsCard