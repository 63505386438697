import { useState, useEffect } from "react";

const TextInput = ({
    SuffixIcon = null,
    PrefixIcon = null,
    placeholder = null,
    inputName,
    inputSetter = null,
    type = "text",
    pattern,
    value,
    isEmpty,
    setIsNotEmpty = null,
    clearFields = "",
    setClearFields,
    name = null,
}) => {
    //current value variable
    const [currentValue, setCurrentValue] = useState("");
    //input type
    const [inputType, setInputType] = useState(type);
    //check if current value is empty make border of field red 

    //value change handler for input
    const handleValueChange = (e) => {
        const val = e.target.value;
        if (pattern === "en") {
            let reg = /^[A-Za-z]*$/;
            if (reg.test(val)) {
                setCurrentValue(val);
            }
        } else if (pattern === "ge") {
            let reg = /^[\u10A0-\u10FF]*$/i;
            if (reg.test(val)) {
                setCurrentValue(val);
            }
        } else {
            setCurrentValue(val);
        }
    };

    //handle show password
    const handleShowPassword = () => {
        if (type === "password") {
            if (inputType === "password") {
                setInputType("text")
            } else {
                setInputType("password")
            }
        }
    }

    //when current value changes passed function
    //will change specific object based value
    useEffect(() => {
        if (inputSetter) {
            inputSetter(currentValue, inputName);
        }
        if (currentValue.length !== 0 && setIsNotEmpty) {
            setIsNotEmpty()
        }
    }, [currentValue]);

    //current value
    useEffect(() => {
        if (value) {
            setCurrentValue(value);
        }
    }, [value]);

    //clear field if nesecity
    useEffect(() => {
        if (clearFields !== "" && clearFields) {
            setCurrentValue("");
            setClearFields(false);
        }
    }, [clearFields]);

    return (
        <div className="w-full">
            {name !== null &&
                <p className="text-slate-600 text-sm mb-3">
                    {name}
                </p>
            }
            <div
                className={`w-[100%] bg-white p-2 rounded-xl border-solid border 
                ${isEmpty ? "border-red-500 outline outline-red-300" : "border-gray-300"}`}>
                <div className="flex items-center gap-4 w-full">
                    {PrefixIcon !== null && (
                        <PrefixIcon className="w-4 h-4 text-slate-500 cursor-pointer" />
                    )}
                    <div className="w-full">
                        <input
                            name={inputName}
                            value={currentValue}
                            onChange={(e) => {
                                handleValueChange(e);
                            }}
                            type={inputType}
                            placeholder={placeholder}
                            className="w-full text-slate-600 border-none h-5 text-sm outline-none"
                        />
                    </div>
                    {SuffixIcon !== null && (
                        <SuffixIcon onClick={handleShowPassword} className="w-4 h-4 text-slate-500 cursor-pointer" />
                    )}
                </div>
            </div>
        </div >
    );
};

export default TextInput;
