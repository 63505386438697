import OptionsRouter from "./OptionsRouter"
import OptionsMenu from "./OptionsMenu"

const OptionsLayout = () => {
    return (
        <div className="grid grid-cols-3 max-lg:grid-cols-1">
            <div className="col-span-1 p-5">
                <OptionsMenu />
            </div>
            <div className="col-span-2 max-lg:col-span-1">
                <OptionsRouter />
            </div>
        </div>
    )
}

export default OptionsLayout