import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"
import SearchInput from "../../components/inputs/SearchInput"

import { TbWorldWww } from "react-icons/tb"

const Domens = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                დომენები
            </h2>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl p-5">
                <div className="flex gap-2">
                    <TbWorldWww className={"w-10 h-10 text-slate-400"} />
                    <div>
                        <p className="text-blue-1000 text-sm">
                            იყიდე ან დაუკავშირდი დომენს
                        </p>
                        <p className="text-slate-400 text-xs">
                            იყიდე ახალ დომენი ან დაუკავშირდი არსებულს
                        </p>
                    </div>
                </div>
                <div className="flex gap-2 items-center mt-4">
                    <Button
                        style={"border border-blue-1000 text-blue-1000 rounded-lg py-2 px-4 text-sm"}
                        text={"დაკავშირება"}
                    />
                    <Button
                        style={"border bg-blue-1000 text-white rounded-lg py-2 px-4 text-sm"}
                        text={"ყიდვა"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl p-5">
                <SearchInput
                    placeholder={"მოძებნე დომენი"}
                />
                <div className="mt-4 grid grid-cols-1 gap-3">
                    <div className="grid grid-cols-2">
                        <p className="text-slate-600 text-sm">
                            დომენი
                        </p>
                        <p className="text-slate-600 text-sm text-end">
                            მიზანი / დანიშნულება
                        </p>
                    </div>
                    <Line className={"h-[1px] bg-gray-300 w-full"} />
                    <div>
                        <div className="grid grid-cols-2">
                            <p className="text-slate-500 text-sm">
                                name.ge
                            </p>
                            <p className="text-slate-500 text-sm text-end">
                                ონლაინ მაღაზია
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <p className="text-blue-1000 text-sm">
                        გადაცვალე ახალ დომენში
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Domens