import { Route, Routes, useLocation } from "react-router-dom";
import { useState } from "react";

import Header from "../header/Header";
import Panel from "../../pages/panel/Panel";
import Filials from "../../pages/filials/Filials";
import Orders from "../../pages/orders/Orders";
import Category from "../../pages/category/Category";
import Inbox from "../../pages/inbox/Inbox";
import Calendar from "../../pages/calendar/Calendar";
import AddProducts from "../../pages/products/AddProducts";
import Products from "../../pages/products/Products";
import Login from "../../pages/accountPages/Login";
import Signup from "../../pages/accountPages/Signup";
import ResetPassword from "../../pages/accountPages/ResetPassword";
import QuestionFinish from "../questions/QuestionFinish";
import SupportCenter from "../../pages/supportCenter/SupportCenter";
import OptionsLayout from "../../pages/options/OptionsLayout";
import Users from "../../pages/users/Users";
import Admins from "../../pages/adminsPage/Admins";
import AddAdmin from "../../pages/adminsPage/AddAdmin";
import AdminEdit from "../../pages/adminsPage/AdminEdit";
import UserEdit from "../../pages/users/UserEdit";
import EditCategory from "../../pages/category/EditCategory";
import EditProduct from "../../pages/products/EditProduct";
import FilterPage from "../../pages/filter/FilterPage";
import EditFilters from "../../pages/filter/EditFilters";
import EditBranch from "../../pages/filials/EditBranch";
import EditOrder from "../../pages/orders/EditOrder";
import AddFilial from "../../pages/filials/AddFilial";
import QuestionsOne from "../questions/QuestionsOne";
import QuestionsLayout from "../questions/QuestionsLayout";

const AdminRouter = () => {
  const { pathname } = useLocation();
  const [toggleSidebar, setToggleSideBar] = useState(true);

  const shouldRenderFullNavigation = (path) => {
    return ![
      "/congratulations",
      "/register",
      "/reset-password",
      "/",
      "/steps",
    ].includes(path);
  };

  return (
    <>
      {shouldRenderFullNavigation(pathname) && (
        <Header
          toggleSidebar={toggleSidebar}
          setToggleSideBar={setToggleSideBar}
        />
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/congratulations" element={<QuestionFinish />} />

        <Route path="/steps" element={<QuestionsLayout />} />
      </Routes>
      <div
        className={`transition-all ${
          toggleSidebar ? "pl-[300px] max-sm:pl-[0]" : "pl-[94px] max-sm:pl-[0]"
        }`}
      >
        <Routes>
          <Route path="/home" element={<Panel />} />

          <Route path="/admins" element={<Admins />} />
          <Route path="/add-admins" element={<AddAdmin />} />
          <Route path="/admins/:adminId" element={<AdminEdit />} />

          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserEdit />} />

          <Route path="/category" element={<Category />} />
          <Route path="/category/:categoryId" element={<EditCategory />} />

          <Route path="/products" element={<Products />} />
          <Route path="/add-products" element={<AddProducts />} />
          <Route path="/products/:productId" element={<EditProduct />} />

          <Route path="/filter" element={<FilterPage />} />
          <Route path="/filter/:filterId" element={<EditFilters />} />

          <Route path="/filial" element={<Filials />} />
          <Route path="/filial/:branchId" element={<EditBranch />} />
          <Route path="/add-filial" element={<AddFilial />} />

          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:orderId" element={<EditOrder />} />

          <Route path="/inbox" element={<Inbox />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/support-center" element={<SupportCenter />} />
          <Route path="/options/*" element={<OptionsLayout />} />
        </Routes>
      </div>
    </>
  );
};

export default AdminRouter;
