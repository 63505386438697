import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Checkbox from "../inputs/Checkbox";
import deleteRecord from "../../Services/delete";
import SubMenu from "../subMenu/SubMenu";
import useGetOrderStatuses from "../generalData/useGetOrderStatuses";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import SelectInput from "../inputs/SelectInput";
import updateManyRecords from "../../Services/updateMany";

const OrderStatusCardd = ({
  data,
  handleAddCheckOrder,
  handleUncheckOrder,
  allChecked,
  setUpdateOrderRecords,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const orderStatuses = useGetOrderStatuses();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  //timer
  let timer;

  const handleDeleteOrder = (id) => {
    deleteRecord({
      url: `/admin/order?orderIds=${JSON.stringify([id])}`,
      updateFunction: setUpdateOrderRecords,
    });
  };

  const handleUpdateStatus = () => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      console.log({ selectedOrderStatus, id: data.id, stat: data.status });
      if (selectedOrderStatus && selectedOrderStatus != data.status) {
        updateManyRecords({
          url: "admin/changeOrderStatus",
          payload: {
            orderIds: JSON.stringify([data.id]),
            status: selectedOrderStatus,
          },
          updateFunction: setUpdateOrderRecords,
        });
      }
    }, 1000);
  };

  useEffect(() => {
    if (isChecked) {
      handleAddCheckOrder(data.id);
    } else {
      handleUncheckOrder(data.id);
    }
  }, [isChecked]);

  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    handleUpdateStatus();
  }, [selectedOrderStatus]);

  return (
    <div className="grid grid-cols-6 p-5 border-b border-slate-200">
      <div className="flex gap-2 items-center">
        <Checkbox inputSetter={setIsChecked} value={isChecked} />
        <p className="text-slate-600 text-sm">#{data.id}</p>
      </div>
      {/* გაფორმებული ,  დადასტურებული, გზაშია, მიტანილია, გაუქმებულია */}
      <div className="flex gap-2 items-center">
        <p className="text-yellow-400 text-sm">{data?.status}</p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-sm">{data?.address_details}</p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-sm">{data?.phone}</p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="text-slate-600 text-sm">{data?.email}</p>
      </div>

      <div className="flex gap-2 items-center justify-end">
        <SubMenu>
          <Link
            to={`/orders/${data.id}`}
            className="inline-block min-w-[160px]"
          >
            <div className="h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer flex items-center gap-2 justify-center">
              <BsPencilSquare className=" text-white shrink-0" />
              <p className="text-white shrink-0 text-xs">რედაქტირება</p>
            </div>
          </Link>
          <div
            className="h-8 bg-red-500 rounded-lg p-2 cursor-pointer flex items-center gap-2 justify-center"
            onClick={() => handleDeleteOrder(data.id)}
          >
            <BsFillTrash3Fill className="text-white" />
            <p className="text-white shrink-0 text-xs">წაშლა</p>
          </div>
          <SelectInput
            options={orderStatuses}
            placeholder={"სტატუსი"}
            inputSetter={(value, _) => {
              setSelectedOrderStatus(value);
            }}
            inputName={"orderStatus"}
            value={data?.status}
          />
        </SubMenu>
      </div>
    </div>
  );
};

export default OrderStatusCardd;
