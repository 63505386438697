import deleteRecord from "../../Services/delete";
import updateSingleRecord from "../../Services/updateSingle";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";

const OrderDetailsCard = ({
  orderItem,
  product,
  orderId,
  setUpdateOrderRecords,
}) => {
  const handleDeleteOrderItem = (id) => {
    deleteRecord({
      url: `/admin/order/${orderId}/`,
      id: id,
      updateFunction: setUpdateOrderRecords,
    });
  };

  const handleUpdateOrderItem = (id) => {
    updateSingleRecord({
      url: `admin/order/${orderId}/`,
      id: id,
      payload: {
        // orderId: "number",
        // orderItemId: "number",
        // branchId: "number",
      },
    });
  };

  return (
    <div className="grid grid-cols-3 py-5 border-b border-slate-200">
      <div className="flex gap-2 items-center">
        <div className="rounded-xl w-10 h-10 bg-slate-100 overflow-hidden">
          <img
            src={`${process.env.REACT_APP_API_URL}/${product?.imgs[0]?.url}`}
            alt="#"
            className="w-full h-full object-cover"
          />
        </div>
        <p className="text-slate-600 text-sm">{product?.name}</p>
      </div>
      <div className="flex gap-2 items-center justify-end">
        <p className="text-slate-600 text-sm">{product?.code}</p>
      </div>
      <div className="flex items-center justify-end gap-2">
        <div
          className="w-8 h-8 bg-red-500 rounded-lg p-2 cursor-pointer"
          onClick={() => handleDeleteOrderItem(orderItem.id)}
        >
          <BsFillTrash3Fill className="w-full h-full text-white" />
        </div>
        <div
          className="w-8 h-8 bg-blue-1000 rounded-lg p-2 cursor-pointer"
          onClick={() => handleUpdateOrderItem(orderItem.id)}
        >
          <BsPencilSquare className="w-full h-full text-white" />
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
