import protectedAxios from "../api/protectedAxios";
import { successToast, errorToast } from "../components/toast/ToastAlerts";

export default function updateManyRecords({
  url,
  payload,
  successMessage = "მონაცემები განახლდა",
  errorMessage = "დაფიქსირდა შეცდომა",
  updateFunction,
}) {
  protectedAxios
    .post(`${url}`, payload)
    .then((res) => {
      successToast(successMessage);
      if (updateFunction) {
        updateFunction(res);
      }
    })
    .catch((_) => {
      errorToast(errorMessage);
    });
}
