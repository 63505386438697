import Button from "../../components/inputs/Button"
import { Line } from "../../components/inputs/Line"
import TextInput from "../../components/inputs/TextInput"
import Textarea from "../../components/inputs/Textarea"
import Checkbox from "../../components/inputs/Checkbox"
import PaginatedItems from "../../components/pagination/Pagination"
import SearchInput from "../../components/inputs/SearchInput"

const Promocode = () => {
    return (
        <div className="min-h-screen w-full p-5 ">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                კუპონი
            </h2>

            <div className="w-full mt-10 flex flex-col gap-2">
                <div className="items-center grid grid-cols-3 gap-2 max-md:grid-cols-1">
                    <div className="col-span-2 w-full max-md:col-span-1">
                        <TextInput
                            placeholder={"კუპონის კოდი"}
                        />
                    </div>
                    <Button
                        style="border bg-blue-1000 text-white rounded-lg py-2 
                        px-4 text-sm flex gap-2 col-span-1 w-full h-10"
                        text={"კოდის გენერირება"}
                    />
                </div>
                <Textarea
                    placeholder={"აღწერა სურვილისამებრ"}
                />
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        ძირითადი
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col  gap-3">
                    <TextInput
                        name={"ფასდაკლების ტიპი"}
                    />
                    <TextInput
                        name={"კუპონის ოდენობა"}
                    />
                    <TextInput
                        name={"კუპონის ვადის ამოწურის თარიღი"}
                    />
                    <Checkbox
                        name={"უფასო მიწოდების ნების დართვა"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        გამოყენების შეზღუდვა
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col  gap-3">
                    <TextInput
                        name={"მინიმალური ხარჯი"}
                    />
                    <TextInput
                        name={"მაქსიმალური ხარჯი"}
                    />
                    <Checkbox
                        name={"მხოლოდ პირადი მოხმარებისთვის"}
                    />
                    <Checkbox
                        name={"გამორიცხული ფასდაკლებული ნივთები"}
                    />
                    <TextInput
                        name={"პროდუქტები"}
                    />
                    <TextInput
                        name={"გამორიცხული პროდუქტები"}
                    />
                    <TextInput
                        name={"პროდუქტების კატეგორიები"}
                    />
                    <TextInput
                        name={"კატეგორიების გამორიცხვა"}
                    />
                    <TextInput
                        name={"ნებადართული ელფოსტის ანგარიშები"}
                    />
                </div>
            </div>

            <div className="flex flex-col mt-10 shadow-my rounded-2xl">
                <div className="px-8 py-4 w-full">
                    <p className="text-blue-1000 text-sm">
                        გამოყენების ზღვარი
                    </p>
                </div>
                <Line className={"h-[1px] bg-gray-300 w-full"} />
                <div className="px-8 py-4 flex flex-col  gap-3">
                    <TextInput
                        name={"გამოყენების ოდენობა ერთ კუპონზე"}
                    />
                    <TextInput
                        name={"გამოყენების ოდენობა ერთ მომხმარებელზე"}
                    />
                </div>
            </div>

            <div className="mt-10 bg-white shadow-my rounded-2xl w-full">
                <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between p-5 pb-0 max-md:flex-col max-md:gap-2">
                        <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-md:w-full">
                            მომხმარებლები
                        </h2>
                        <div className="gap-2 flex max-md:flex-col max-md:gap-2 max-md:w-full">

                            <div className="w-32 max-md:w-full ">
                                <SearchInput />
                            </div>
                        </div>
                    </div>
                    <div className="max-sm:overflow-auto scrollbar">
                        <div className="max-sm:w-[1000px]">
                            <div className="grid grid-cols-5 p-5">
                                <div className="flex gap-2 items-center">
                                    <Checkbox />
                                    <p className="text-slate-600 text-sm">
                                        სახელი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        პროდუქტი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        პრომოკოდი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        თარიღი
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-600 text-sm">
                                        რაოდენობა
                                    </p>
                                </div>
                            </div>
                            <Line className="bg-gray-400_87 w-full h-[1px]" />
                            <div className="grid grid-cols-5 p-5">
                                <div className="flex gap-2 items-center">
                                    <Checkbox />
                                    <p className="text-slate-500 text-sm">
                                        დასაკეცი მაგიდა
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        QOWNLA
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        QOWNLA
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        10/23/12
                                    </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <p className="text-slate-500 text-sm">
                                        15
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-5 max-sm:flex-col max-sm:gap-2">
                        <p className="text-sm text-slate-600 max-sm:w-full">
                            ნაჩვენებია 1-10 სულ 46 შეკვეთა
                        </p>
                        <PaginatedItems />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promocode