import { BsX } from "react-icons/bs"
import RadioButton from "../../components/inputs/RadioButton"
import TextInput from "../../components/inputs/TextInput"
import Textarea from "../../components/inputs/Textarea"

const TechnicalSupport = ({ closeFunction }) => {
    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-blue-1000 bg-opacity-80 flex items-center justify-center">
            <div className="rounded-2xl shadow-my p-5 gap-10 bg-white w-5/12 h-[70%] max-md:w-[90%]">
                <div className="flex items-center justify-between">
                    <p className="text-md text-slate-600">
                        ტექნიკური დეპარტამენტი
                    </p>
                    <BsX className="text-2xl text-slate-600 cursor-pointer" onClick={closeFunction} />
                </div>
                <div className="w-full grid grid-cols-1 gap-2">
                    <TextInput
                        placeholder={"პრობლემის სათაური"}
                    />
                    <div className="w-full border border-slate-300 rounded-2xl p-5">
                        <p className="text-sm text-slate-600">
                            პრიორიტეტი
                        </p>
                        <div className="flex gap-2 mt-5">
                            <RadioButton
                                name={"მაღალი"}
                                uniqName={"priority"}
                            />
                            <RadioButton
                                name={"საშუალო"}
                                uniqName={"priority"}
                            />
                            <RadioButton
                                name={"დაბალი"}
                                uniqName={"priority"}
                            />
                        </div>
                    </div>
                    <Textarea
                        placeholder={"წერილი"}
                    />
                </div>

            </ div >
        </div>
    )
}

export default TechnicalSupport