import { useEffect, useState } from "react";
import { Line } from "../../components/inputs/Line";
import LineChartTwo from "../../components/charts/LineChartTwo";
import SelectInput from "../../components/inputs/SelectInput";
import StatisticCards from "../../components/dataCards/StatisticCards";
import Button from "../../components/inputs/Button";
import CircularProgress from "../../components/charts/CircularProgress";
import MostSoldProductsCard from "./MostSoldProductsCard";
import NotificationCardTwo from "./NotificationCardTwo";
import protectedAxios from "../../api/protectedAxios";

const Panel = () => {
  const [statisticCardsFilter, setStatisticsCardsFilter] = useState(1);
  const [statisticCardsDays, setStatisticCardsDays] = useState("ბოლო 1 დღე");

  useEffect(() => {
    switch (statisticCardsFilter) {
      case 1:
        setStatisticCardsDays("ბოლო 1 დღე");
        break;
      case 7:
        setStatisticCardsDays("ბოლო 7 დღე");
        break;
      case 30:
        setStatisticCardsDays("ბოლო 30 დღე");
        break;
    }
  }, [statisticCardsFilter]);

//   useEffect(() => {
//     protectedAxios
//       .get(`/admin/orderStatistic?status=${1}&day=${30}`)
//       .then((res) => {
//         console.log(res);
//       });
//   }, []);

  useEffect(() => {
    protectedAxios
      .get(`/admin/orderItemStatistic?limit=5`)
      .then((res) => {
        console.log(res);
      });
  }, []);

//   useEffect(() => {
//     protectedAxios
//       .get(`/admin/orderStatistic?status=${1}&day=${30}`)
//       .then((res) => {
//         console.log(res);
//       });
//   }, []);

  return (
    <div className="min-h-screen w-full">
      {/* <QuestionsLayout /> */}
      <div className="w-full grid grid-cols-3 max-lg:grid-cols-1">
        <div className="col-span-2 p-5 max-lg:col-span-1">
          <div className="w-full flex items-center justify-between">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              სტატისტიკა
            </h2>
            <div className="shrink-0">
              <SelectInput
                options={[
                  {
                    id: 1,
                    title: "ბოლო 1 დღე",
                  },
                  {
                    id: 7,
                    title: "ბოლო 7 დღე",
                  },
                  {
                    id: 30,
                    title: "ბოლო 30 დღე",
                  },
                ]}
                inputSetter={setStatisticsCardsFilter}
                placeholder={"დღეების დიაპაზონი"}
              />
            </div>
          </div>
          <div className="mt-5 grid grid-cols-3 gap-5 max-md:grid-cols-2 max-sm:grid-cols-1">
            <StatisticCards
              color={{
                bg: "bg-base-gradient",
                text: "text-deep_purple-600",
                lineColor: "rgba(87,71,189,1)",
              }}
              days={statisticCardsDays}
              title={"შესრულებული შეკვეთების რაოდენობა"}
            />
            <StatisticCards
              color={{
                bg: "bg-base-gradient",
                text: "text-deep_purple-600",
                lineColor: "rgba(87,71,189,1)",
              }}
              days={statisticCardsDays}
              title={"შესრულებული შეკვეთების თანხის ჯამური რაოდენობა"}
            />
            <StatisticCards
              color={{
                bg: "bg-base-gradient",
                text: "text-deep_purple-600",
                lineColor: "rgba(87,71,189,1)",
              }}
              days={statisticCardsDays}
              title={"გაყიდული პროდუქტის რაოდენობა"}
            />
          </div>

          <div className="mt-5 p-5 bg-white shadow-my rounded-2xl">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex items-center justify-between">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
                  შეკვეთების დინამიკა
                </h2>
                <div className="shrink-0">
                  <SelectInput
                    options={[
                      {
                        id: 1,
                        title: "ბოლო 1 დღე",
                      },
                      {
                        id: 7,
                        title: "ბოლო 7 დღე",
                      },
                      {
                        id: 30,
                        title: "ბოლო 30 დღე",
                      },
                    ]}
                    inputSetter={setStatisticsCardsFilter}
                    placeholder={"დღეების დიაპაზონი"}
                  />
                </div>
              </div>
              <div className="w-full">
                <LineChartTwo
                  lineColor={{
                    lineOne: "#22C55E",
                    lineTwo: "#F97316",
                    lineThree: "#3B82F6",
                  }}
                />
                <div className="flex items-center gap-2 flex-wrap w-full mt-5">
                  <div className="flex gap-2 items-center ">
                    <div className="bg-green-500 h-[13px] my-0.5 rounded-md w-[13px]"></div>
                    <p className="text-sm text-slate-600">
                      შემოსული მომხმარებლები
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="bg-orange-500 h-[13px] my-0.5 rounded-md w-[13px]"></div>
                    <p className="text-sm text-slate-600">შემოსული შეკვეთები</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="bg-blue-500 h-[13px] my-0.5 rounded-md w-[13px]"></div>
                    <p className="text-sm text-slate-600">
                      დასრულებული შეკვეთები
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-5 bg-white shadow-my rounded-2xl w-full">
            <div className="flex flex-col w-full">
              <div className="p-5 pb-0">
                <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600 max-md:w-full">
                  შეტყობინებები
                </h2>
              </div>
              <div className="max-sm:overflow-auto scrollbar">
                <div className="max-sm:w-[1000px]">
                  <div className="grid grid-cols-4 p-5">
                    <div className="flex gap-2 items-center">
                      <p className="text-slate-600 text-sm">ავტორი</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="text-slate-600 text-sm">სტატუსი</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="text-slate-600 text-sm">ტიპი</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="text-slate-600 text-sm">შეტყობინება</p>
                    </div>
                  </div>
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                  <Line className="bg-gray-400_87 w-full h-[1px]" />
                  <NotificationCardTwo />
                </div>
              </div>
              <div className="flex items-center justify-center p-5 max-sm:flex-col max-sm:gap-2">
                <Button
                  text={"მეტის ნახვა"}
                  style={
                    "bg-blue-1000 cursor-pointer p-4 rounded-2xl text-base text-center text-white"
                  }
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-span-1 p-5 max-lg:col-span-1">
          <div>
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              ყველაზე გაყიდვადი პროდუქტები
            </h2>
            <div className="mt-5">
              <div className="flex flex-col gap-2">
                <MostSoldProductsCard />
                <MostSoldProductsCard />
                <MostSoldProductsCard />
                <MostSoldProductsCard />
                <MostSoldProductsCard />
              </div>
              <div className="mt-5 w-full">
                <Button
                  text={"ყველას ნახვა"}
                  style={
                    "bg-blue-1000 cursor-pointer p-4 rounded-2xl text-base text-center text-white w-full"
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <h2 className="max-sm:text-md max-md:text-lg text-xl text-slate-600">
              შეკვეთის სტატუსი
            </h2>
            <div className="mt-5">
              <CircularProgress progress={95} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
